import template from './pbo-data-indicator.component.html';

class PboDataIndicatorComponent {
    chartSize = 180;
    data = [];
    dataStructure = {
        data: [],
        doughnutChartData: { bigLegend: {}, datasets: [], labels: [] },
        tableChartData: { datasets: [], labels: [] },
        tableData: { columns: [] },
    };
    parentRef = {};

    constructor(indicatorR00144Service) {
        this._indicatorR00144Service = indicatorR00144Service;
    }

    $onInit() {
        this.dataStructure = this._indicatorR00144Service.generateDataStructure(this.data);
    }
}

angular.module('dotic').component('pboDataIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: PboDataIndicatorComponent,
    templateUrl: template,
});
