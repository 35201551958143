import { ColorService } from '../../../../services/color.service';
import template from './distance-indicator.component.html';

class DistanceIndicatorComponent {
    chartSize = 180;
    data = [];
    distanceRange = null;
    filteredData = [];
    numberTotalDistances = 0;

    constructor($filter, $timeout) {
        this._translate = $filter('translate');
        this._$timeout = $timeout;
    }

    $onInit() {
        // Force open the indicators
        if (this.parentRef && !this.parentRef.isOpen() && this.opened) {
            this.parentRef.toggle();
        }

        if (!this.data || !this.data[0] || angular.isUndefined(this.data[0]['distanceKm'])) {
            return;
        }

        this.data.sort((a, b) => a.distanceKm - b.distanceKm);
        this.filteredData = [...this.data];
        this.numberTotalDistances = this.data.length;

        if (angular.isDefined(this.size)) {
            this.chartSize = parseInt(this.size, 10);
        }

        this.distanceNroSro = !!this.data.find((data) => data['nro']);
        this.distanceSroPbo = !!this.data.find((data) => data['zsCode']);
        this.distanceNroPbo = !!this.data.find((data) => data['znCode'] && data['bpCode']);

        this.distanceRange = this.preparedChartDistanceRange(this.data);
    }

    clearFilter() {
        this.data.sort((a, b) => a.distanceKm - b.distanceKm);
        this.filteredData = [...this.data];
    }

    preparedChartDistanceRange(data) {
        const chartData = data.reduce(
            (acc, range) => {
                if (range.distanceKm > 0 && range.distanceKm <= 0.3) {
                    acc.zeroToThreeHundred += 1;
                } else if (range.distanceKm <= 0.5) {
                    acc.threeHundredToFiveHundred += 1;
                } else if (range.distanceKm <= 1) {
                    acc.fiveHundredToThousand += 1;
                } else if (range.distanceKm <= 3) {
                    acc.thousandToThreeThousand += 1;
                } else {
                    acc.overThreeThousand += 1;
                }

                return acc;
            },
            {
                zeroToThreeHundred: 0,
                threeHundredToFiveHundred: 0,
                fiveHundredToThousand: 0,
                thousandToThreeThousand: 0,
                overThreeThousand: 0,
            },
        );

        const labels = [
            `${this._translate('shared.lessThan', { VALUE: 0.3 })} km`,
            `${this._translate('shared.betweenTwoValues', { FIRST_VALUE: 0.3, SECOND_VALUE: 0.5 })} km`,
            `${this._translate('shared.betweenTwoValues', { FIRST_VALUE: 0.5, SECOND_VALUE: 1 })} km`,
            `${this._translate('shared.betweenTwoValues', { FIRST_VALUE: 1, SECOND_VALUE: 3 })} km`,
            `${this._translate('shared.greaterThan', { VALUE: 3 })} km`,
        ];

        const datasets = [
            {
                data: [
                    chartData.zeroToThreeHundred,
                    chartData.threeHundredToFiveHundred,
                    chartData.fiveHundredToThousand,
                    chartData.thousandToThreeThousand,
                    chartData.overThreeThousand,
                ],
                backgroundColor: ColorService.genericDistanceColorOrdered,
            },
        ];

        return {
            bigLegend: {
                figure: this.numberTotalDistances,
                name: this._translate('shared.distance', {
                    COUNT: this.numberTotalDistances,
                }),
            },
            labels,
            datasets,
        };
    }

    getEvent(customClickEvent) {
        if (!customClickEvent.isDoughnut) {
            return;
        }

        if (!this.parentRef.isOpen()) {
            this.parentRef.toggle();
        }

        this.hasAnomaliesOpen = true;

        this._$timeout(() => {
            switch (customClickEvent.index) {
                case 0:
                    this.filteredData = this.data.filter((range) => range.distanceKm > 0 && range.distanceKm <= 0.3);
                    break;
                case 1:
                    this.filteredData = this.data.filter((range) => range.distanceKm > 0.3 && range.distanceKm <= 0.5);
                    break;
                case 2:
                    this.filteredData = this.data.filter((range) => range.distanceKm > 0.5 && range.distanceKm <= 1);
                    break;
                case 3:
                    this.filteredData = this.data.filter((range) => range.distanceKm > 1 && range.distanceKm <= 3);
                    break;
                case 4:
                default:
                    this.filteredData = this.data.filter((range) => range.distanceKm > 3);
                    break;
            }
        });
    }
}

angular.module('dotic').component('distanceIndicator', {
    controller: DistanceIndicatorComponent,
    templateUrl: template,
    bindings: {
        data: '<',
        opened: '<',
        parentRef: '<',
        size: '<',
    },
});
