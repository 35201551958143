import { ColorService, GeneralBarChartColorEnum } from '../../color.service';
import { ChartService } from '../../chart.service';
import { IndicatorService } from '../indicator.service';

const KeyEnum = {
    CABLE_LENGTH: 'cableLength',
    SLICE_COMPOSITION: 'sliceComposition',
};

// Linked to cable-length-by-slice-type indicator.
class IndicatorR00146Service {
    static ANCTCorrespondenceNumber = 28;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    generateDataStructure(data) {
        const formattedData = IndicatorService.getFormattedKilometerData(data, [KeyEnum.CABLE_LENGTH]);
        const sliceCompositions = formattedData.map((currentData) => currentData[KeyEnum.SLICE_COMPOSITION]);
        const datasets = [
            ChartService.getTableChartDataset(
                formattedData.map((currentData) => currentData[KeyEnum.CABLE_LENGTH]),
                this._translate('shared.length', { COUNT: 1 }),
                GeneralBarChartColorEnum.LENGTH,
            ),
        ];

        return {
            ANCTCorrespondenceNumber: IndicatorR00146Service.ANCTCorrespondenceNumber,
            data: formattedData,
            iconOptions: this.getIconOptions(formattedData),
            tableChartData: {
                datasets: datasets,
                labels: sliceCompositions,
            },
            tableData: {
                columns: this.getColumns(),
                title: this._translate('reportCompare.detailLengthCable'),
            },
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.SLICE_COMPOSITION,
                translation: this._translate('shared.conductTypeBySlice'),
            },
            {
                key: KeyEnum.CABLE_LENGTH,
                translation: `${this._translate('shared.length', { COUNT: 1 })} (km)`,
            },
        ];
    }

    getIconOptions(data) {
        return data.map((currentData) => {
            return {
                color: ColorService.getSliceTypeColor(currentData[KeyEnum.SLICE_COMPOSITION]),
                icon: 'cement-mix',
                label: currentData[KeyEnum.SLICE_COMPOSITION],
                value: currentData[KeyEnum.CABLE_LENGTH],
            };
        });
    }
}

angular.module('dotic').factory('indicatorR00146Service', IndicatorR00146Service);
