// Imports
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../../assets/images/help/lCtrl/newFiltres-1.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../../assets/images/help/lCtrl/newFiltres-2.jpg");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../../../assets/images/help/lStock/lStock-filtre.jpg");
// Module
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 5.3.2 / {{ 'helpDeliverable.filterStocked' | translate }}: </h3> <ul class=\"help-Doc__ul\"> <li class=\"help-Doc__subTitre4\"> {{ 'shared.newFilterManagement' | translate }} : <img class=\"help-Doc__marginTop help-Doc__img-double-up help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_0___ + "\" alt=\"{{ 'shared.newFilterManagement' | translate }}\"/> <img class=\"help-Doc__marginBottom help-Doc__img-double-down help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_1___ + "\" alt=\"{{ 'shared.newFilterManagement' | translate }}\"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'shared.availableFilters' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_2___ + "\" alt=\"{{ 'shared.availableFilters' | translate }}\"/> </li> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/gestionDepot/livrableStocke/filtreLStocke.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;