// Imports
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../../assets/images/help/control/control-selection-edit.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../../assets/images/help/control/control-edit.jpg");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../../../assets/images/help/control/control-edit-part.jpg");
var ___HTML_LOADER_IMPORT_3___ = require("../../../../../../assets/images/help/control/control-edit-detail.jpg");
// Module
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h4 class=\"help-Doc__subTitre5 help_Doc__paddingBottom-paddingTop\"> 3.2.3.2 / {{ 'shared.editGrid' | translate }} : </h4> <p class=\"help-Doc_Alerte help-Doc-Box-Alerte\"> <span> ! {{ 'helpConfig.warningTemplate' | translate }}. <br/> ! {{ 'helpConfig.warningModify' | translate }} </span> </p> <ul class=\"help-Doc__None-ul\"> <li class=\"help-Doc__subTitre4\"> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_0___ + "\" alt=\"{{ 'shared.editGrid' | translate }}\"/> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_1___ + "\" alt=\"{{ 'shared.editGrid' | translate }}\"/> </li> </ul> <ul class=\"help-Doc__ul\"> <li class=\"help-Doc__subTitre4\"> {{ 'helpConfig.editionMode' | translate }} : <p class=\"help-Doc_Alerte-interne help-Doc-Box-Alerte-interne\"> <span> ! {{ 'helpConfig.editControlFields' | translate }} . <br/> </span> </p> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_2___ + "\" alt=\"{{ 'helpConfig.editionMode' | translate }}\"/> </li> </ul> <ul class=\"help-Doc__ul\"> <li class=\"help-Doc__subTitre4\"> {{ 'helpDeliverable.seeDetailsControlPoint' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_3___ + "\" alt=\"{{ 'helpConfig.editionMode' | translate }}\"/> </li> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/gestionConfigurations/grillesControl/parametrerEditerGControl.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;