import { sum as radashSum } from 'radash';

import { IndicatorCableBtiService } from '../../../services/indicatorCableBti.service';
import template from './reportPDF.html';

class ReportPDFController {
    company = {};
    controlsStatistics = null;
    finished = false;
    formattedReport = null;
    glossary = [];
    hasIndicators = true;
    hasLinkBetweenGridsEnabled = false;
    indicators = null;
    isGraceV3 = false;
    loading = true;
    oldDeposit = false;
    report = null;

    // records indicator
    barChartOptions = {
        responsive: true,
        tooltips: {
            enabled: false,
        },
        scales: null,
    };
    cableBti = IndicatorCableBtiService.cableBtiDefaultValues;
    controlPointBySeverity = {
        datasets: [],
        labels: [],
    };
    isOld = false;
    oldDepositNRO = false;
    oldDepositSRO = false;
    oldNbBti = false;
    nbTableLines = 0;

    constructor(
        $chartService,
        $companySettings,
        $dataModelService,
        $filter,
        $indicatorCableBtiService,
        $location,
        $pdfReportService,
        $reportService,
        $scope,
        $state,
        $stateParams,
        $timeout,
        $toasterService,
        reportProvider,
    ) {
        this._$companySettings = $companySettings;
        this._$indicatorCableBtiService = $indicatorCableBtiService;
        this._$dataModelService = $dataModelService;
        this._$location = $location;
        this._$pdfReportService = $pdfReportService;
        this._$reportService = $reportService;
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._translate = $filter('translate');

        this._reportProvider = reportProvider;

        this.barChartOptions.scales = $chartService.getDefaultScaleConfiguration(true, {
            fontSize: 12,
            minRotation: 8,
        });

        this.forceOpen = {
            isOpen: () => true,
        };
    }

    async $onInit() {
        this.finished = false;
        this.loading = true;

        const reportData = await this.getReportData();
        this.hasLinkBetweenGridsEnabled = await this._$companySettings.getCompanyHasLinkBetweenGridsEnabled(
            this.report.companyId,
        );
        this.hasIndicators =
            this.company.settings.feature.hasIndicatorsDefault ||
            this.company.settings.feature.hasIndicatorsANCT ||
            this.company.settings.feature.hasindicatorsGC;

        this.controlPointBySeverity = this._$pdfReportService.getControlPointChartData(
            reportData.report,
            this.hasLinkBetweenGridsEnabled,
        );

        this._$pdfReportService.setScopeNavigation(reportData.report, this._$scope);
        this._$timeout(() => (this.loading = false));

        this.formattedReport = this._$reportService.format(reportData);
        this.loadPdfContent();

        this._$timeout(() => {
            this._$pdfReportService.definePageNumbers();
            this.finished = true;
        });
    }

    async getReportData() {
        try {
            const reportData = await this._reportProvider.get(this._$stateParams.reportId);
            this.controlsStatistics = reportData.controls;
            this.report = reportData.report;
            this.indicators = reportData.indicators;
            this.company = reportData.report.company;

            this.isGraceV3 = this._$dataModelService.isDataGraceTHDV3(this.report.deposit);

            return reportData;
        } catch {
            this._$toasterService.error(this._translate('toaster.error'));
        }
    }

    initAbandonedObjectTree(abandonedObjectIndicator) {
        if (!abandonedObjectIndicator) {
            return;
        }

        this.abandonedObjectTree = {
            parent: {
                icon: 'recycling-man',
                color: '#607099',
                value: abandonedObjectIndicator.sumTablesName,
                text: this._translate('shared.abandonedObject', { COUNT: abandonedObjectIndicator.sumTablesName }),
            },
        };
    }

    initNROTree() {
        if (angular.isUndefined(this.indicators.nroTypePhy?.[0]?.type)) {
            this.oldDepositNRO = true;

            return;
        }

        this.NROTree = {
            parent: {
                textIcon: 'NRO',
                color: '#5069a9',
                value: radashSum(this.indicators.nroTypePhy, (nro) => nro.count),
            },
            children: this.indicators.nroTypePhy.map((item) => {
                return {
                    icon: 'building',
                    color: '#e971b1',
                    text: item.type.toLocaleLowerCase(),
                    value: item.count,
                };
            }),
        };
    }

    initSROTree() {
        if (angular.isUndefined(this.indicators.sroTypePhy?.[0]?.type)) {
            this.oldDepositSRO = true;

            return;
        }

        this.SROTree = {
            parent: {
                textIcon: 'SRO',
                color: '#00a889',
                value: radashSum(this.indicators.sroTypePhy, (sro) => sro.count),
            },
            children: this.indicators.sroTypePhy.map((item) => {
                return {
                    icon: 'building',
                    color: '#e971b1',
                    text: item.type.toLocaleLowerCase(),
                    value: item.count,
                };
            }),
        };
    }

    initTableRecords() {
        if (angular.isUndefined(this.formattedReport.tables?.[0]?.['nb_lignes_par_table'])) {
            this.isOld = true;

            return;
        }

        this.nbTableLines = radashSum(this.formattedReport.tables, (table) => table.nb_lignes_par_table);
        const activeTables = this.formattedReport.tables.filter((active) => active.activated);
        const emptyTables = this.formattedReport.tables.filter(
            (active) => active.nb_lignes_par_table === 0 && !active.activated,
        );

        this.totalTableTree = {
            parent: {
                icon: 'table',
                color: '#e971b1',
                value: this.formattedReport.tables.length,
                text:
                    this.formattedReport.tables.length > 1
                        ? `${this._translate('shared.tables')} ${this._translate('shared.totales')}`
                        : `${this._translate('shared.table')} ${this._translate('shared.totale')}`,
            },
            children: [
                {
                    icon: 'file-spreadsheet',
                    color: '#0b99e0',
                    value: activeTables.length,
                    text: this._translate('shared.activeTable', { COUNT: activeTables.length }),
                },
                {
                    icon: 'file-file',
                    color: '#c8c8d1',
                    value: emptyTables.length,
                    text: this._translate('shared.emptyTable', { COUNT: emptyTables.length }),
                },
            ],
        };
    }

    loadPdfContent() {
        this.glossary = this._$reportService.computeChunk(this.formattedReport.glossary, 10);
        this.unrealisedControl = this._$reportService.computeChunk(this.controlsStatistics.notMade, 22);

        this.controlsStatistics.thematics = this._$pdfReportService.initThematics(this.controlsStatistics.thematics);
        this.initTableRecords();
        this.initAbandonedObjectTree(this.report.abandonnedObjectIndicator);
        this.housingTree = this._$pdfReportService.initHousePlugTree(this.indicators.newPlug, 'house');
        this.plugTree = this._$pdfReportService.initHousePlugTree(this.indicators.newPlug, 'plug');
        this.initNROTree();
        this.initSROTree();

        this.noDataDistanceBetweenNroPbo = angular.isUndefined(this.indicators.distanceBetweenNroPbo?.[0]?.distanceKm);
        this.noDataDistanceBetweenNroSro = angular.isUndefined(this.indicators.distanceBetweenNroSro?.[0]?.distanceKm);
        this.noDataDistanceBetweenSroPbo = angular.isUndefined(this.indicators.distanceBetweenSroPbo?.[0]?.distanceKm);

        this.oldNbBti = angular.isUndefined(this.indicators.nbBti?.[0]?.code);
        this.cableBti = this._$indicatorCableBtiService.initCableBti(this.indicators.cableBti);
    }
}

angular.module('dotic').component('reportPdfPage', {
    controller: ReportPDFController,
    templateUrl: template,
});
