import { group as radashGroup, max as radashMax, sum as radashSum } from 'radash';

import { ColorService, DoughnutChartColorEnum } from '../../color.service';
import { FieldEnum } from '../../../models/grace.model';

// This field doesn't come from backend
export const FieldOccurrenceAdditionalFieldEnum = {
    NON_SPECIFIC: 'nonSpecific',
    ADDRESS_COUNT: 'addressCount',
};

export const FieldOccurrenceKeyEnum = {
    FIELD: 'field',
    OCCURRENCE_COUNT: 'occurrenceCount',
    VALUE: 'value',
};

// Linked to field-occurrence indicator.
export class IndicatorR00140Service {
    static ANCTCorrespondenceNumber = 17;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    static getFormattedFieldOccurrenceData(data) {
        const elementsGroupedByField = radashGroup(data, (currentData) => currentData[FieldOccurrenceKeyEnum.FIELD]);
        const occurrenceCount = IndicatorR00140Service.getOccurrenceCount(elementsGroupedByField);

        const fieldsWithOccurrences = Object.entries(elementsGroupedByField).map(([field, elements]) => {
            const validElements = elements.filter((element) => element[FieldOccurrenceKeyEnum.VALUE]);

            return {
                [FieldOccurrenceKeyEnum.FIELD]: field,
                [FieldOccurrenceKeyEnum.OCCURRENCE_COUNT]:
                    radashSum(
                        validElements,
                        (validElement) => validElement[FieldOccurrenceKeyEnum.OCCURRENCE_COUNT] || 0,
                    ) ?? 0,
            };
        });

        return [
            ...fieldsWithOccurrences,
            {
                [FieldOccurrenceKeyEnum.FIELD]: FieldOccurrenceAdditionalFieldEnum.ADDRESS_COUNT,
                [FieldOccurrenceKeyEnum.OCCURRENCE_COUNT]: occurrenceCount,
            },
            {
                [FieldOccurrenceKeyEnum.FIELD]: FieldOccurrenceAdditionalFieldEnum.NON_SPECIFIC,
                [FieldOccurrenceKeyEnum.OCCURRENCE_COUNT]:
                    occurrenceCount -
                    radashSum(
                        fieldsWithOccurrences,
                        (fieldWithOccurrence) => fieldWithOccurrence[FieldOccurrenceKeyEnum.OCCURRENCE_COUNT],
                    ),
            },
        ];
    }

    static getOccurrenceCount(elementsGroupedByField) {
        return (
            radashMax(
                Object.values(elementsGroupedByField).map((currentValues) =>
                    radashSum(currentValues, (value) => value[FieldOccurrenceKeyEnum.OCCURRENCE_COUNT]),
                ),
            ) ?? 0
        );
    }

    generateDataStructure(data) {
        const formattedData = IndicatorR00140Service.getFormattedFieldOccurrenceData(data);

        return {
            ANCTCorrespondenceNumber: IndicatorR00140Service.ANCTCorrespondenceNumber,
            data: formattedData,
            doughnutChartData: this.getDoughnutChartData(formattedData),
            tableData: {
                columns: this.getColumns(),
                title: this._translate('indicator.fieldOccurrence'),
            },
        };
    }

    getColumns() {
        return [
            {
                key: FieldOccurrenceKeyEnum.FIELD,
                translation: this._translate('shared.field'),
            },
            {
                key: FieldOccurrenceKeyEnum.OCCURRENCE_COUNT,
                translation: this._translate('shared.nbOccurrence'),
            },
            {
                key: FieldOccurrenceKeyEnum.VALUE,
                translation: this._translate('shared.value'),
            },
        ];
    }

    getDifferenceReformattedData(differenceFormattedData) {
        return differenceFormattedData.map((currentData) => {
            return {
                ...currentData,
                [FieldOccurrenceKeyEnum.FIELD]: this.getDisplayedLabel(
                    currentData[FieldOccurrenceKeyEnum.FIELD],
                    currentData[FieldOccurrenceKeyEnum.OCCURRENCE_COUNT],
                ),
            };
        });
    }

    getDisplayedLabel(field, count) {
        switch (field) {
            case FieldEnum.AD_IMNEUF:
                return this._translate('shared.newBuilding', { COUNT: count });
            case FieldEnum.AD_ISOLE:
                return this._translate('shared.isolatedLocals', { COUNT: count });
            case FieldEnum.AD_PRIO:
                return this._translate('shared.prioritySiteConnection', { COUNT: count });
            case FieldOccurrenceAdditionalFieldEnum.NON_SPECIFIC:
                return this._translate('shared.addressWithoutSpecificities', { COUNT: count });
            case FieldOccurrenceAdditionalFieldEnum.ADDRESS_COUNT:
                return this._translate('shared.addressCount');
            default:
                return field;
        }
    }

    getDoughnutChartData(data) {
        const dataToDisplay = data.filter(
            (currentData) =>
                currentData[FieldOccurrenceKeyEnum.FIELD] !== FieldOccurrenceAdditionalFieldEnum.ADDRESS_COUNT,
        );
        const occurrenceCount =
            data.find(
                (currentData) =>
                    currentData[FieldOccurrenceKeyEnum.FIELD] === FieldOccurrenceAdditionalFieldEnum.ADDRESS_COUNT,
            )?.[FieldOccurrenceKeyEnum.OCCURRENCE_COUNT] || 0;

        const colors = dataToDisplay.map((currentData) => {
            if (currentData[FieldOccurrenceKeyEnum.FIELD] === FieldOccurrenceAdditionalFieldEnum.NON_SPECIFIC) {
                return DoughnutChartColorEnum.BIG_FAILURE;
            }

            return ColorService.getGraceFieldColor(currentData[FieldOccurrenceKeyEnum.FIELD]);
        });

        return {
            bigLegend: {
                figure: occurrenceCount,
                name: this._translate('shared.address', { COUNT: occurrenceCount }),
            },
            datasets: [
                {
                    backgroundColor: colors,
                    data: dataToDisplay.map((currentData) => currentData[FieldOccurrenceKeyEnum.OCCURRENCE_COUNT]),
                },
            ],
            labels: dataToDisplay.map((currentData) =>
                this.getDisplayedLabel(
                    currentData[FieldOccurrenceKeyEnum.FIELD],
                    currentData[FieldOccurrenceKeyEnum.OCCURRENCE_COUNT],
                ),
            ),
        };
    }
}

angular.module('dotic').factory('indicatorR00140Service', IndicatorR00140Service);
