import { RemoveModalTypeEnum } from '../models/modal.model';

class ModalService {
    // In each method, the catch is triggered on outside click.

    constructor($filter, $uibModal) {
        this._translate = $filter('translate');
        this._$uibModal = $uibModal;
    }

    async triggerAddKnownUserModal(companyId) {
        try {
            const modal = this._$uibModal.open({
                component: 'addKnownUserModal',
                resolve: {
                    companyId: () => companyId,
                },
                size: 'sm',
            });

            return await modal.result;
        } catch {
            return false;
        }
    }

    async triggerConfirmationModal(i18nTitleKey, i18nMessageKey, i18nMessageProperties = {}) {
        try {
            const modal = this._$uibModal.open({
                component: 'confirmModal',
                resolve: {
                    title: () => this._translate(i18nTitleKey),
                    message: () => this._translate(i18nMessageKey, i18nMessageProperties),
                },
                size: 'sm',
            });

            return await modal.result;
        } catch {
            return false;
        }
    }

    async triggerConfirmDepositDeletionModal(isANCT = false) {
        try {
            const modal = this._$uibModal.open({
                component: 'confirmDepositDeletionModal',
                resolve: {
                    isANCT: () => isANCT,
                },
                size: 'sm',
            });

            return await modal.result;
        } catch {
            return false;
        }
    }

    async triggerControlConfigurationModal(controlConfigurations) {
        try {
            const modal = this._$uibModal.open({
                size: 'sm',
                component: 'controlConfigurationListModal',
                resolve: {
                    controlConfigurations: () => controlConfigurations,
                },
            });

            return await modal.result;
        } catch {
            return false;
        }
    }

    async triggerControlErrorModal(
        control,
        controlErrors,
        getBottomTemplate = undefined,
        isClassicControlModal = true,
    ) {
        const componentName = isClassicControlModal ? 'controlErrorsModal' : 'compareErrorsModal';

        try {
            const modal = this._$uibModal.open({
                component: componentName,
                resolve: {
                    control: () => control,
                    controlErrors: () => controlErrors,
                    ...(getBottomTemplate && {
                        bottomTemplate: () => getBottomTemplate(),
                    }),
                },
                size: 'xxl',
            });

            return await modal.result;
        } catch {
            return false;
        }
    }

    async triggerCorrectiveActionModal(control) {
        try {
            const modal = this._$uibModal.open({
                component: 'correctiveActionModal',
                resolve: {
                    control: () => control,
                },
                size: 'xl',
            });

            return await modal.result;
        } catch {
            return null;
        }
    }

    async triggerChangePasswordModal() {
        try {
            const modal = this._$uibModal.open({
                component: 'changePasswordModal',
                size: 'sm',
            });

            return await modal.result;
        } catch {
            return null;
        }
    }

    async triggerControlPointFormModal(thematicList, objectList, index = null, controlData = null) {
        try {
            const modal = this._$uibModal.open({
                component: 'controlPointFormModal',
                resolve: {
                    controlData: () => controlData,
                    index: () => index,
                    objectList: () => objectList,
                    thematicList: () => thematicList,
                },
                size: 'lg',
            });

            return await modal.result;
        } catch {
            return null;
        }
    }

    async triggerDeliveryZoneListModal(deliveryZones) {
        try {
            const modal = this._$uibModal.open({
                size: 'sm',
                component: 'deliveryZoneListModal',
                resolve: {
                    deliveryZones: () => deliveryZones,
                },
            });

            return await modal.result;
        } catch {
            return false;
        }
    }

    async triggerEditBulkElementModal(deposit) {
        try {
            const modal = this._$uibModal.open({
                component: 'editBulkElementModal',
                resolve: {
                    configuration: () => deposit.controlConfiguration,
                    dataModel: () => deposit.dataModel,
                    deliveryZone: () => deposit.deliveryZone,
                    filename: () => deposit.file.name,
                    isANCT: () => deposit.isANCT,
                    networkSegments: () => deposit.networkSegments,
                    phase: () => deposit.phase,
                    project: () => deposit.project,
                },
                size: 'xl',
            });

            return await modal.result;
        } catch {
            return null;
        }
    }

    async triggerImportExcelModal(isDeliveryZoneImport = false) {
        try {
            const modal = this._$uibModal.open({
                component: 'importExcelModal',
                size: 'sm',
                resolve: {
                    isDeliveryZoneImport: () => isDeliveryZoneImport,
                },
            });

            return await modal.result;
        } catch {
            return null;
        }
    }

    async triggerListModal(items, title = null) {
        try {
            const modal = this._$uibModal.open({
                size: 'sm',
                component: 'listModal',
                resolve: {
                    items: () => items,
                    ...(title && {
                        title: () => title,
                    }),
                },
            });

            return await modal.result;
        } catch {
            return false;
        }
    }

    triggerExportGeoPackageModal() {
        const modal = this._$uibModal.open({
            component: 'generateExportGeoPackageModal',
            size: 'xl',
            backdrop: 'static',
        });

        return modal.result;
    }

    triggerFttxHeritageInventoryModal() {
        const modal = this._$uibModal.open({
            component: 'generateFttxEvolutionInventoryModal',
            size: 'xl',
            backdrop: 'static',
        });

        return modal.result;
    }

    async triggerLinkedDownloadModal(deposit, title, job, action = undefined) {
        try {
            const modal = this._$uibModal.open({
                component: 'linkedDownloadModal',
                resolve: {
                    deposit: () => deposit,
                    job: () => job,
                    title: () => title,
                    ...(action && {
                        action: () => action,
                    }),
                },
                size: 'sm',
            });

            return await modal.result;
        } catch {
            return false;
        }
    }

    async triggerProjectListModal(projects) {
        try {
            const modal = this._$uibModal.open({
                size: 'sm',
                component: 'projectListModal',
                resolve: {
                    projects: () => projects,
                },
            });

            return await modal.result;
        } catch {
            return false;
        }
    }

    async triggerUserFormModal(company, user = undefined) {
        const deepCopyUser = { ...user };
        try {
            const modal = this._$uibModal.open({
                component: 'userFormModal',
                resolve: {
                    company: () => company,
                    user: () => deepCopyUser,
                },
                size: 'sm',
            });

            return await modal.result;
        } catch {
            return false;
        }
    }

    async triggerRemoveModal(infoText, caseMode = RemoveModalTypeEnum.DELETE) {
        const messageIntro = this._translate('removeModal.introduction', {
            CASE: caseMode,
        });
        const submitText = this._translate('removeModal.confirm', {
            CASE: caseMode,
        });

        try {
            const modal = this._$uibModal.open({
                component: 'removeModal',
                resolve: {
                    message: () => `${messageIntro} ${infoText}`,
                    submitText: () => submitText,
                },
                size: 'sm',
            });

            return await modal.result;
        } catch {
            return false;
        }
    }

    async triggerSubCompaniesModal(subCompanies) {
        try {
            const modal = this._$uibModal.open({
                component: 'subCompaniesModal',
                resolve: {
                    companies: () => subCompanies,
                },
                size: 'sm',
            });

            return await modal.result;
        } catch {
            return false;
        }
    }

    async triggerSuperAdminFormModal() {
        try {
            const modal = this._$uibModal.open({
                component: 'superAdminFormModal',
                size: 'sm',
            });

            return await modal.result;
        } catch {
            return false;
        }
    }
}

angular.module('dotic').factory('$modalService', ($filter, $uibModal) => new ModalService($filter, $uibModal));
