import template from './dashboardExtended.html';

class DashboardExtendedPage {
    constructor(
        $auth,
        $authorizationService,
        $chartService,
        $filter,
        $indicatorMaxValueService,
        $location,
        $phaseService,
        $scope,
        $state,
        $timeout,
        $toasterService,
        dashboardExtendedProvider,
        userProvider,
    ) {
        this._translate = $filter('translate');
        this._$scope = $scope;
        this._$state = $state;
        this._$toasterService = $toasterService;
        this._$timeout = $timeout;
        this._dashboardExtendedProvider = dashboardExtendedProvider;
        this._$phaseService = $phaseService;
        this._userProvider = userProvider;
        this._$indicatorMaxValueService = $indicatorMaxValueService;

        this.isAllowed = $authorizationService.isAllowed;
        this.userId = $auth.getPayload().userId;
        this.bestControlRip = [];
        this.bestLoadRip = [];

        this.loading = true;
        this.loadingData = false;
        this.depositsLoading = false;

        this.stepFinish = false;
        this.timeRangeFilled = false;

        // charts
        this.globalDepositsChartData = null;
        this.globalMonthDepositsChartData = null;
        this.depositsEvolPercentChart = null;
        this.ripEvolPercentChart = null;
        this.userEvolPercentChart = null;
        this.globalActivityByRipChart = null;
        this.bestRipLoad = null;
        this.bestRipControl = null;
        this.ripActivityPercentChart = null;
        this.usersActivityPercentChart = null;
        this.allPhasesChartData = null;

        this.horizontalBarChartOptions = {
            responsive: true,
            scales: $chartService.getDefaultScaleConfiguration(false),
        };

        // Filters
        this.filterOptions = {
            phases: [],
            companies: [],
            users: [],
            timeRangeList: [
                { key: 'currentMonth', value: 'Mois en cours' },
                { key: 'lastMonth', value: 'Mois dernier' },
                { key: 'last6Month', value: '6 derniers mois' },
                { key: 'lastYear', value: 'Année dernière' },
            ],
        };

        this.filter = {
            phase: $state.params.phase || '',
            company: $state.params.company || '',
            user: $state.params.user || '',
            timeRange: $state.params.timeRange || 'last6Month',
            groupByLastVersion: $state.params.groupByLastVersion || false,
        };

        $scope.$watchGroup(
            [
                '$ctrl.filter.phase',
                '$ctrl.filter.company',
                '$ctrl.filter.user',
                '$ctrl.filter.timeRange',
                '$ctrl.filter.groupByLastVersion',
            ],
            (next, acc) => {
                if (acc !== next) {
                    this.setScopeDataWithFilters();
                }
            },
        );

        // Navigation path
        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'dashboardExtended',
                    title: this._translate('shared.dashboard', { TYPE: 'extended' }),
                    href: $location.path(),
                },
            ],
        });
        $scope.$on('selectRows', (e, data) => {
            this.tableSelect.select(data.rows, true);
        });
    }

    async $onInit() {
        try {
            const user = await this._userProvider.me();
            if (!user.permissions.canAccessDashboardExtended) {
                this.redirectToDashboard();

                return;
            }
        } catch {
            this.redirectToDashboard();

            return;
        }

        this.steps = [];
        this.depositsLoading = true;

        try {
            await this.updateStats();
            this.prepareFilters();

            this.depositsLoading = false;

            if (this.companyDataSorted.length === 0) {
                this.loading = false;
                this.loadingData = false;

                return;
            }

            this.ripUsersGlobalActivity(this.companyDataSorted, this.userActiveMonth);
            this.usersActivity(this.usersList);
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    redirectToDashboard() {
        this._$toasterService.error(this._translate('shared.unavailableFeature'));
        this._$state.go('app.dashboard');
    }

    getExtendedData() {
        this.loading = true;
        this.loadingData = true;
        this.steps.push({
            text: this._translate('shared.loading'),
            status: !this.stepFinish,
        });

        return this._dashboardExtendedProvider
            .getDataExtended({
                groupByLastVersion: this.filter.groupByLastVersion,
                phase: this.filter.phase,
                company: this.filter.company,
                user: this.filter.user,
                timeRange: this.filter.timeRange,
            })
            .then((result) => {
                this.steps.push({
                    text: this._translate('shared.loading'),
                    status: this.stepFinish,
                });
                this.steps.push({
                    text: 'Traitement des données: En cours...',
                    status: !this.stepFinish,
                });

                // Global indicators
                // Sum company-ies filtered
                this.sumAllCompanies = result.companies.length;
                // Sum users in company filtered
                this.sumAllUsers = result.totalUsersInCompanies;
                // Sum companies global without filter
                this.totalAllCompanies = result.allCompaniesCount;
                // Calcul somme des dépôts totaux réussis des entreprises filtrés
                this.sumTotalDepositCompanySuccess = result.companiesStats.sumTotalDepositCompanySuccess;
                // Calcul somme des dépôts contrôlés réussis des entreprises filtrés
                this.sumTotalControlSuccessCompany = result.companiesStats.sumTotalControlSuccessCompany;
                // Calcul somme des dépôts stockés réussis des entreprises filtrés
                this.sumTotalLoadSuccessCompany = result.companiesStats.sumTotalLoadSuccessCompany;

                // Data for filtered companies
                this.companyDataSorted = result.companies;

                // Global deposits by type
                this.globalTypeDeposits = result.globalTypeDeposits;
                this.ripLoadDepositData = result.ripLoadDeposit;
                this.ripControlDepositData = result.ripControlDeposit;

                this.steps.push({
                    text: 'Traitement des données: Terminé.',
                    status: this.stepFinish,
                });

                this.steps.push({
                    text: 'Génération des graphiques: En cours...',
                    status: !this.stepFinish,
                });

                if (Object.entries(this.filter.timeRange).length > 0) {
                    this.timeRangeFilled = true;
                } else {
                    this.timeRangeFilled = false;
                }

                if (this.companyDataSorted.length === 0) {
                    this.loading = false;
                    this.loadingData = false;

                    return;
                }

                // Charts
                if (this.globalTypeDeposits[0].control.length > 0 || this.globalTypeDeposits[0].load.length > 0) {
                    this.loadControlGlobalDeposit(this.globalTypeDeposits);
                }

                if (this.ripLoadDepositData) {
                    this.ripLoadDeposit(this.ripLoadDepositData);
                }

                if (this.ripControlDepositData) {
                    this.ripControlDeposit(this.ripControlDepositData);
                }

                this.ripActivity(this.companyDataSorted, this.totalAllCompanies);
                this.globalPhasesActivity(result.allPhases);

                this.loadDepositMaxValue = this._$indicatorMaxValueService.findMaxValueFlatten(this.bestRipLoad);
                this.controlDepositMaxValue = this._$indicatorMaxValueService.findMaxValueFlatten(this.bestRipControl);
                this.steps.push({
                    text: 'Génération des graphiques: Terminé',
                    status: this.stepFinish,
                });

                this._$timeout(() => {
                    this.loading = false;
                    this.loadingData = false;
                    this.steps = [];
                }, 10);
            })
            .catch(this._$toasterService.error);
    }

    getUsers() {
        this.steps.push({
            text: 'Récupération des données des utilisateurs: En cours ...',
            status: !this.stepFinish,
        });

        return this._userProvider
            .getEmployeesWithActivity({
                companyId: this.filter.company,
                userId: this.filter.user,
            })
            .then((users) => {
                this.usersList = users;

                this.steps.push({
                    text: 'Récupération des données des utilisateurs: Terminé.',
                    status: this.stepFinish,
                });
            })
            .catch(this._$toasterService.error);
    }

    getActiveUsersByMonth() {
        this.steps.push({
            text: 'Récupération des activités des utilisateurs: En cours ...',
            status: !this.stepFinish,
        });

        return this._userProvider
            .getActiveEmployeesByMonth({
                companyId: this.filter.company,
                userId: this.filter.user,
            })
            .then((activeMonth) => {
                this.userActiveMonth = activeMonth;
                this.steps.push({
                    text: 'Récupération des activités des utilisateurs: Terminé',
                    status: this.stepFinish,
                });
            })
            .catch(this._$toasterService.error);
    }

    updateStats() {
        return Promise.all([this.getExtendedData(), this.getUsers(), this.getActiveUsersByMonth()]);
    }

    // filters
    setScopeDataWithFilters() {
        const { phase, company, user, timeRange, groupByLastVersion } = this.filter;
        this._$state.go('.', {
            phase,
            company,
            user,
            timeRange,
            groupByLastVersion,
        });

        if (this.phase === '') {
            this.phase = null;
        }

        this.updateStats()
            .then(() => {
                this.prepareFilters();
            })
            .then(() => {
                if (Object.entries(this.filter.timeRange).length > 0) {
                    this.timeRangeFilled = true;
                } else {
                    this.timeRangeFilled = false;
                }

                if (this.companyDataSorted.length === 0) {
                    this.loading = false;
                    this.loadingData = false;

                    return;
                }

                this.usersActivity(this.usersList);
                this.ripUsersGlobalActivity(this.companyDataSorted, this.userActiveMonth);
            });
    }

    prepareFilters() {
        const { company } = this.filter;

        const phases = this._$phaseService.getAll().map((phase) => ({ value: phase, key: phase }));

        const companies = this.companyDataSorted.map(({ id, name }) => ({
            key: id,
            value: name,
        }));

        let users = this.usersList.map(({ id, fullname, email, role }) => ({
            key: id,
            value: [fullname, email, role].join(' - '),
        }));

        if (company && company !== '') {
            const selected = this.companyDataSorted.find(({ id }) => id === company);
            users = users.filter((user) => !selected?.employees || selected.employees.indexOf(user.key) > -1);
        }

        this.filterOptions = {
            ...this.filterOptions,
            users,
            phases,
            companies,
        };
    }

    showCancelFilter() {
        return this.filter.phase || this.filter.company || this.filter.user || this.filter.timeRange;
    }

    cancelFilters() {
        this.filter.phase = '';
        this.filter.company = '';
        this.filter.user = '';
        this.filter.timeRange = '';
    }

    toggleLastVersionDeposit() {
        this.filter.groupByLastVersion = !this.filter.groupByLastVersion;
    }

    toggleControlDepositVisibility() {
        this.toggleControlDeposit = !this.toggleControlDeposit;
    }

    toggleLoadDepositVisibility() {
        this.toggleLoadDeposit = !this.toggleLoadDeposit;
    }

    toggleDepositDateVisibility() {
        this.toggleDepositDate = !this.toggleDepositDate;

        //Load day chart only when click on button
        if (this.toggleDepositDate) {
            this.loadDayChart(this.globalTypeDeposits);
        }
    }

    findCurrentMonthDate(data) {
        if (data && data.length > 0) {
            const currentDateObj = data.reduce((a, b) => {
                return new Date(a.date) > new Date(b.date) ? a : b;
            });

            return currentDateObj.date;
        }
    }

    findMonthName(date, period) {
        const monthDate =
            period === 'current' ? moment(date, 'MM YYYY').get('month') + 1 : moment(date, 'MM YYYY').get('month');

        return moment(monthDate, 'M').format('MMM');
    }

    findPreviousMonthDate(data) {
        return moment(data, 'MM YYYY').subtract(1, 'month').format('MM YYYY');
    }

    findYearDate(data) {
        return moment(data, 'MM YYYY').format('YYYY');
    }

    // find all Dates between start and end
    findAllDatesGrouped(data) {
        let startDate = '';
        let endDate = '';

        if (data && data.length === 1) {
            startDate = moment(data, 'YYYY-MM-DD').subtract(3, 'days');
            endDate = moment(data, 'YYYY-MM-DD').add(3, 'days');
        } else if (data && data.length > 1) {
            const startDateInit = data.reduce((a, b) => (a < b ? a : b));
            startDate = moment(startDateInit, 'YYYY-MM-DD').subtract(1, 'days');
            const endDateInit = data.reduce((a, b) => (a > b ? a : b));
            endDate = moment(endDateInit, 'YYYY-MM-DD').add(1, 'days');
        }

        let dates = [];
        //to avoid modifying the original date
        const theDate = new Date(startDate);
        while (theDate < new Date(endDate)) {
            dates = [...dates, moment(theDate).format('YYYY-MM-DD')];
            theDate.setDate(theDate.getDate() + 1);
        }
        dates = [...dates, moment(endDate).format('YYYY-MM-DD')];

        return dates;
    }

    mergeDateGlobal(dateType, allDates) {
        const uniqueResultOne = allDates.filter((obj) => {
            return !dateType.some((obj2) => obj === obj2.date);
        });

        const formattedUniqueDate = uniqueResultOne.map((el) => ({
            date: el,
            control: 0,
            load: 0,
        }));

        return [...dateType, ...formattedUniqueDate].sort((a, b) => a.date.localeCompare(b.date));
    }

    mergedAllFormattedDate(controlData, loadData) {
        const sumDepositsControl = controlData.reduce((acc, deposit) => {
            acc[deposit] = (acc[deposit] ?? 0) + 1;

            return acc;
        }, {});

        const sumDepositsLoad = loadData.reduce((acc, deposit) => {
            acc[deposit] = (acc[deposit] ?? 0) + 1;

            return acc;
        }, {});

        const sameData = [];
        const dateControlDiff = [];
        const dateLoadDiff = [];

        Object.entries(sumDepositsControl).map((type1) => {
            Object.entries(sumDepositsLoad).map((type2) => {
                if (type1[0] === type2[0]) {
                    return sameData.push({
                        date: type1[0],
                        control: type1[1],
                        load: type2[1],
                    });
                }
            });
        });

        Object.entries(sumDepositsLoad)
            .filter((elementToFind) => {
                return !Object.entries(sumDepositsControl).some((elementToCompare) => {
                    return elementToFind[0] === elementToCompare[0];
                });
            })
            .map((el) => {
                dateLoadDiff.push({
                    date: el[0],
                    control: 0,
                    load: el[1],
                });
            });

        Object.entries(sumDepositsControl)
            .filter((elementToFind) => {
                return !Object.entries(sumDepositsLoad).some(
                    (elementToCompare) => elementToFind[0] === elementToCompare[0],
                );
            })
            .map((el) => {
                dateControlDiff.push({
                    date: el[0],
                    control: el[1],
                    load: 0,
                });
            });

        return [...sameData, ...dateLoadDiff, ...dateControlDiff].sort((a, b) => a.date.localeCompare(b.date));
    }

    fetchGlobalDepositDataByDayGrouped(data) {
        const formattedControlsData = data[0].control.map((el) => el.split('T')[0]); // Optimized date formatting YYYY-MM-DD
        const formattedLoadData = data[0].load.map((el) => el.split('T')[0]); // Optimized date formatting YYYY-MM-DD
        const groupedData = [...formattedControlsData, ...formattedLoadData].sort((a, b) => a.localeCompare(b));

        const listAllDateGrouped = this.findAllDatesGrouped(groupedData);
        const dateGlobal = this.mergedAllFormattedDate(formattedControlsData, formattedLoadData);

        return this.mergeDateGlobal(dateGlobal, listAllDateGrouped);
    }

    fetchGlobalDepositDataByMonthGrouped(data) {
        const formattedControlsMonths = data[0].control.map((el) => el.slice(0, 7)); // Optimized date formatting YYYY-MM
        const formattedLoadMonths = data[0].load.map((el) => el.slice(0, 7)); // Optimized date formatting YYYY-MM;

        return this.mergedAllFormattedDate(formattedControlsMonths, formattedLoadMonths);
    }

    loadDayChart(groupRipData) {
        if (this.companyDataSorted.length === 0) {
            return;
        }

        const globalDayDepositData = this.fetchGlobalDepositDataByDayGrouped(groupRipData);

        const days = [];
        const loadedDeposits = [];
        const checkedDeposits = [];

        globalDayDepositData.forEach((month) => {
            days.push(moment(new Date(month.date)).format('DD/MM/YYYY'));
            checkedDeposits.push(month.control || 0);
            loadedDeposits.push(month.load || 0);
        });

        this.globalDepositsChartData = {
            labels: days,
            datasets: [
                {
                    label: this._translate('shared.loadDeposit'),

                    backgroundColor: 'rgba(255, 185, 53, 0.8)',
                    hoverBackgroundColor: '#FFAF1A',
                    hoverRadius: 5,
                    borderColor: '#ffb935',
                    borderWidth: 1,

                    data: loadedDeposits,
                },
                {
                    label: this._translate('shared.controlDeposit'),

                    backgroundColor: 'rgba(11, 153, 224, 0.8)',
                    hoverBackgroundColor: '#008BD1',
                    hoverRadius: 5,
                    borderColor: '#0B99E0',
                    borderWidth: 1,
                    data: checkedDeposits,
                },
            ],
        };
    }

    // Chart global deposits
    loadControlGlobalDeposit(deposits) {
        this.groupRipData = deposits;

        if (this.companyDataSorted.length === 0) {
            return;
        }

        const globalMonthDeposit = this.fetchGlobalDepositDataByMonthGrouped(this.groupRipData);

        const globalMonthDepositsTotal = globalMonthDeposit.map((item) => {
            return {
                date: item.date,
                deposits: (item.control ?? 0) + (item.load ?? 0),
            };
        });

        this.evolutionDepositsChart(globalMonthDepositsTotal);

        const globalMonthDepositFormatted = globalMonthDeposit.map((item) => {
            return {
                date: moment(item.date, 'YYYY-MM').format('MM YYYY'),
                control: item.control,
                load: item.load,
            };
        });

        const months = [];
        const loadedMonthDeposits = [];
        const checkedMonthDeposits = [];

        globalMonthDepositFormatted.forEach((month) => {
            const chartDate = month.date.split(' ');
            const date = moment(new Date(chartDate[1], chartDate[0] - 1, 1)).format('MMMM');

            const monthCapitalize = date.capitalize();
            const convertedDate = `${monthCapitalize} ${chartDate[1]}`;

            months.push(convertedDate);
            loadedMonthDeposits.push(month.load || 0);
            checkedMonthDeposits.push(month.control || 0);
        });

        this.globalMonthDepositsChartData = {
            labels: months,
            datasets: [
                {
                    label: this._translate('shared.loadDeposit'),
                    backgroundColor: 'rgb(255, 185, 53)',
                    hoverBackgroundColor: 'rgb(255, 185, 53)',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: loadedMonthDeposits,
                },
                {
                    label: this._translate('shared.controlDeposit'),

                    backgroundColor: 'rgb(11, 153, 224)',
                    hoverBackgroundColor: 'rgb(11, 153, 224)',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: checkedMonthDeposits,
                },
            ],
        };
    }

    // Chart global load deposit
    ripLoadDeposit(data) {
        // Array of all job load deposit by Company
        const arrayLoad = data[0].arrayLoad;
        // Name of company
        const labelsCompany = data[0].labelsCompany;
        const bestLabel = data[0].bestLabel;

        this.bestLoadRip = data[0].bestLoadRip;

        const bestLoadRipPercent = data[0].bestLoadRipPercent;

        // NB max RIP
        this.bestRipLoad = {
            labels: bestLabel,
            datasets: [
                {
                    label: this._translate('shared.loadDeposit'),
                    backgroundColor: 'rgba(255, 185, 53, 0.8)',
                    hoverBackgroundColor: '#FFAF1A',
                    borderColor: '#fff',
                    borderWidth: 1,
                    categoryPercentage: 0.7,
                    barPercentage: 0.7,
                    maxBarThickness: 15,
                    data: this.bestLoadRip,
                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        font: {
                            weight: 'bold',
                        },
                    },
                },
            ],
        };

        this.allLoadChartData = {
            labels: labelsCompany,
            datasets: [
                {
                    label: this._translate('shared.loadDeposit'),
                    backgroundColor: 'rgba(255, 185, 53, 0.8)',
                    hoverBackgroundColor: '#FFAF1A',
                    borderColor: '#fff',
                    borderWidth: 1,
                    categoryPercentage: 0.7,
                    barPercentage: 0.7,
                    maxBarThickness: 15,
                    data: arrayLoad,
                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        font: {
                            weight: 'bold',
                        },
                    },
                },
            ],
        };

        this.bestLoadRipPercentChart = {
            bigLegend: {
                figure: bestLoadRipPercent.toFixed(1),
                name: '%',
            },
            datasets: [
                {
                    data: [bestLoadRipPercent, 100 - bestLoadRipPercent],
                    backgroundColor: ['rgb(255, 185, 53)', '#e9e9e9'],
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        };
    }

    // Chart global control deposit
    ripControlDeposit(data) {
        // Array of all job load deposit by Company
        const arrayControl = data[0].arrayControl;
        // Name of company
        const labelsCompany = data[0].labelsCompany;
        const bestLabel = data[0].bestLabel;

        this.bestControlRip = data[0].bestControlRip;

        const bestControlRipPercent = data[0].bestControlRipPercent;

        this.bestRipControl = {
            labels: bestLabel,
            datasets: [
                {
                    label: this._translate('shared.controlDeposit'),
                    backgroundColor: 'rgba(11, 153, 224, 0.8)',
                    hoverBackgroundColor: '#008BD1',
                    borderColor: '#fff',
                    categoryPercentage: 0.7,
                    barPercentage: 0.7,
                    maxBarThickness: 15,
                    borderWidth: 1,
                    data: this.bestControlRip,
                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        font: {
                            weight: 'bold',
                        },
                    },
                },
            ],
        };

        this.bestControlRipPercentChart = {
            bigLegend: {
                figure: bestControlRipPercent.toFixed(1),
                name: '%',
            },
            datasets: [
                {
                    data: [bestControlRipPercent, 100 - bestControlRipPercent],
                    backgroundColor: ['rgba(11, 153, 224, 0.8)', '#e9e9e9'],
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        };

        this.allControlChartData = {
            labels: labelsCompany,
            datasets: [
                {
                    label: this._translate('shared.loadDeposit'),
                    backgroundColor: 'rgba(11, 153, 224, 0.8)',
                    hoverBackgroundColor: '#008BD1',
                    borderColor: '#fff',
                    borderWidth: 1,
                    categoryPercentage: 0.7,
                    barPercentage: 0.7,
                    maxBarThickness: 15,
                    data: arrayControl,
                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        font: {
                            weight: 'bold',
                        },
                    },
                },
            ],
        };
    }

    getUniqueArray(arr, keyProps) {
        return Object.values(
            arr.reduce((uniqueMap, entry) => {
                const key = keyProps.map((k) => entry[k]).join('|');
                if (!(key in uniqueMap)) {
                    uniqueMap[key] = entry;
                }

                return uniqueMap;
            }, {}),
        );
    }

    fetchRipByDate(data, type) {
        // Find Rip action by date
        const filteredRipData = data;

        // Create list of all date
        const allFormattedDate = filteredRipData
            .map((item) => item.allDeposits)
            .flat()
            .sort((a, b) => a.localeCompare(b));

        const resultFormatDate = allFormattedDate
            .filter((el, index, arr) => arr.indexOf(el) === index)
            .sort((a, b) => a.localeCompare(b));

        const allRipByDate = [];

        filteredRipData.forEach((item) => {
            return item.allDeposits.map((deposit) => {
                if (resultFormatDate.includes(deposit)) {
                    allRipByDate.push({
                        date: deposit,
                        rip: item.name,
                    });
                }
            });
        });

        const uniqueRipDate = this.getUniqueArray(allRipByDate, ['date', 'rip']);

        if (type === 'rip') {
            return uniqueRipDate.reduce((acc, post) => {
                const { date, rip } = post;

                return { ...acc, [date]: [...(acc[date] || []), rip] };
            }, {});
        }

        return uniqueRipDate.reduce((acc, post) => {
            const { date, rip } = post;

            return { ...acc, [rip]: [...(acc[rip] || []), date] };
        }, {});
    }

    // Chart global rip - user activity
    ripUsersGlobalActivity(deposits, users) {
        const ripData = [];
        deposits.map((item) =>
            item.depositStats.forEach((deposit) => {
                ripData.push({
                    allDeposits: deposit.allDeposits
                        .sort((a, b) => a.localeCompare(b))
                        .map((element) => new Date(element).toISOString().substring(0, 7).replace('-', ' ')), // Optimized date formatting (YYYY MM),
                    lastDeposit: deposit.lastDeposit,
                    phase: deposit.phase,
                    count: deposit.count,
                    status: deposit.status,
                    createdBy: deposit.createdBy,
                    version: deposit.version,
                    name: item.name,
                });
            }),
        );

        const ripByDate = this.fetchRipByDate(ripData, 'rip');

        const globalRipActivityData = Object.entries(ripByDate).map((data) => ({
            date: data[0],
            rip: data[1].length,
        }));

        // get rip activity by date
        this.sortedGloablRipActivity = globalRipActivityData
            .sort((a, b) => a.date.localeCompare(b.date))
            .map((item) => {
                return {
                    date: item.date.split(' ').reverse().join(' '),
                    rip: item.rip,
                };
            });

        // Find all dates of Rip activity to match with users activity date
        const getAllDates = this.sortedGloablRipActivity.map((activity) => ({
            date: moment(activity.date, 'MM YYYY').format('YYYY MM'),
        }));

        // Get users missing activity
        const missingUserActivity = [];
        getAllDates.map((el) => {
            if (!users.some((item) => el.date === item.month)) {
                return missingUserActivity.push({
                    users: 0,
                    month: el.date,
                });
            }
        });

        const userActiveByMonth = users.map((item) => ({
            users: item.users.length,
            month: item.month,
        }));

        // Get all users activity by date
        const globalUserActivity = [...userActiveByMonth, ...missingUserActivity].sort((a, b) =>
            a.month.localeCompare(b.month),
        );

        // filter users activity with same rip activity date
        const userActiveDate = getAllDates
            .map((item) => globalUserActivity.filter((date) => date.month.includes(item.date)))
            .flat();

        const months = [];
        const ripActivity = [];

        // Prepare rip data for chart
        this.sortedGloablRipActivity.forEach((month) => {
            const chartDate = month.date.split(' ');
            const date = moment(new Date(chartDate[1], chartDate[0] - 1, 1)).format('MMM');

            const monthCapitalize = date.capitalize();
            const convertedDate = `${monthCapitalize} ${chartDate[1]}`;

            months.push(convertedDate);

            ripActivity.push(month.rip || 0);
        });

        // If no rip activity avoid chart
        if (this.sortedGloablRipActivity.length === 0) {
            return;
        }

        // Prepare users data for chart
        const userActivity = userActiveDate.map((month) => month.users || 0);

        // Rip users chart
        this.globalActivityByRipChart = {
            labels: months,
            datasets: [
                {
                    label: this._translate('dashboardAdmin.activeRips'),
                    pointBorderColor: '#fff',
                    backgroundColor: 'rgba(36, 92, 148, 0.8)',
                    hoverBackgroundColor: '#3e4862',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: ripActivity,
                },
                {
                    label: this._translate('dashboardAdmin.activeUsers'),
                    pointBorderColor: '#fff',
                    backgroundColor: 'rgba(233, 113, 177, 0.8)',
                    hoverBackgroundColor: '#e03590',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: userActivity,
                },
            ],
        };

        // Send data for evolution chart
        this.evolutionRipChart(this.sortedGloablRipActivity);
        this.evolutionUsersChart(globalUserActivity);
    }

    // Monthly evolution
    evolutionRipChart(selected) {
        // Find current month in JavaScript
        const currentMonthToFind = this.findCurrentMonthDate(selected);
        const accMonthToFind = this.findPreviousMonthDate(currentMonthToFind);
        this.selectedYear = this.findYearDate(currentMonthToFind);

        this.currentMonthNameRip = this.findMonthName(currentMonthToFind, 'current');
        this.prevMonthNameRip = this.findMonthName(currentMonthToFind, 'last');

        const currentMonthData = selected.find((date) => date.date === currentMonthToFind);
        this.findCurrentActivity = currentMonthData ? currentMonthData.rip : 0;

        const prevMonthData = selected.find((date) => date.date === accMonthToFind);
        this.findPrevActivity = prevMonthData ? prevMonthData.rip : 0;

        if (this.findPrevActivity !== 0) {
            const difference = this.findCurrentActivity - this.findPrevActivity;
            this.currentTendancy = (difference / this.findPrevActivity) * 100;
        } else if (this.findPrevActivity === 0) {
            this.currentTendancy = (this.findCurrentActivity - this.findPrevActivity) * 100;
        }

        this.ripEvolPercentChart = {
            bigLegend: {
                figure: this.currentTendancy.toFixed(0),
            },
            datasets: [
                {
                    data: [this.findCurrentActivity, this.findPrevActivity],
                    backgroundColor: ['#4f7ca9', '#e9e9e9'],
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        };
    }

    evolutionUsersChart(userSelected) {
        const userSelectedActivity = userSelected
            .sort((a, b) => a.month.localeCompare(b.month))
            .map((item) => ({
                date: item.month.split(' ').reverse().join(' '),
                users: item.users,
            }));

        const currentMonthToFind = this.findCurrentMonthDate(userSelectedActivity);
        const accMonthToFind = this.findPreviousMonthDate(currentMonthToFind);
        this.selectedYear = this.findYearDate(currentMonthToFind);

        this.currentMonthNameUser = this.findMonthName(currentMonthToFind, 'current');
        this.prevMonthNameUser = this.findMonthName(currentMonthToFind, 'last');

        const currentMonthData = userSelectedActivity.find((date) => date.date === currentMonthToFind);
        this.findCurrentUserActivity = currentMonthData ? currentMonthData.users : 0;

        const prevMonthData = userSelectedActivity.find((date) => date.date === accMonthToFind);
        this.findPrevUserActivity = prevMonthData ? prevMonthData.users : 0;

        if (this.findCurrentUserActivity && this.findCurrentUserActivity !== 0) {
            const differenceUser = this.findCurrentUserActivity - this.findPrevUserActivity;
            this.currentTendancyUser = (differenceUser / this.findPrevUserActivity) * 100;
        } else if (this.findCurrentUserActivity && this.findPrevUserActivity === 0) {
            this.currentTendancyUser = (this.findCurrentUserActivity - this.findPrevUserActivity) * 100;
        }

        this.userEvolPercentChart = {
            bigLegend: {
                figure: this.currentTendancyUser.toFixed(0),
            },
            datasets: [
                {
                    data: [this.findCurrentUserActivity, this.findPrevUserActivity],
                    backgroundColor: ['#ed8dc0', '#e9e9e9'],
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        };
    }

    evolutionDepositsChart(depositsSelected) {
        const sortedGloablDepositisActivity = depositsSelected
            .sort((a, b) => a.date.localeCompare(b.date))
            .map((item) => {
                return {
                    date: item.date.split('-').reverse().join(' '),
                    deposits: item.deposits,
                };
            });

        const currentMonthToFind = this.findCurrentMonthDate(sortedGloablDepositisActivity);
        const accMonthToFind = this.findPreviousMonthDate(currentMonthToFind);
        this.selectedYear = this.findYearDate(currentMonthToFind);

        this.currentMonthNameDeposit = this.findMonthName(currentMonthToFind, 'current');
        this.prevMonthNameDeposit = this.findMonthName(currentMonthToFind, 'last');

        const currentMonthData = sortedGloablDepositisActivity.find((date) => date.date === currentMonthToFind);
        this.findCurrentDepositActivity = currentMonthData ? currentMonthData.deposits : 0;

        const prevMonthData = sortedGloablDepositisActivity.find((date) => date.date === accMonthToFind);
        this.findLastDepositActivity = prevMonthData ? prevMonthData.deposits : 0;

        if (this.findCurrentDepositActivity && this.findLastDepositActivity !== 0) {
            const differenceDeposits = this.findCurrentDepositActivity - this.findLastDepositActivity;
            this.currentTendancyDeposits = (differenceDeposits / this.findLastDepositActivity) * 100;
        } else if (this.findCurrentDepositActivity && this.findLastDepositActivity === 0) {
            this.currentTendancyDeposits = (this.findCurrentDepositActivity - this.findLastDepositActivity) * 100;
        }

        this.depositsEvolPercentChart = {
            bigLegend: {
                figure: this.currentTendancyDeposits.toFixed(0),
            },
            datasets: [
                {
                    data: [this.findCurrentDepositActivity, this.findLastDepositActivity],
                    backgroundColor: ['#33b9b9', '#e9e9e9'],
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        };
    }

    groupRipLastActivity(deposits) {
        const lastActivityRip = deposits.map((item) => ({
            lastActivity: item.lastDeposit.replace(' ', '/'), // optimized date transformation
            rip: item.name,
            job: item.job,
        }));

        const uniqueLastRip = this.getUniqueArray(lastActivityRip, ['lastActivity', 'rip']).sort((a, b) =>
            a.lastActivity.localeCompare(b.lastActivity),
        );

        const groupByRip = uniqueLastRip.reduce(
            (acc, obj) =>
                Object.assign(acc, {
                    [obj.rip]: (acc[obj.rip] || []).concat({
                        lastActivity: obj.lastActivity,
                        job: obj.job,
                    }),
                }),
            {},
        );

        return Object.entries(groupByRip)
            .map((data) => {
                const date = data[1].reduce((a, b) => (new Date(a.lastActivity) > new Date(b.lastActivity) ? a : b));

                return {
                    rip: data[0],
                    date: new Date(date.lastActivity).getTime(),
                    job: date.job,
                    activity: true,
                };
            })
            .sort((a, b) => (b.date > a.date ? 1 : -1));
    }

    // Chart global rip activity
    ripActivity(deposits, allCompanies) {
        const ripDataActive = deposits.reduce((acc, item) => {
            const data = item.depositStats.map((deposit) => ({
                allDeposits: deposit.allDeposits
                    .sort((a, b) => a.localeCompare(b))
                    .map((element) => new Date(element).toISOString().substring(0, 7).split('-').reverse().join(' ')), // Optimized date formatting (MM YYYY),
                lastDeposit: deposit.lastDeposit,
                createdBy: deposit.createdBy,
                job: deposit.job,
                name: item.name,
            }));

            return [...acc, ...data];
        }, []);

        const ripByActivity = this.fetchRipByDate(ripDataActive, 'ripActivity');
        const globalRipActivity = Object.entries(ripByActivity)
            .map((data) => ({
                rip: data[0],
                lastActivity: data[1],
            }))
            .sort((a, b) => b.lastActivity.length - a.lastActivity.length);

        const ripActivityPercent = (globalRipActivity.length * 100) / allCompanies;

        const ripActive = globalRipActivity.length;
        const ripInactive = allCompanies - ripActive;

        const lastActivityRipList = this.groupRipLastActivity(ripDataActive);
        const activeRipList = lastActivityRipList.map((data) => data.rip).flat();

        const allRipList = deposits.map((deposit) => deposit.name).flat();

        allRipList.map((rip) => {
            if (!activeRipList.includes(rip)) {
                lastActivityRipList.push({
                    rip: rip,
                    activity: false,
                    date: '-',
                    job: '-',
                });
            }
        });

        this.ripDataActive = ripDataActive;
        this.lastActivityRipList = lastActivityRipList;
        this.ripActivityPercentChart = {
            bigLegend: {
                figure: ripActivityPercent.toFixed(1),
                name: '% activité',
            },
            labels: [' Rips avec dépôts', ' Rips sans dépôt'],
            datasets: [
                {
                    data: [ripActive, ripInactive],
                    backgroundColor: ['rgba(36, 92, 148, 0.8)', '#e9e9e9'],
                    borderColor: '#fff',
                    borderWidth: 1,
                    cutout: '75%',
                    borderRadius: 30,
                },
            ],
        };
    }

    // Chart global user activity
    usersActivity(usersActivity) {
        this.userGloablActivityList = usersActivity.sort((a, b) => b.createdAt.localeCompare(a.createdAt));

        const userActive = [];
        const userInactive = [];

        this.userGloablActivityList.map((user) => {
            if (user.activity) {
                userActive.push(user);
            } else {
                userInactive.push(user);
            }
        });

        const sumAllUsers = userActive.length + userInactive.length;

        const userActivityPercent = (userActive.length * 100) / sumAllUsers;

        this.usersActivityPercentChart = {
            bigLegend: {
                figure: userActivityPercent.toFixed(1),
                name: '% activité',
            },
            labels: [this._translate('dashboardAdmin.activeUsers'), this._translate('dashboardAdmin.inactiveUsers')],
            datasets: [
                {
                    data: [userActive.length, userInactive.length],
                    backgroundColor: ['rgba(233, 113, 177, 0.8)', '#e9e9e9'],
                    borderColor: '#fff',
                    borderWidth: 1,
                    cutout: '75%',
                    borderRadius: 30,
                },
            ],
        };
    }

    // Chart global phases
    globalPhasesActivity(data) {
        const colors = this._$phaseService.getColors();

        const datasets = Object.keys(colors).map((phaseName) => ({
            label: phaseName,
            backgroundColor: colors[phaseName].backgroundColor,
            hoverBackgroundColor: colors[phaseName].hoverBackgroundColor,
            borderColor: '#fff',
            borderWidth: 1,
            categoryPercentage: 0.7,
            barPercentage: 0.7,
            maxBarThickness: 15,
            data: data[phaseName] || [],
        }));

        this.allPhasesChartData = {
            labels: data.labelsCompany,
            datasets: datasets,
        };
    }
}

angular.module('dotic').component('dashboardExtendedPage', {
    controller: DashboardExtendedPage,
    templateUrl: template,
});
