import template from './cable-length-by-type-indicator.component.html';

class CableLengthByTypeIndicatorComponent {
    data = [];
    dataStructure = {
        data: [],
        iconOptions: [],
        tableChartData: { datasets: [], labels: [] },
        tableData: { columns: [], title: '' },
    };
    parentRef = {};

    constructor(indicatorR00132Service) {
        this._indicatorR00132Service = indicatorR00132Service;
    }

    $onInit() {
        this.dataStructure = this._indicatorR00132Service.generateDataStructure(this.data);
    }
}

angular.module('dotic').component('cableLengthByTypeIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: CableLengthByTypeIndicatorComponent,
    templateUrl: template,
});
