import { cluster as radashCluster } from 'radash';

import { IndicatorNameEnum } from '../../../sdk/connect-control-api-v1';

export class IndicatorChartService {
    constructor(
        indicatorR00132Service,
        indicatorR00138Service,
        indicatorR00139Service,
        indicatorR00140Service,
        indicatorR00141Service,
        indicatorR00142Service,
        indicatorR00143Service,
        indicatorR00144Service,
        indicatorR00145Service,
        indicatorR00146Service,
        indicatorR00148Service,
        indicatorR00149Service,
        indicatorR00150Service,
        indicatorR00830Service,
    ) {
        this._indicatorR00132Service = indicatorR00132Service;
        this._indicatorR00138Service = indicatorR00138Service;
        this._indicatorR00139Service = indicatorR00139Service;
        this._indicatorR00140Service = indicatorR00140Service;
        this._indicatorR00141Service = indicatorR00141Service;
        this._indicatorR00142Service = indicatorR00142Service;
        this._indicatorR00143Service = indicatorR00143Service;
        this._indicatorR00144Service = indicatorR00144Service;
        this._indicatorR00145Service = indicatorR00145Service;
        this._indicatorR00146Service = indicatorR00146Service;
        this._indicatorR00148Service = indicatorR00148Service;
        this._indicatorR00149Service = indicatorR00149Service;
        this._indicatorR00150Service = indicatorR00150Service;
        this._indicatorR00830Service = indicatorR00830Service;

        this.IndicatorNameEnum = new IndicatorNameEnum();
    }

    static getClusteredData(dataStructures, size = 20) {
        return Object.entries(dataStructures).map(([indicatorName, dataStructure]) => {
            return {
                columns: dataStructure.tableData.columns,
                data: radashCluster(dataStructure.data, size),
                indicator: indicatorName,
                options: {
                    ...(dataStructure.ANCTCorrespondenceNumber && {
                        ANCTCorrespondenceNumber: dataStructure.ANCTCorrespondenceNumber,
                    }),
                },
                title: dataStructure.tableData.title,
            };
        });
    }

    generateANCTFullDataStructure(ANCTData) {
        return Object.entries(ANCTData).reduce((acc, [indicatorName, data]) => {
            return {
                ...acc,
                [indicatorName]: this.generateDataStructure(data, indicatorName),
            };
        }, {});
    }

    // Only concern ANCT indicators for now
    generateDataStructure(data, indicatorName) {
        switch (indicatorName) {
            case this.IndicatorNameEnum.CABLE_LENGTH_BY_IMPLANTATION:
                return this._indicatorR00141Service.generateDataStructure(data);
            case this.IndicatorNameEnum.CABLE_LENGTH_BY_ORGANIZATION_AND_DESIGN:
                return this._indicatorR00138Service.generateDataStructure(data);
            case this.IndicatorNameEnum.CABLE_LENGTH_BY_OWNER_AND_MANAGER:
                return this._indicatorR00145Service.generateDataStructure(data);
            case this.IndicatorNameEnum.CABLE_LENGTH_BY_SLICE_TYPE:
                return this._indicatorR00146Service.generateDataStructure(data);
            case this.IndicatorNameEnum.CABLE_LENGTH_BY_TYPE:
                return this._indicatorR00132Service.generateDataStructure(data);
            case this.IndicatorNameEnum.CODE_HEXACLE:
                return this._indicatorR00142Service.generateDataStructure(data);
            case this.IndicatorNameEnum.FIELD_OCCURRENCE:
                return this._indicatorR00140Service.generateDataStructure(data);
            case this.IndicatorNameEnum.LOCAL_NUMBER_BY_DISTANCE_RANGE:
                return this._indicatorR00143Service.generateDataStructure(data);
            case this.IndicatorNameEnum.LOCAL_NUMBER_BY_IMPLANTATION:
                return this._indicatorR00139Service.generateDataStructure(data);
            case this.IndicatorNameEnum.LOCAL_NUMBER_BY_LOGICAL_TYPE:
                return this._indicatorR00150Service.generateDataStructure(data);
            case this.IndicatorNameEnum.LOCAL_NUMBER_BY_SITE_LOGICAL_TYPE:
                return this._indicatorR00149Service.generateDataStructure(data);
            case this.IndicatorNameEnum.NRO_NRA_DISTANCE:
                return this._indicatorR00830Service.generateDataStructure(data);
            case this.IndicatorNameEnum.PBO_DATA:
                return this._indicatorR00144Service.generateDataStructure(data);
            case this.IndicatorNameEnum.SITE_NUMBER_BY_LOGICAL_TYPE:
                return this._indicatorR00148Service.generateDataStructure(data);
            default:
                throw `Unknown indicator name: ${indicatorName}`;
        }
    }
}

angular.module('dotic').factory('indicatorChartService', IndicatorChartService);
