import { DoughnutChartColorEnum } from '../../../../services/color.service';
import template from './pto-status-indicator.component.html';

class PtoStatusIndicatorComponent {
    static bigLegendName = 'PTO';
    chartData = { bigLegend: {}, datasets: [], labels: [] };
    chartSize = 180;
    data = { ptoKo: 0, ptoOk: 0 };
    isParentLoading = false;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    $onInit() {
        this.chartData = this.getChartData(this.data);
    }

    getChartData(values) {
        return {
            bigLegend: {
                figure: values.ptoKo + values.ptoOk,
                name: this._translate('shared.address', { COUNT: values.ptoKo + values.ptoOk }),
            },
            datasets: [
                {
                    data: [values.ptoOk, values.ptoKo],
                    backgroundColor: [DoughnutChartColorEnum.BIG_SUCCESS, DoughnutChartColorEnum.BIG_FAILURE],
                },
            ],
            labels: [
                this._translate('shared.consistentResult', { COUNT: values.ptoOk }),
                this._translate('shared.inconsistentResult', { COUNT: values.ptoOk, SUFFIX: 'pto' }),
            ],
        };
    }
}

angular.module('dotic').component('ptoStatusIndicator', {
    controller: PtoStatusIndicatorComponent,
    templateUrl: template,
    bindings: {
        data: '<',
        isParentLoading: '<',
    },
});
