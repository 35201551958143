import template from './simple-table.component.html';

export class SimpleTableComponent {
    // columns is of type Array<{ isPercentage?: boolean, key: string, translation: string }>
    columns = [];
    data = [];
    tableTitle = '';
}

angular.module('dotic').component('simpleTable', {
    bindings: {
        columns: '<',
        data: '<',
        tableTitle: '<?',
    },
    controller: SimpleTableComponent,
    templateUrl: template,
});
