import template from './icon-indicator.component.html';

class IconIndicatorComponent {
    size = '60px';
}
angular.module('dotic').component('iconIndicator', {
    bindings: {
        position: '@',
        reverse: '@',
        value: '<',
        result2: '<',
        text: '@',
        icon: '@',
        textIcon: '@',
        textIconSmall: '@',
        color: '@',
        size: '@?',
        unit: '@',
        unit2: '@',
        compare: '<',
        value1: '<',
        value2: '<',
        tooltip: '@',
    },
    controller: IconIndicatorComponent,
    templateUrl: template,
});
