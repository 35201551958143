import { ReportsApi, UsersApi } from '../../../../sdk/connect-control-api-v1/src';
import template from './anct-verifications-detail.component.html';

class ANCTVerificationsDetailComponent {
    ANCTControls = [];
    ANCTDataStructure = {};
    ANCTVerification = {};
    isAnomaliesVisible = false;
    isDownloading = false;
    isIndicatorsVisible = false;
    loading = true;
    report = {};
    tableDetail = {};

    constructor(
        $apiClientService,
        $auth,
        $authorizationService,
        $fileService,
        $filter,
        $location,
        $modalService,
        $reportService,
        $scope,
        $segmentsService,
        $state,
        $stateParams,
        $tableService,
        $timeout,
        $toasterService,
        indicatorChartService,
        LoaderService,
        RestProvider,
    ) {
        this._$auth = $auth;
        this._$authorizationService = $authorizationService;
        this._$fileService = $fileService;
        this._$location = $location;
        this._$modalService = $modalService;
        this._$reportService = $reportService;
        this._$scope = $scope;
        this._$segmentsService = $segmentsService;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$tableService = $tableService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._indicatorChartService = indicatorChartService;
        this._LoaderService = LoaderService;
        this._RestProvider = RestProvider;
        this._reportApi = new ReportsApi($apiClientService.client);
        this._translate = $filter('translate');
        this._usersApi = new UsersApi($apiClientService.client);
    }

    async $onInit() {
        this.loading = true;

        try {
            this.ANCTVerification = (
                await this._reportApi.getReportStatisticsWithHttpInfo(this._$stateParams.id)
            ).response.body.indicators.ANCT;

            this.ANCTDataStructure = this._indicatorChartService.generateANCTFullDataStructure(this.ANCTVerification);

            const report = (await this._reportApi.getDetailsWithHttpInfo(this._$stateParams.id, { isANCT: true }))
                .response.body;
            const networkSegments = this._$segmentsService.setNetworkSegments(report.deposit.networkSegments);
            this.report = {
                ...report,
                deposit: {
                    ...report.deposit,
                    networkSegments: networkSegments,
                },
            };

            this.ANCTControls = this._$reportService.getControls(report.controls, report.deposit.gridControl).controls;

            this.user = (await this._usersApi.getByIdWithHttpInfo(this._$auth.getPayload().userId)).response.body;
            this.tableDetail = this._$tableService.detail();
        } catch (error) {
            this._$toasterService.error(error);
        }

        this._$scope.$emit('keepPreviousNavigation', {
            allowedPreviousKeys: ['anctVerifications'],
            defaultPrevious: {
                title: this._translate('shared.ANCTVerifiedDeposit'),
                href: this._$state.href('app.anctVerificationsList'),
                key: 'anctVerifications',
            },
            newPage: [
                {
                    key: 'anctVerification',
                    title: `${this._translate('shared.report', { COUNT: 1 })} ${this.report?.deposit.name ?? ''}`,
                    href: this._$location.path(),
                },
            ],
        });

        this._$timeout(() => {
            this.loading = false;
        });
    }

    async downloadControlShape(control) {
        this._LoaderService.open(`
        <p>${this._translate('shared.downloadingFile')}</p>
        <p>${this._translate('shared.timeConsuming')}</p>`);

        try {
            await this._RestProvider.downloadFromPath(
                `reports/${this._$stateParams.id}/controls/${control.controlPoint}/xlsx`,
                {
                    severity: control.severity,
                },
            );
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        } finally {
            this._LoaderService.dismiss();
        }
    }

    async downloadReportExcel() {
        this.isDownloading = true;

        try {
            const response = (await this._reportApi.downloadANCTReportXlsxWithHttpInfo(this._$stateParams.id)).response;
            this._$fileService.downloadFileFromResponse(response);
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        }

        this.isDownloading = false;
    }

    async downloadReportPdf() {
        this.isDownloading = true;

        try {
            await this._RestProvider.downloadFromPath(`reports/${this.report.id}/anct/pdf`);
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        }

        this.isDownloading = false;
    }

    toggleAnomaliesVisibility() {
        this.isAnomaliesVisible = !this.isAnomaliesVisible;
    }

    toggleControlDetailModal(control) {
        if (!control.invalid_count) {
            return;
        }

        return this._$modalService.triggerControlErrorModal(
            control,
            this._$reportService.getErrors(this._$stateParams.id, control.controlPoint, control.severity),
        );
    }

    toggleIndicatorsVisibility() {
        this.isIndicatorsVisible = !this.isIndicatorsVisible;
    }
}

angular.module('dotic').component('anctVerificationsDetail', {
    controller: ANCTVerificationsDetailComponent,
    templateUrl: template,
});
