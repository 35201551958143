angular.module('dotic').factory('serverProvider', serverProvider);

function serverProvider(ApiProvider, URI_KEYS) {
    return {
        getAll: getAll,
    };

    function getAll(queryParams = {}) {
        return ApiProvider.one(URI_KEYS.SERVER).customGET(null, queryParams);
    }
}
