import { ColorService } from '../../color.service';

const KeyEnum = {
    ADDRESS_COUNT: 'addressCount',
    LOGICAL_TYPE: 'logicalType',
};

// Linked to local-number-by-logical-type indicator.
class IndicatorR00150Service {
    static ANCTCorrespondenceNumber = 13;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    generateDataStructure(data) {
        const iconOptions = data.map((currentData) => {
            return {
                color: ColorService.getSiteLogicalTypeColor(currentData[KeyEnum.LOGICAL_TYPE]),
                icon: 'home',
                label: currentData[KeyEnum.LOGICAL_TYPE],
                value: currentData[KeyEnum.ADDRESS_COUNT],
            };
        });

        return {
            ANCTCorrespondenceNumber: IndicatorR00150Service.ANCTCorrespondenceNumber,
            data: data,
            iconOptions: iconOptions,
            tableData: {
                columns: this.getColumns(),
                title: this._translate('shared.detailLocalCount'),
            },
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.LOGICAL_TYPE,
                translation: this._translate('shared.logicalType'),
            },
            {
                key: KeyEnum.ADDRESS_COUNT,
                translation: this._translate('shared.addressCount'),
            },
        ];
    }
}

angular.module('dotic').factory('indicatorR00150Service', IndicatorR00150Service);
