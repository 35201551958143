// Imports
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../../assets/images/help/remplissage/fillGrid-attribut-disabled.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../../assets/images/help/remplissage/fillGrid-attribut-activated.jpg");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../../../assets/images/help/remplissage/fillGrid-severity.jpg");
// Module
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h4 class=\"help-Doc__subTitre5 help_Doc__paddingBottom-paddingTop\"> 3.1.3.3 / {{ 'helpConfig.propertyStep' | translate }} : </h4> <ul class=\"help-Doc__ul\"> <li class=\"help-Doc__subTitre4\"> {{ 'helpConfig.enableDisableStep' | translate }} : <div class=\"help-Doc__center\"> <div class=\"row\"> <div class=\"col-md-offset-2 col-md-4 help-Doc__positionLegende\"> <span class=\"help-Doc__span\"> <em class=\"help-Doc__em-Alerte\">{{ 'shared.disable' | translate }} :</em> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_0___ + "\" alt=\"{{ 'shared.disable' | translate }}\"/> </span> </div> </div> <div class=\"row\"> <div class=\"col-md-offset-2 col-md-4 help-Doc__positionLegende\"> <span class=\"help-Doc__span\"> <em class=\"help-Doc__em-Alerte\">{{ 'helpConfig.enable' | translate }} :</em> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_1___ + "\" alt=\"{{ 'helpConfig.enable' | translate }}\"/> </span> </div> </div> </div> </li> <li class=\"help-Doc__subTitre4\"> {{ 'helpConfig.modifySeverity' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_2___ + "\" alt=\"{{ 'helpConfig.modifySeverity' | translate }}\"/> </li> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/gestionConfigurations/grillesRemplissage/parametrerAttributGRemplissage.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;