import template from './tree-indicator.component.html';

class TreeIndicatorComponent {
    constructor($element, $timeout) {
        this._$element = $element;
        this._$timeout = $timeout;
        this.childrenX = [];
        this.loaded = false;
    }

    $onInit() {
        this.loaded = false;
        if (!angular.isArray(this.children)) {
            return;
        }

        const length = this.children.length;

        if (length === 1) {
            this.childrenX = [300];
        } else if (length === 2) {
            this.childrenX = [150, 450];
        } else if (length === 3) {
            this.childrenX = [150, 300, 450];
        } else if (length === 4) {
            this.childrenX = [40, 200, 400, 550];
        }

        this._$timeout(() => {
            this.childrenX.forEach((x, index) => {
                const line = this._$element.find(`.line-${index}`)[0];
                line.setAttribute('x2', x);

                if (length % 2 === 1) {
                    const parentValue = this._$element.find(`.parent-value`)[0];
                    parentValue.setAttribute('x', 340);
                    parentValue.setAttribute('y', 0);
                }

                const circle = this._$element.find(`.child-circle-${index}`)[0];
                circle.setAttribute('cx', x);
                circle.setAttribute('fill', this.children[index].color);

                const icon = this._$element.find(`.child-circle-icon-${index}`)[0];
                icon.setAttribute('x', x - 35);

                const value = this._$element.find(`.child-value-${index}`)[0];
                value.setAttribute('x', x - 80);
            });
            this.loaded = true;
        });
    }
}

angular.module('dotic').component('treeIndicator', {
    controller: TreeIndicatorComponent,
    templateUrl: template,
    bindings: {
        parent: '<',
        children: '<',
        result2: '<',
        unit2: '@',
        compare: '@',
    },
});
