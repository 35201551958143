import { group as radashGroup, sum as radashSum, unique as radashUnique } from 'radash';
import { ChartService } from '../../chart.service';
import { ColorService } from '../../color.service';
import { DistanceRangeEnum } from '../../../models/grace.model';

const KeyEnum = {
    DISTANCE_RANGE: 'distanceRange',
    IMPLANTATION: 'implantation',
    LOCAL_COUNT: 'localCount',
};

// Linked to local-number-by-distance-range indicator.
class IndicatorR00143Service {
    static ANCTCorrespondenceNumber = 15;
    static orderedDistanceRanges = [
        DistanceRangeEnum.FROM_0_TO_50,
        DistanceRangeEnum.FROM_50_TO_100,
        DistanceRangeEnum.FROM_100_TO_150,
        DistanceRangeEnum.FROM_150_TO_200,
        DistanceRangeEnum.FROM_200_TO_300,
        DistanceRangeEnum.FROM_300_TO_500,
        DistanceRangeEnum.MORE_THAN_500,
    ];

    constructor($filter) {
        this._translate = $filter('translate');
    }

    generateDataStructure(data) {
        return {
            ANCTCorrespondenceNumber: IndicatorR00143Service.ANCTCorrespondenceNumber,
            data: data,
            doughnutChartData: this.getDoughnutChartData(data),
            tableChartData: this.getTableChartData(data),
            tableData: {
                columns: this.getColumns(),
                title: this._translate('shared.detailLocalCount'),
            },
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.IMPLANTATION,
                translation: this._translate('shared.implantationType'),
            },
            {
                key: KeyEnum.DISTANCE_RANGE,
                translation: `${this._translate('shared.distanceRange')} (m)`,
            },
            {
                key: KeyEnum.LOCAL_COUNT,
                translation: this._translate('indicator.localNumber'),
            },
        ];
    }

    getDoughnutChartData(data) {
        const elementsGroupedByDistanceRange = radashGroup(data, (currentData) => currentData[KeyEnum.DISTANCE_RANGE]);
        const localCounts = IndicatorR00143Service.orderedDistanceRanges.reduce((acc, distanceRange) => {
            return {
                ...acc,
                [distanceRange]: radashSum(
                    elementsGroupedByDistanceRange[distanceRange] || [],
                    (currentData) => currentData[KeyEnum.LOCAL_COUNT] || 0,
                ),
            };
        }, {});

        const localCount = radashSum(Object.values(localCounts));

        const colors = Object.keys(localCounts).map((distanceRange) =>
            ColorService.getDistanceRangeColor(distanceRange),
        );

        return {
            bigLegend: {
                figure: localCount,
                name: this._translate('shared.local', { COUNT: localCount }),
            },
            datasets: [
                {
                    backgroundColor: colors,
                    data: Object.values(localCounts),
                },
            ],
            labels: IndicatorR00143Service.orderedDistanceRanges.map((distanceRange) => `${distanceRange} m`),
        };
    }

    getTableChartData(data) {
        const implantations = radashUnique(data.map((currentData) => currentData[KeyEnum.IMPLANTATION]));

        const datasets = implantations.map((implantationType) => {
            const filteredData = data.filter(
                (currentData) => !implantationType.localeCompare(currentData[KeyEnum.IMPLANTATION]),
            );

            const localCounts = IndicatorR00143Service.orderedDistanceRanges.map((distanceRange) => {
                const dataWithProperDistanceRange = filteredData.find(
                    (currentData) => !distanceRange.localeCompare(currentData[KeyEnum.DISTANCE_RANGE]),
                );

                if (!dataWithProperDistanceRange) {
                    return 0;
                }

                return dataWithProperDistanceRange[KeyEnum.LOCAL_COUNT];
            });

            return ChartService.getTableChartDataset(
                localCounts,
                implantationType,
                ColorService.getImplantationTypeColor(implantationType),
            );
        });

        return {
            datasets: datasets,
            labels: IndicatorR00143Service.orderedDistanceRanges,
        };
    }
}

angular.module('dotic').factory('indicatorR00143Service', IndicatorR00143Service);
