import { SimpleTableComponent } from '../simple-table/simple-table.component';
import template from './paginated-table.component.html';

class PaginatedTableComponent extends SimpleTableComponent {}

angular.module('dotic').component('paginatedTable', {
    bindings: {
        columns: '<',
        data: '<',
        tableTitle: '<?',
    },
    controller: PaginatedTableComponent,
    templateUrl: template,
});
