import { FeatureEnum } from '../../guards/has-feature.guard';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.statisticsHome', {
            url: '/statistics/control',
            component: 'statisticsPage',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
            resolve: {
                canActivate: [
                    'hasFeatureGuard',
                    (hasFeatureGuard) => {
                        return hasFeatureGuard(FeatureEnum.STATISTICS);
                    },
                ],
            },
        })
        .state('app.statisticDetail', {
            url: '/statistics/control/{statisticId:[A-Za-z0-9]+}/view?severity&deposit&diff&result&object&project&tableName&thematic&ratioMin&ratioMax&deliveryZone&attributeName&search',
            params: {
                severity: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                deposit: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                diff: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                result: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                object: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                project: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                tableName: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                thematic: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                ratioMin: {
                    value: '0',
                    squash: true,
                    dynamic: true,
                },
                ratioMax: {
                    value: '100',
                    squash: true,
                    dynamic: true,
                },
                deliveryZone: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                attributeName: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                search: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
            },
            component: 'statisticDetailPage',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
            resolve: {
                canActivate: [
                    'hasFeatureGuard',
                    (hasFeatureGuard) => {
                        return hasFeatureGuard(FeatureEnum.STATISTICS);
                    },
                ],
            },
        });
    $stateProvider.state('app.statisticsCompare', {
        url: '/statistics/control/{idStatisticsOrigin:[A-Za-z0-9]+}/{idStatisticsToCompare:[A-Za-z0-9]+}/compare?deposit&severity&tableName&object&thematic&search',
        component: 'statisticsComparePage',
        params: {
            deposit: {
                value: '',
                squash: true,
                dynamic: true,
            },
            severity: {
                value: '',
                squash: true,
                dynamic: true,
            },
            tableName: {
                value: '',
                squash: true,
                dynamic: true,
            },
            object: {
                value: '',
                squash: true,
                dynamic: true,
            },
            thematic: {
                value: '',
                squash: true,
                dynamic: true,
            },
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
        resolve: {
            canActivate: [
                'hasFeatureGuard',
                (hasFeatureGuard) => {
                    return hasFeatureGuard(FeatureEnum.STATISTICS);
                },
            ],
        },
    });
});
