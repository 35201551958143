import bulkTemplate from './bulk.html';
import { DataModelMongoRegexEnum } from '../../models/data-model.model';
import { DepositsGeoPackageApi } from '../../../sdk/connect-control-api-v1/src';
import EnvironmentSettingsService from '../../services/environment-settings.service';
import { FMEJobEnum } from '../../models/deposit.model';
import helpZipModaleTemplate from '../../components/modals/helpZipModal/helpZipModal.html';

class BulkPage {
    bulk = {
        deposits: [],
        files: [],
    };
    exportGeoPackageId = null;
    failureCounter = 0;
    loading = false;
    projectList = [];
    successCounter = 0;
    totalCounter = 0;
    user = {};
    waiting = false;

    constructor(
        $auth,
        $apiClientService,
        $deliveryZoneService,
        $filter,
        $http,
        $interval,
        $location,
        $log,
        $modalService,
        $projectService,
        $scope,
        $segmentsService,
        $stateParams,
        $timeout,
        $toasterService,
        $uibModal,
        $window,
        companyProvider,
        controlConfigurationProvider,
        dataModelProvider,
        depositProvider,
        projectProvider,
        userProvider,
        userMetricsProvider,
    ) {
        this._$deliveryZoneService = $deliveryZoneService;
        this._$projectService = $projectService;
        this._$toasterService = $toasterService;
        this._$timeout = $timeout;
        this._$interval = $interval;
        this._$http = $http;
        this._$uibModal = $uibModal;
        this._$log = $log;
        this._$modalService = $modalService;
        this._$window = $window;
        this._companyProvider = companyProvider;
        this._controlConfigurationProvider = controlConfigurationProvider;
        this._depositProvider = depositProvider;
        this._dataModelProvider = dataModelProvider;
        this._exportGeoPackageApi = new DepositsGeoPackageApi($apiClientService.client);
        this._projectProvider = projectProvider;
        this._userMetricsProvider = userMetricsProvider;
        this._userProvider = userProvider;
        this._translate = $filter('translate');
        this._$segmentsService = $segmentsService;

        this.companyId = $auth.getPayload().company;
        this.exportGeoPackageId = $stateParams.exportGeoPackageId ?? null;
        this.hasFttxIndicatorsStateParam = $stateParams.hasFttxIndicators ?? false;
        this.isANCTStateParam = $stateParams.isANCT ?? false;
        this.isOrange = EnvironmentSettingsService.isOrange();
        this.userId = $auth.getPayload().userId;

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'bulk',
                    title: this._translate('shared.deliverablesDeposit'),
                    href: $location.path(),
                },
            ],
        });

        $scope.$watchGroup(['$ctrl.totalCounter', '$ctrl.successCounter', '$ctrl.failureCounter'], () => {
            if (this.waiting) {
                if (this.totalCounter > 0) {
                    if (this.totalCounter === this.successCounter + this.failureCounter) {
                        this.waiting = false;
                    }
                }
            }
        });

        this._$window.onbeforeunload = (e) => {
            e.preventDefault();
            e.returnValue = 'Êtes-vous sûr de vouloir quitter la page ?';

            return 'Êtes-vous sûr de vouloir quitter la page ?';
        };
    }

    $onDestroy() {
        this._$window.onbeforeunload = (e) => {
            delete e['returnValue'];
        };
        this._$window.onbeforeunload = undefined;
    }

    async $onInit() {
        this.loading = true;

        try {
            await this._userMetricsProvider.openDepositBulk();
            this.company = await this._companyProvider.get(this.companyId);
            this.user = await this._userProvider.get(this.userId);

            if (this.exportGeoPackageId) {
                const exportGeoPackage = (
                    await this._exportGeoPackageApi.geoPackageGetOneWithHttpInfo(this.exportGeoPackageId)
                ).response.body;
                await this.initBulkWithGeoPackage(exportGeoPackage);
            }
        } catch {
            this._$toasterService.error(this._translate('toaster.error'));
        }
        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    async detectDepositData(filename) {
        const projectRegex = new RegExp(
            '^([a-z0-9. -]+)_([a-z0-9. -]+)_(PRE|DIA|AVP|PRO|EXE|REC|MCO|TVX|ACT)(?:_([TR|DI|RA|-]+))?(?:_(V[0-9]{3}|V[0-9]{3}[a-z]{5}))?_.*?.zip$',
            'i',
        );
        const regexResult = projectRegex.exec(filename);

        const defaultDeposit = {
            controlConfiguration: null,
            projectId: '',
        };

        if ((regexResult?.length ?? 0) < 3) {
            return defaultDeposit;
        }

        const deliveryZone = await this._$deliveryZoneService.getDeliveryZoneByName(regexResult[1], regexResult[2]);
        const networkSegments = this._$segmentsService.getNetworkSegmentsFromRegexValue(regexResult[4], regexResult[2]);
        const phase = regexResult[3]?.toLocaleUpperCase();

        // Resolve dataModel, if there is no match from the regex, use the default dataModel.
        let dataModelSlug = regexResult[5];
        if (!dataModelSlug && deliveryZone?.project?.controlConfigurations) {
            const dataModelList = deliveryZone.project.controlConfigurations.map(
                (controlConfiguration) => controlConfiguration.dataModel.regexSlug,
            );
            dataModelSlug = dataModelList.find((slug) => slug === 'V201patch'); // Defining 201patch as default
            if (!dataModelSlug && dataModelList.length > 0) {
                dataModelSlug = dataModelList[0];
            }
        }

        const transformSlug = dataModelSlug?.toLocaleLowerCase();
        dataModelSlug = transformSlug?.[0].toUpperCase() + transformSlug?.substring(1);

        let dataModel = null;
        try {
            if (dataModelSlug !== DataModelMongoRegexEnum.V301 || this.company.hasV301ModelEnabled) {
                dataModel = (await this._dataModelProvider.getAll({ slug: dataModelSlug }))[0];
            }
        } catch {
            // Empty. The fetch can throw, though it shouldn't be an error as it's depending on a name provided by the user.
        }

        let controlConfiguration;
        if (dataModelSlug && deliveryZone?.project) {
            controlConfiguration = this._$projectService.getControlConfigurationFromSlugInProject(
                deliveryZone.project,
                dataModelSlug,
            );
        }

        return {
            ...defaultDeposit,
            controlConfiguration: controlConfiguration,
            dataModel: dataModel,
            deliveryZone: deliveryZone,
            networkSegments: networkSegments,
            phase: phase,
            project: deliveryZone?.project,
            projectId: deliveryZone?.projectId,
        };
    }

    async initBulkWithGeoPackage(exportGeoPackage) {
        const defaultDeposit = {
            controlConfiguration: null,
            networkSegments: exportGeoPackage.networkSegments,
            projectId: '',
        };

        try {
            const geoPackageFileBlob = (
                await this._exportGeoPackageApi.geoPackageDownloadWithHttpInfo(exportGeoPackage.id)
            ).response.body;

            const dataModel = await this._dataModelProvider.get(exportGeoPackage.dataModel);
            const controlConfigurations = await this._controlConfigurationProvider.getAll({ dataModel: dataModel._id });

            let project = null;
            if (exportGeoPackage.projects.length === 1) {
                project = await this._projectProvider.get(exportGeoPackage.projects[0]);
            }
            const deposit = {
                ...defaultDeposit,
                controlConfiguration:
                    controlConfigurations.find(
                        (controlConfiguration) => controlConfiguration.isDefaultConfigurationDataModel,
                    ) ?? null,
                dataModel: dataModel,
                file: new File([geoPackageFileBlob, { type: 'application/zip' }], `${exportGeoPackage.name}.zip`, {
                    type: 'application/zip',
                }),
                hasFttxIndicators: this.hasFttxIndicatorsStateParam,
                isANCT: this.isANCTStateParam,
                project: project,
            };

            this.bulk.deposits = [...this.bulk.deposits, deposit];
            this.bulk.files = [...this.bulk.files, deposit.file];
        } catch {
            this._$toasterService.error(this._translate('toaster.error'));
        }
    }

    async openDepositModal(deposit, index) {
        const result = await this._$modalService.triggerEditBulkElementModal(deposit);

        if (!result) {
            return;
        }

        this._$timeout(() => {
            this.bulk.deposits[index].project = result.project;
            this.bulk.deposits[index].phase = result.phase;
            this.bulk.deposits[index].controlConfiguration = result.configuration;
            this.bulk.deposits[index].deliveryZone = result.deliveryZone;
            this.bulk.deposits[index].projectId = result.projectId;
            // Order segments from edit deposit
            this.bulk.deposits[index].networkSegments = this._$segmentsService.sortSegments(result.networkSegments);
            this.bulk.deposits[index].dataModel = result.configuration.dataModel;
            this.bulk.deposits[index].isMatchingConfiguration = !!result.configuration;
            this.bulk.deposits[index].hasFttxIndicators = !!result.hasFttxIndicators;
            this.bulk.deposits[index].isANCT = !!result.isANCT;
        });
    }

    async onFileSelected() {
        if (!angular.isArray(this.bulk.files)) {
            return;
        }

        const promises = this.bulk.files.map(async (file) => {
            const data = await this.detectDepositData(file.name);

            return {
                ...data,
                file: file,
                status: {
                    load: [],
                    control: [],
                },
            };
        });

        const deposits = await Promise.all(promises);
        this._$timeout(() => {
            this.bulk.deposits = deposits;
        });
    }

    showHelp() {
        this._$uibModal.open({
            controller: 'HelpZipModalController as $ctrl',
            size: 'xl',
            templateUrl: helpZipModaleTemplate,
        });
    }

    async removeDeposit(index) {
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.deposit').toLowerCase(),
        );
        if (!isAccepted) {
            return;
        }

        this.bulk.deposits.splice(index, 1);
    }

    pushDeposits(job) {
        if (!this.areDepositsIsValid()) {
            return false;
        }

        this.totalCounter = 0;
        this.successCounter = 0;
        this.failureCounter = 0;
        this.waiting = true;

        this.bulk.deposits = this.bulk.deposits.map((deposit) => {
            deposit.status = { load: [], control: [] };

            return deposit;
        });

        this.bulk.deposits.forEach((deposit, index) => this.prepareDeposit(deposit, index, job));
    }

    addStatus(indexDeposit, job, message, status) {
        if (status === 'error') {
            this.failureCounter += 1;
        }

        this.bulk.deposits[indexDeposit].status[job].push({ message, status });
    }

    /**
     *
     * @param {deposit} Deposit
     * @param {number} index
     * @param {string} job
     * @param {number} idFME
     * @return {*}
     */
    checkDepositStatus(deposit, index, job, idFME) {
        return this._depositProvider
            .getJobStatus(deposit.id)
            .then((result) => {
                switch (result.status) {
                    case 'DONE_WITH_ERROR': {
                        let errorMessage = 'Traitement du livrable en échec';
                        if (this.isOrange || this.user.settings.feature.hasDebugMode) {
                            errorMessage = `Tâche #${idFME} en échec`;
                        }

                        this.addStatus(index, job, errorMessage, 'error');
                        break;
                    }

                    case 'DONE': {
                        this.successCounter += 1;

                        let successMessage = 'Traitement du livrable réussi';
                        if (this.isOrange || this.user.settings.feature.hasDebugMode) {
                            successMessage = `Tâche #${idFME} réussie`;
                        }

                        this.addStatus(index, job, successMessage, 'success');
                        break;
                    }

                    case 'QUEUED':
                    case 'RUNNING':
                    case 'UNKNOWN':
                    default:
                        this._$timeout(() => this.checkDepositStatus(deposit, index, job, idFME), 10000);
                        break;
                }
            })
            .catch((error) => {
                if (error.status === 404) {
                    return;
                }

                const jobName = this._translate(`bulk.job.${job}`);
                this._$log.warn(`Erreur job ${jobName} #${idFME} pour livrable #${index}`, error);

                this._$timeout(() => this.checkDepositStatus(deposit, index, job, idFME), 10000);
            });
    }

    processDeposit(file, deposit, index, job) {
        const jobName = this._translate(`bulk.job.${job}`);

        this.addStatus(index, job, `Début ${jobName}`, 'success');

        return this._companyProvider
            .createAndRunDeposit(this.companyId, file, deposit)
            .then((sendDeposit) => {
                sendDeposit.data.map((deposit) => {
                    this.checkJobReadyInterval(deposit, index, job);
                });
            })
            .catch((error) => {
                this._$interval.cancel();

                this.onProcessDepositError(index, job, error);
            });
    }

    /**
     * Check if the job in ready to be run (unzip may take long)
     * @param deposit
     * @param index
     * @param job
     */
    checkJobReadyInterval(deposit, index, job) {
        return this._depositProvider
            .getJobStatus(deposit.id)
            .then((updatedDeposit) => {
                const checkJob = updatedDeposit.FMEJobs.find((FMEJob) => FMEJob.job === job);
                if (!checkJob) {
                    throw new Error(`Erreur de création du job FME`);
                }

                const newDeposit = { ...deposit, ...updatedDeposit };

                if (checkJob.id) {
                    // Job was sent to FME, continue to next step
                    this.onJobReady(newDeposit, index, job);
                } else if (checkJob.status === 'FME_FAILURE' || checkJob.status === 'JOB_FAILURE') {
                    throw new Error('Échec de création du job FME');
                } else {
                    // Check in 10s
                    this._$timeout(() => this.checkJobReadyInterval(newDeposit, index, job), 5000);
                }
            })
            .catch((e) => {
                this.addStatus(index, job, e.message, 'error');
            });
    }

    /**
     * On job ready, Validate the job and run the ...run
     * @param deposit
     * @param index
     * @param job
     * @return {*}
     */
    onJobReady(deposit, index, job) {
        const { url, id } = this.getZipUrl(deposit, index, job);

        // Check if the zip is available
        return this._$http({ method: 'HEAD', url })
            .then(
                (res) => {
                    if (res.status !== 200) {
                        throw new Error("Le fichier zippé n'est pas accessible");
                    }

                    return id;
                },
                () => {
                    throw new Error("Le fichier zippé n'est pas accessible");
                },
            )
            .then(() => {
                this.addStatus(index, job, `Livrable envoyé`, 'success');

                const FMEJob = deposit.FMEJobs.find((FMEJob) => FMEJob.job === job);

                let message = 'Traitement du livrable lancé';
                if (this.isOrange || this.user.settings.feature.hasDebugMode) {
                    message = `Tâche #${FMEJob.id} lancée`;
                }
                this.addStatus(index, job, message, 'success');

                this._$timeout(() => this.checkDepositStatus(deposit, index, job, FMEJob.id), 10000);
            })
            .catch((err) => {
                this._$interval.cancel();
                this.onProcessDepositError(index, job, err);
            });
    }

    /**
     * @param {number} index
     * @param {string} job
     * @param {Error} error
     */
    onProcessDepositError(index, job, error) {
        let typeStatus = 'error';
        let message = this._translate('deposit.taskLaunchFailed');

        if (error.status === -1 && error.data === null) {
            message = `${this._translate('deposit.analysisOfDeliverableInProgress')}. ${this._translate('deposit.operationMayTakeSomeTime')}. ${this._translate('deposit.pleaseWait')}...`;
            typeStatus = 'success';
        }

        if (error.data && angular.isString(error.data.message)) {
            message = error.data.message;
            this._$toasterService.error(error);
        }

        this.addStatus(index, job, message, typeStatus);
        this._$log.warn(`Erreur sur le livrable #${index} en job ${job} : `, error);
    }

    getZipUrl(deposit, index, job) {
        const { id, FMEJobs, zipFile } = deposit;

        if (!id) {
            const message = `Le livrable n'a pas reçu d'identifiant`;
            this.addStatus(index, job, message, 'error');
            throw new Error(message);
        }

        if (!FMEJobs?.length || FMEJobs[0].job !== job) {
            let message = `Le job `;
            if (FMEJobs[0]) {
                message += `#${FMEJobs[0].id} `;
            }

            message += `du livrable est incorrect`;
            this.addStatus(index, job, message, 'error');
            throw new Error(message);
        }

        if (!zipFile?.locationDist) {
            const message = `Le livrable n'a pas été correctement mis en ligne`;
            this.addStatus(index, job, message, 'error');
            throw new Error(message);
        }

        return { url: zipFile.locationDist, id };
    }

    prepareDeposit(deposit, index, job) {
        const { file } = deposit;

        const jobs = [];
        if (job === 'load_control') {
            jobs.push(FMEJobEnum.LOAD, FMEJobEnum.CONTROL);
        } else {
            jobs.push(job);
        }

        const promises = jobs.map((jobName) => {
            const data = {
                controlConfiguration: deposit.controlConfiguration.id,
                dataModel: deposit.dataModel.id,
                deliveryZone: deposit.deliveryZone.id,
                hasFttxIndicators: deposit.hasFttxIndicators ?? false,
                isANCT: deposit.isANCT ?? false,
                jobs: [jobName],
                networkSegments: deposit.networkSegments,
                project: deposit.project.id,
                phase: deposit.phase,
            };

            this.totalCounter += 1;

            return this.processDeposit(file, data, index, jobName);
        });

        return Promise.all(promises);
    }

    isDataDepositValid(deposit) {
        return Boolean(deposit.phase && deposit.deliveryZone && deposit.controlConfiguration && deposit.dataModel);
    }

    areDepositsIsValid() {
        return this.bulk.deposits.length > 0 && this.bulk.deposits.every((deposit) => this.isDataDepositValid(deposit));
    }
}

angular.module('dotic').component('bulkPage', {
    controller: BulkPage,
    templateUrl: bulkTemplate,
});
