import { HelpersService } from '../../../services/helpers.service';
import template from './generate-fttx-evolution-inventory-modal.html';

class GenerateFttxEvolutionInventoryModalComponent {
    companyId = null;
    deliveryZones = [];
    deposit = null;
    filterValues = {
        deliveryZones: [],
        networkSegments: [],
        phases: [],
        projects: [],
        subCompanies: [],
        users: [],
    };
    loading = true;
    phases = [];
    selectedValues = {
        deliveryZones: [],
        from: null,
        hasFttxIndicators: true,
        networkSegments: [],
        phases: [],
        project: null,
        subCompanies: [],
        to: null,
        users: [],
    };

    constructor(
        $auth,
        $filter,
        $phaseService,
        $segmentsService,
        $scope,
        $timeout,
        $toasterService,
        companyProvider,
        deliveryZoneProvider,
        projectProvider,
    ) {
        this._companyProvider = companyProvider;
        this._deliveryZoneProvider = deliveryZoneProvider;
        this._projectProvider = projectProvider;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._translate = $filter('translate');

        this.companyId = $auth.getPayload().company;

        this.filterValues.networkSegments = $segmentsService.getAll().map((value) => ({ key: value, value: value }));
        this.filterValues.phases = $phaseService.getAll().map((value) => ({ key: value, value: value }));

        $scope.$watch('$ctrl.selectedValues.project', async () => {
            await this.getSubFilterValues();
        });
        // Needed to trigger call to $onChanges in deposits-preview component
        $scope.$watchGroup(
            [
                '$ctrl.selectedValues.deliveryZones',
                '$ctrl.selectedValues.from',
                '$ctrl.selectedValues.networkSegments',
                '$ctrl.selectedValues.phases',
                '$ctrl.selectedValues.project',
                '$ctrl.selectedValues.subCompanies',
                '$ctrl.selectedValues.to',
                '$ctrl.selectedValues.users',
            ],
            () => {
                this.selectedValues = { ...this.selectedValues };
            },
        );
    }

    async $onInit() {
        this.loading = true;
        try {
            const [projects, users, subCompanies] = await Promise.all([
                this._projectProvider.getAll(),
                this._companyProvider.getEmployeeDetailsForCompany(this.companyId),
                this._companyProvider.getCompanySubCompanies(this.companyId),
            ]);

            this.filterValues.projects = HelpersService.toKeyValue(projects);
            this.filterValues.users = HelpersService.toKeyValue(users, {
                keyField: 'id',
                valueField: 'fullname',
            });
            this.filterValues.subCompanies = HelpersService.toKeyValue(subCompanies);
        } catch {
            this._$toasterService.error(this._translate('toaster.error'));
        } finally {
            this._$timeout(() => {
                this.loading = false;
            });
        }
    }

    cancel() {
        return this.close();
    }

    async getSubFilterValues() {
        try {
            this.deliveryZones = await this._deliveryZoneProvider.list({ project: this.selectedValues.project });
            this.filterValues.deliveryZones = HelpersService.toKeyValue(this.deliveryZones);
        } catch {
            this._$toasterService.error(this._translate('toaster.error'));
        }
    }

    isFormValid() {
        return this.deposit && this.selectedValues.project;
    }

    onDepositSelected(deposits) {
        this.deposit = deposits[0];
    }

    submit() {
        if (!this.isFormValid()) {
            return this._$toasterService.error(this._translate('toaster.invalidForm'));
        }

        return this.close({
            $value: {
                deposit: this.deposit,
                project: this.selectedValues.project,
            },
        });
    }
}

angular.module('dotic').component('generateFttxEvolutionInventoryModal', {
    templateUrl: template,
    controller: GenerateFttxEvolutionInventoryModalComponent,
    bindings: {
        close: '&',
    },
});
