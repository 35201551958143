import { saveAs } from 'file-saver';

class RestProvider {
    constructor($auth, $filter, $http, $toasterService, $window) {
        this.$auth = $auth;
        this._translate = $filter('translate');
        this.$http = $http;
        this.$toasterService = $toasterService;
        this.$window = $window;
        this.baseApiUrl = APP_SETTINGS.baseApiUrl;
    }

    static formatPath(path) {
        return path instanceof Array ? path.join('/') : path;
    }

    static formatQuery(query) {
        const keys = Object.keys(query);
        const queryArray = keys.reduce((acc, val) => [...acc, `${val}=${query[val]}`], []);

        return queryArray.length === 0 ? '' : `?${queryArray.join('&')}`;
    }

    download(path = '', query = {}) {
        const url = this.resolveURL(path, query);
        this.$window.open(url);
    }

    async downloadFromPath(path, query = {}, body = null) {
        const fetchedData = await fetch(this.resolveURL(path, query), {
            ...(body && {
                body: angular.toJson(body),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            }),
        });

        if (!fetchedData.ok) {
            // Cannot use .json and then .text if it fails. As fetch uses stream to read data, once .json is triggered, we can no longer use .text
            let error;
            const errorTextFormat = await fetchedData.text();
            try {
                // eslint-disable-next-line angular/json-functions
                error = JSON.parse(errorTextFormat);
            } catch {
                error = errorTextFormat;
            }

            this.$toasterService.error(error);

            return;
        }

        const fileName = fetchedData.headers
            .get('Content-Disposition')
            ?.split('filename=')[1]
            .split(';')[0]
            .slice(1, -1);
        const blob = await fetchedData.blob();

        // Use saveAs from FileService to download the file
        saveAs(blob, fileName ?? this._translate('shared.file', { COUNT: 1 }));
    }

    resolveURL(path = '', query = {}) {
        query.access_token = this.$auth.getToken();

        return `${this.baseApiUrl}/${RestProvider.formatPath(path)}${RestProvider.formatQuery(query)}`;
    }

    get(path = '', query = {}) {
        return this.$http
            .get(`${this.baseApiUrl}/${RestProvider.formatPath(path)}${RestProvider.formatQuery(query)}`)
            .then((response) => response.data);
    }

    post(path = '', data = {}, query = {}) {
        return this.$http
            .post(`${this.baseApiUrl}/${RestProvider.formatPath(path)}${RestProvider.formatQuery(query)}`, data)
            .then((response) => response.data);
    }

    put(path = '', data = {}, query = {}) {
        return this.$http
            .put(`${this.baseApiUrl}/${RestProvider.formatPath(path)}${RestProvider.formatQuery(query)}`, data)
            .then((response) => response.data);
    }

    remove(path = '', query = {}) {
        return this.$http
            .delete(`${this.baseApiUrl}/${RestProvider.formatPath(path)}${RestProvider.formatQuery(query)}`)
            .then((response) => response.data);
    }
}

angular.module('dotic').factory('RestProvider', RestProvider);
