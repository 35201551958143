import template from './technical-points-indicator.component.html';

class TechnicalPointsIndicatorComponent {
    constructor($chartService, $indicatorStatusService, $indicatorMaxValueService, $timeout) {
        this._$indicatorStatusService = $indicatorStatusService;
        this._$indicatorMaxValueService = $indicatorMaxValueService;
        this._$timeout = $timeout;

        this.data = null;
        this.natures = {};
        this.oldDeposit = false;
        this.depositUnknown = false;

        this.stackedBarChartOptions = {
            responsive: true,
            scales: $chartService.getDefaultScaleConfiguration(false),
        };
    }

    $onInit() {
        const advancement = this.data?.table?.[0]?.avancement;
        this.oldDeposit = !this.data?.table || !this.data.table[0] || angular.isUndefined(advancement);

        if (this.oldDeposit) {
            return;
        }

        if (advancement === 'Inconnu') {
            this.depositUnknown = true;
        }

        this._$timeout(() => {
            this.natureTypePhyChartData = this.buildPhysicalTypeChart(this.data);
            this.physicalMaxvalue = this._$indicatorMaxValueService.findMaxValue(this.natureTypePhyChartData);
            this.natures = this.sumByTypePhy(this.data);
        });
    }

    buildPhysicalTypeChart({ status, nature_support }) {
        if (!angular.isObject(nature_support)) {
            return null;
        }

        const natures = [];

        Object.entries(nature_support).forEach((nature) => {
            const [natureName, data] = nature;
            const labels = Object.keys(data);
            const datasets = status.map((state) => {
                const status = this._$indicatorStatusService.getData(state);
                const color = status?.color || '';
                const chartData = Object.values(data).map((element) => {
                    return element[state] || 0;
                });

                return {
                    backgroundColor: color,
                    label: status.title,
                    data: chartData.map((element) => {
                        return element?.toFixed(0) ?? null;
                    }),
                    borderColor: '#fff',
                    borderWidth: 1,
                    categoryPercentage: 0.75,
                    barPercentage: 0.75,
                    maxBarThickness: 15,
                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        color: color,
                        padding: '10',
                        clamp: true,
                        font: {
                            weight: 'bold',
                        },
                    },
                };
            });

            natures.push({ name: natureName, chartData: { datasets, labels } });
        });

        return natures;
    }

    sumByTypePhy({ table }) {
        const natures = {};

        table
            .sort((a, b) => a.avancement.localeCompare(b.avancement))
            .forEach((entry) => {
                const { avancement, count } = entry;
                if (!natures[avancement]) {
                    const status = this._$indicatorStatusService.getData(avancement);
                    const title = status?.title || '';
                    const color = status?.color || '';
                    const icon = status?.icon || '';

                    natures[avancement] = {
                        count: 0,
                        title,
                        color,
                        icon,
                    };
                }

                natures[avancement].count += count;
            });

        return natures;
    }
}

angular.module('dotic').component('technicalPointsIndicator', {
    controller: TechnicalPointsIndicatorComponent,
    templateUrl: template,
    bindings: {
        data: '<',
        parentRef: '<',
    },
});
