class NewPlugService {
    constructor($filter) {
        this._translate = $filter('translate');
    }

    initIndicNewPlug(indic) {
        indic.newPlug = {};
        indic.newPlug.sum_nbl = 0;
        indic.newPlug.sum_nbl_hab = 0;
        indic.newPlug.sum_nbl_pro = 0;
        indic.newPlug.sum_nbpr = 0;
        indic.newPlug.sum_nbpr_hab = 0;
        indic.newPlug.sum_nbpr_pro = 0;

        return {
            sum_nbl: indic.newPlug.sum_nbl,
            sum_nbl_hab: indic.newPlug.sum_nbl_hab,
            sum_nbl_pro: indic.newPlug.sum_nbl_pro,
            sum_nbpr: indic.newPlug.sum_nbpr,
            sum_nbpr_hab: indic.newPlug.sum_nbpr_hab,
            sum_nbpr_pro: indic.newPlug.sum_nbpr_pro,
        };
    }

    initHousingTree(dataOrigin, dataToCompare, isCompared) {
        return {
            parent: {
                icon: 'ui-home',
                color: '#5069a9',
                ...(isCompared
                    ? {
                          text: this._translate('shared.house', { COUNT: dataOrigin.sum_nbl + dataToCompare.sum_nbl }),
                          value1: dataOrigin.sum_nbl ?? 0,
                          value2: dataToCompare.sum_nbl ?? 0,
                      }
                    : {
                          text: this._translate('shared.house', { COUNT: dataOrigin.sum_nbl }),
                          value: dataOrigin.sum_nbl ?? 0,
                      }),
            },
            children: [
                {
                    icon: 'home',
                    color: '#e971b1',
                    ...(isCompared
                        ? {
                              text: this._translate('shared.privateIndividual', {
                                  COUNT: (dataOrigin.sum_nbl_hab ?? 0) + (dataToCompare.sum_nbl_hab ?? 0),
                              }),
                              value1: dataOrigin.sum_nbl_hab ?? 0,
                              value2: dataToCompare.sum_nbl_hab ?? 0,
                          }
                        : {
                              text: this._translate('shared.privateIndividual', { COUNT: dataOrigin.sum_nbl_hab }),
                              value: dataOrigin.sum_nbl_hab ?? 0,
                          }),
                },
                {
                    icon: 'industries-4',
                    color: '#0b99e0',
                    ...(isCompared
                        ? {
                              text: this._translate('shared.professional', {
                                  COUNT: (dataOrigin.sum_nbl_pro ?? 0) + (dataToCompare.sum_nbl_pro ?? 0),
                              }),
                              value1: dataOrigin.sum_nbl_pro ?? 0,
                              value2: dataToCompare.sum_nbl_pro ?? 0,
                          }
                        : {
                              icon: 'industries-4',
                              color: '#0b99e0',
                              text: this._translate('shared.professional', { COUNT: dataOrigin.sum_nbl_pro }),
                              value: dataOrigin.sum_nbl_pro ?? 0,
                          }),
                },
            ],
        };
    }

    initPlugTree(dataOrigin, dataToCompare, isCompared) {
        return {
            parent: {
                icon: 'plugin',
                color: '#5069a9',
                ...(isCompared
                    ? {
                          text: this._translate('shared.potentialCatch', {
                              COUNT: dataOrigin.sum_nbpr + dataToCompare.sum_nbpr,
                          }),
                          value1: dataOrigin.sum_nbpr ?? 0,
                          value2: dataToCompare.sum_nbpr ?? 0,
                      }
                    : {
                          text: this._translate('shared.plug', { COUNT: dataOrigin.sum_nbpr }),
                          value: dataOrigin.sum_nbpr ?? 0,
                      }),
            },
            children: [
                {
                    icon: 'home',
                    color: '#e971b1',
                    ...(isCompared
                        ? {
                              text: this._translate('shared.privateIndividual', {
                                  COUNT: dataOrigin.sum_nbpr_hab + dataToCompare.sum_nbpr_hab,
                              }),
                              value1: dataOrigin.sum_nbpr_hab ?? 0,
                              value2: dataToCompare.sum_nbpr_hab ?? 0,
                          }
                        : {
                              text: this._translate('shared.privateIndividual', { COUNT: dataOrigin.sum_nbpr_hab }),
                              value: dataOrigin.sum_nbpr_hab ?? 0,
                          }),
                },
                {
                    icon: 'industries-4',
                    color: '#0b99e0',
                    ...(isCompared
                        ? {
                              text: this._translate('shared.professional', {
                                  COUNT: dataOrigin.sum_nbpr_pro + dataToCompare.sum_nbpr_pro,
                              }),
                              value1: dataOrigin.sum_nbpr_pro ?? 0,
                              value2: dataToCompare.sum_nbpr_pro ?? 0,
                          }
                        : {
                              icon: 'industries-4',
                              color: '#0b99e0',
                              text: this._translate('shared.professional', { COUNT: dataOrigin.sum_nbpr_pro }),
                              value: dataOrigin.sum_nbpr_pro ?? 0,
                          }),
                },
            ],
        };
    }
}

angular.module('dotic').factory('$indicatorNewPlugService', ($filter) => new NewPlugService($filter));
