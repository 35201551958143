import { group as radashGroup, sum as radashSum, unique as radashUnique } from 'radash';

import { ChartService } from '../../chart.service';
import { ColorService } from '../../color.service';

const KeyEnum = {
    LOGICAL_TYPE: 'logicalType',
    NB_POSITIONS: 'nbPositions',
    POSITION_FUNCTION: 'positionFunction',
};

// Linked to pbo-data indicator.
class IndicatorR00144Service {
    static ANCTCorrespondenceNumber = 22;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    generateDataStructure(data) {
        return {
            ANCTCorrespondenceNumber: IndicatorR00144Service.ANCTCorrespondenceNumber,
            data: data,
            doughnutChartData: this.getDoughnutChartData(data),
            tableChartData: this.getTableChartData(data),
            tableData: {
                columns: this.getColumns(),
            },
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.LOGICAL_TYPE,
                translation: this._translate('shared.logicalType'),
            },
            {
                key: KeyEnum.POSITION_FUNCTION,
                translation: this._translate('shared.positionFunction'),
            },
            {
                key: KeyEnum.NB_POSITIONS,
                translation: this._translate('shared.nbPositions'),
            },
        ];
    }

    getDoughnutChartData(data) {
        const positionCount = radashSum(data, (currentData) => currentData[KeyEnum.NB_POSITIONS] || 0);
        const dataGroupedByFunction = radashGroup(data, (currentData) => currentData[KeyEnum.POSITION_FUNCTION]);

        return {
            bigLegend: {
                figure: positionCount,
                name: this._translate('shared.location', { COUNT: positionCount }),
            },
            datasets: [
                {
                    backgroundColor: Object.keys(dataGroupedByFunction).map((key) =>
                        ColorService.getPositionFunctionTypeColor(key),
                    ),
                    data: Object.values(dataGroupedByFunction).map((currentData) =>
                        radashSum(currentData, (value) => value[KeyEnum.NB_POSITIONS] || 0),
                    ),
                },
            ],
            labels: Object.keys(dataGroupedByFunction),
        };
    }

    getTableChartData(data) {
        const labels = radashUnique(data.map((currentData) => currentData[KeyEnum.POSITION_FUNCTION]));

        const dataGroupedByLogicalType = radashGroup(data, (currentData) => currentData[KeyEnum.LOGICAL_TYPE]);

        return {
            datasets: Object.entries(dataGroupedByLogicalType).map(([key, values]) => {
                const tableChartData = labels.map((label) => {
                    const findValue = values.find((value) => value[KeyEnum.POSITION_FUNCTION] === label);

                    return findValue?.[KeyEnum.NB_POSITIONS] || 0;
                });

                return ChartService.getTableChartDataset(
                    tableChartData,
                    key,
                    ColorService.getCableLogicalTypeColor(key),
                );
            }),
            labels: labels,
        };
    }
}

angular.module('dotic').factory('indicatorR00144Service', IndicatorR00144Service);
