import template from './simple-number-indicator.component.html';

angular.module('dotic').component('simpleNumberIndicator', {
    templateUrl: template,
    bindings: {
        color: '@',
        text: '@',
        value: '<',
    },
});
