// Imports
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../assets/images/help/projets/projet-subContractor1.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../assets/images/help/projets/projet-subContractor2.jpg");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../../assets/images/help/projets/projet-subContractor3.jpg");
var ___HTML_LOADER_IMPORT_3___ = require("../../../../../assets/images/help/projets/projet-subContractor4.jpg");
var ___HTML_LOADER_IMPORT_4___ = require("../../../../../assets/images/help/projets/projet-subContractor5.jpg");
// Module
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 2.3 / {{ 'helpConfig.attachSubcontractor' | translate }} </h3> <div class=\"help-Doc__ul\"> <img class=\"help-Doc__img help-Doc__W80\" src=\"" + ___HTML_LOADER_IMPORT_0___ + "\" alt=\"{{ 'helpConfig.attachSubcontractor' | translate }} 1\"/> <img class=\"help-Doc__img help-Doc__W80\" src=\"" + ___HTML_LOADER_IMPORT_1___ + "\" alt=\"{{ 'helpConfig.attachSubcontractor' | translate }} 2\"/> <img class=\"help-Doc__img help-Doc__W80\" src=\"" + ___HTML_LOADER_IMPORT_2___ + "\" alt=\"{{ 'helpConfig.attachSubcontractor' | translate }} 3\"/> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_3___ + "\" alt=\"{{ 'helpConfig.attachSubcontractor' | translate }} 4\"/> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_4___ + "\" alt=\"{{ 'helpConfig.attachSubcontractor' | translate }} 5\"/> </div> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/gestionProjet/subContractorProject.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;