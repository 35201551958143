import { unique as radashUnique } from 'radash';

import { GeneralIconColorEnum } from '../../color.service';
import { GraceKeyEnum } from '../../../models/grace.model';
import { IndicatorService } from '../indicator.service';

const KeyEnum = {
    CABLE_CAPACITY: 'cableCapacity',
    CABLE_LENGTH: 'cableLength',
    DESIGN: 'design',
    ORGANIZATION: 'organization',
};

// Linked to cable-length-by-organization-and-design indicator.
class IndicatorR00138Service {
    static ANCTCorrespondenceNumber = 29;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    generateDataStructure(data) {
        const formattedData = IndicatorService.getFormattedKilometerData(data, [KeyEnum.CABLE_LENGTH]);

        return {
            ANCTCorrespondenceNumber: IndicatorR00138Service.ANCTCorrespondenceNumber,
            data: formattedData,
            iconOptions: this.getIconOptions(formattedData),
            tableData: {
                columns: this.getColumns(),
                title: this._translate('reportCompare.detailLengthCable'),
            },
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.ORGANIZATION,
                translation: this._translate('shared.organization', { COUNT: 1 }),
            },
            {
                key: KeyEnum.DESIGN,
                translation: GraceKeyEnum.DESIGN,
            },
            {
                key: KeyEnum.CABLE_CAPACITY,
                translation: this._translate('shared.cableCapacity'),
            },
            {
                key: KeyEnum.CABLE_LENGTH,
                translation: `${this._translate('shared.length', { COUNT: 1 })} (km)`,
            },
        ];
    }

    getIconOptions(data) {
        const designCount = radashUnique(data.map((currentData) => currentData[KeyEnum.DESIGN])).length;
        const organizationCount = radashUnique(data.map((currentData) => currentData[KeyEnum.ORGANIZATION])).length;

        return [
            {
                color: GeneralIconColorEnum.ORGANIZATION,
                icon: 'building-alt',
                label: `${organizationCount} ${this._translate('shared.organization', { COUNT: organizationCount })}`,
            },
            {
                color: GeneralIconColorEnum.DESIGN,
                icon: 'designbump',
                label: `${designCount} ${this._translate('shared.cableReference', { COUNT: designCount })}`,
            },
        ];
    }
}

angular.module('dotic').factory('indicatorR00138Service', IndicatorR00138Service);
