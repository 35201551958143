angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.bulk', {
        url: '/bulk?{exportGeoPackageId:[A-Za-z0-9]+}&isANCT',
        component: 'bulkPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin', 'user', 'extendedUser'],
        },
        params: {
            exportGeoPackageId: null,
            isANCT: null,
        },
    });
});
