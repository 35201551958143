import FttxDataEvolutionThematicIndicatorsComponent from '../fttx-data-evolution-view-indicators-thematic.component';
import template from './fttx-data-evolution-addresses.component.html';

class FttxDataEvolutionAdressesIndicatorsComponent extends FttxDataEvolutionThematicIndicatorsComponent {
    constructor($favoriteIndicatorsService, indicatorChartService) {
        super($favoriteIndicatorsService, indicatorChartService);
    }
}

angular.module('dotic').component('fttxDataEvolutionAdressesIndicatorsComponent', {
    controller: FttxDataEvolutionAdressesIndicatorsComponent,
    templateUrl: template,
    bindings: FttxDataEvolutionThematicIndicatorsComponent.defaultBindings,
});
