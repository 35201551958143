import { ColorService, GeneralIconColorEnum } from '../../../../services/color.service';
import { SiteLogicalTypeEnum } from '../../../../models/grace.model';
import template from './physical-types-indicator.component.html';

class PhysicalTypesIndicatorComponent {
    loaded = false;
    nro = [];
    nroColor = ColorService.getSiteLogicalTypeColor(SiteLogicalTypeEnum.NRO);
    NROTree = null;
    oldDepositNRO = false;
    oldDepositSRO = false;
    requestNRO =
        "SELECT count(*), st_typelog as st_typelog_nro, st_typephy FROM t_sitetech WHERE st_typelog ='NRO' GROUP BY st_typelog, st_typephy ";
    requestSRO =
        "SELECT count(*), st_typelog, st_typephy FROM t_sitetech WHERE st_typelog ='SRO' GROUP BY st_typelog,st_typephy";
    sro = [];
    sroColor = ColorService.getSiteLogicalTypeColor(SiteLogicalTypeEnum.SRO);
    SROTree = null;
    sumNRO = 0;
    sumSRO = 0;

    $onInit() {
        if (!this.sro || !this.sro[0] || angular.isUndefined(this.sro[0].type)) {
            this.oldDepositSRO = true;
        } else {
            this.oldDepositSRO = false;

            this.sumSRO = this.sro.reduce((acc, cur) => acc + cur.count, 0);

            this.SROTree = {
                parent: {
                    textIcon: 'SRO',
                    color: this.sroColor,
                    value: this.sro.reduce((acc, cur) => acc + cur.count, 0),
                },
                children: this.sro.map((item) => {
                    return {
                        icon: 'building',
                        color: GeneralIconColorEnum.BUILDING,
                        text: item.type.toLocaleLowerCase(),
                        value: item.count,
                    };
                }),
            };
        }

        if (!this.nro || !this.nro[0] || angular.isUndefined(this.nro[0].type)) {
            this.oldDepositNRO = true;
        } else {
            this.oldDepositNRO = false;

            this.sumNRO = this.nro.reduce((acc, cur) => acc + cur.count, 0);

            this.NROTree = {
                parent: {
                    textIcon: 'NRO',
                    color: this.nroColor,
                    value: this.nro.reduce((acc, cur) => acc + cur.count, 0),
                },
                children: this.nro.map((item) => {
                    return {
                        icon: 'building',
                        color: GeneralIconColorEnum.BUILDING,
                        text: item.type.toLocaleLowerCase(),
                        value: item.count,
                    };
                }),
            };
        }

        this.loaded = true;
    }
}

angular.module('dotic').component('physicalTypesIndicator', {
    controller: PhysicalTypesIndicatorComponent,
    templateUrl: template,
    bindings: {
        sro: '<',
        nro: '<',
        parentRef: '<',
    },
});
