// Imports
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../../assets/images/help/statistic-filling/fillingStat_metaData.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../../assets/images/help/statistic-filling/fillingStat_depositView.jpg");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../../../assets/images/help/statistic-filling/fillingStat_rate.jpg");
var ___HTML_LOADER_IMPORT_3___ = require("../../../../../../assets/images/help/statistic-filling/fillingStat-fillingRate-Detail1.jpg");
var ___HTML_LOADER_IMPORT_4___ = require("../../../../../../assets/images/help/statistic-filling/fillingStat-fillingRate-Detail2.jpg");
// Module
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 6.2.3 / {{ 'shared.seeDetail' | translate }} : </h3> <ul class=\"help-Doc__ul\"> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_0___ + "\" alt=\"{{ 'shared.informations' | translate }}\"/> <li class=\"help-Doc__subTitre4\"> {{ 'shared.depositList' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_1___ + "\" alt=\"{{ 'shared.depositList' | translate }}\"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'shared.fillingRate' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_2___ + "\" alt=\"{{ 'shared.fillingRate' | translate }}\"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'helpStats.fillingRateDetail' | translate }} : <img class=\"help-Doc__marginTop help-Doc__img-double-up help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_3___ + "\" alt=\"{{ 'helpStats.fillingRateDetail' | translate }}\"/> <img class=\"help-Doc__marginBottom help-Doc__img-double-down help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_4___ + "\" alt=\"{{ 'helpStats.fillingRateDetail' | translate }}\"/> </li> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/gestionStatistic/fillingStatistic/fillingSee.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;