import template from './cable-length-by-organization-and-design-indicator.component.html';

class CableLengthByOrganizationAndDesignIndicatorComponent {
    data = [];
    dataStructure = {
        data: [],
        iconOptions: [],
        tableData: {
            columns: [],
            title: '',
        },
    };
    parentRef = {};

    constructor(indicatorR00138Service) {
        this._indicatorR00138Service = indicatorR00138Service;
    }

    $onInit() {
        this.dataStructure = this._indicatorR00138Service.generateDataStructure(this.data);
    }
}

angular.module('dotic').component('cableLengthByOrganizationAndDesignIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: CableLengthByOrganizationAndDesignIndicatorComponent,
    templateUrl: template,
});
