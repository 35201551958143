import { ColorService } from '../../color.service';

const KeyEnum = {
    LOGICAL_TYPE: 'logicalType',
    SITE_COUNT: 'siteCount',
};

// Linked to site-number-by-logical-type indicator.
class IndicatorR00148Service {
    static ANCTCorrespondenceNumber = 13;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    generateDataStructure(data) {
        const iconOptions = data.map((currentData) => {
            return {
                color: ColorService.getLogicalTypeColor(currentData[KeyEnum.LOGICAL_TYPE]),
                icon: 'home',
                label: currentData[KeyEnum.LOGICAL_TYPE],
                value: currentData[KeyEnum.SITE_COUNT],
            };
        });

        return {
            ANCTCorrespondenceNumber: IndicatorR00148Service.ANCTCorrespondenceNumber,
            data: data,
            iconOptions: iconOptions,
            tableData: {
                columns: this.getColumns(),
                title: this._translate('shared.detailSiteCount'),
            },
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.LOGICAL_TYPE,
                translation: this._translate('shared.logicalType'),
            },
            {
                key: KeyEnum.SITE_COUNT,
                translation: this._translate('shared.siteCount'),
            },
        ];
    }
}

angular.module('dotic').factory('indicatorR00148Service', IndicatorR00148Service);
