import template from './bubble-indicator.component.html';

class BubbleIndicatorComponent {
    constructor($element, $timeout) {
        this._$element = $element;
        this._$timeout = $timeout;
        this.childrenX = [];
        this.loaded = false;
    }

    $onInit() {
        this.loaded = false;

        this._$timeout(() => {
            const parentValue = this._$element.find(`.parent-value`)[0];
            parentValue.setAttribute('x', 340);
            parentValue.setAttribute('y', 0);
            this.loaded = true;
        });
    }
}

angular.module('dotic').component('bubbleIndicator', {
    controller: BubbleIndicatorComponent,
    templateUrl: template,
    bindings: {
        parent: '<',
        result2: '<',
        unit2: '@',
    },
});
