import { sum as radashSum } from 'radash';

import { ColorService } from '../../color.service';

const KeyEnum = {
    AD_RACC: 'adRacc',
    IMPLANTATION: 'implantation',
    HOUSING_COUNT: 'housingCount',
    PERCENT: 'percent',
};

// Linked to local-number-by-implantation indicator.
class IndicatorR00139Service {
    static ANCTCorrespondenceNumber = 14;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    generateDataStructure(data) {
        return {
            ANCTCorrespondenceNumber: IndicatorR00139Service.ANCTCorrespondenceNumber,
            data: data,
            doughnutChartData: this.getDoughnutChartData(data),
            tableData: {
                columns: this.getColumns(),
                title: this._translate('shared.detailLocalCount'),
            },
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.AD_RACC,
                translation: `${this._translate('shared.connectionTypeId')} (ad_racc)`,
            },
            {
                key: KeyEnum.IMPLANTATION,
                translation: this._translate('shared.implantationType'),
            },
            {
                key: KeyEnum.HOUSING_COUNT,
                translation: this._translate('shared.housingCount'),
            },
            {
                key: KeyEnum.PERCENT,
                translation: this._translate('shared.housingRatioByConnectionType'),
            },
        ];
    }

    getDoughnutChartData(data) {
        const housingCount = radashSum(data, (currentData) => currentData[KeyEnum.HOUSING_COUNT] || 0);
        const colors = data.map((currentData) =>
            ColorService.getImplantationTypeColor(currentData[KeyEnum.IMPLANTATION]),
        );

        return {
            bigLegend: {
                figure: housingCount,
                name: this._translate('shared.local', { COUNT: housingCount }),
            },
            datasets: [
                {
                    backgroundColor: colors,
                    data: data.map((currentData) => currentData[KeyEnum.HOUSING_COUNT]),
                },
            ],
            labels: data.map((currentData) => currentData[KeyEnum.IMPLANTATION]),
        };
    }
}

angular.module('dotic').factory('indicatorR00139Service', IndicatorR00139Service);
