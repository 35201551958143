import { CompaniesApi, IndicatorNameEnum } from '../../../../../sdk/connect-control-api-v1/src';
import FavoriteIndicatorsService from '../../../../services/favorite-indicators.service';
import fttxDataEvolutionViewTemplate from './fttx-data-evolution-view-indicators.html';
import { IndicatorDetailDifferencesTypeEnum } from '../../../../services/indicator-fttx-data-evolution.service';
import { IndicatorR00140Service } from '../../../../services/indicator/anct/indicator-r00140.service';
import { IndicatorService } from '../../../../services/indicator/indicator.service';
import { IndicatorsTypeEnum } from '../../../../models/indicator.model';

const indicatorNamesEnum = new IndicatorNameEnum();
const defaultOrderedIndicatorKeys = {
    [IndicatorsTypeEnum.ADDRESSES]: [
        indicatorNamesEnum.NB_CONNECTIONS_BY_IMPLANTATION,
        indicatorNamesEnum.PLUGS_STATUS,
        indicatorNamesEnum.CONNECTIVITY,
        indicatorNamesEnum.NEW_PLUG,
        indicatorNamesEnum.LOCAL_NUMBER_BY_IMPLANTATION,
        indicatorNamesEnum.FIELD_OCCURRENCE,
        indicatorNamesEnum.CODE_HEXACLE,
        indicatorNamesEnum.LOCAL_NUMBER_BY_DISTANCE_RANGE,
        indicatorNamesEnum.LOCAL_NUMBER_BY_SITE_LOGICAL_TYPE,
        indicatorNamesEnum.LOCAL_NUMBER_BY_LOGICAL_TYPE,
    ],
    [IndicatorsTypeEnum.BOXES]: [indicatorNamesEnum.NB_PA, indicatorNamesEnum.NB_BPE, indicatorNamesEnum.PTO_STATUS],
    [IndicatorsTypeEnum.FIBERS_CABLES]: [
        indicatorNamesEnum.RA_FTTE,
        indicatorNamesEnum.CABLE_LENGTH_BY_CAPACITY_AND_LOGICAL_TYPE,
        indicatorNamesEnum.CABLE_LENGTH_BY_TYPE_AND_PROVIDER,
        indicatorNamesEnum.LINEAR_METER_BY_LOGICAl_TYPE,
        indicatorNamesEnum.OPTICAL_CABLE_SUPPORT,
        indicatorNamesEnum.CABLE_LENGTH_BY_TYPE,
        indicatorNamesEnum.CABLE_LENGTH_BY_ORGANIZATION_AND_DESIGN,
        indicatorNamesEnum.CABLE_LENGTH_BY_IMPLANTATION,
        indicatorNamesEnum.CABLE_LENGTH_BY_OWNER_AND_MANAGER,
        indicatorNamesEnum.CABLE_LENGTH_BY_SLICE_TYPE,
        indicatorNamesEnum.PBO_DATA,
    ],
    [IndicatorsTypeEnum.DEPLOYMENT_PROGRESS]: [
        indicatorNamesEnum.DEPLOYED_ADDRESSES,
        indicatorNamesEnum.BEING_DEPLOYED_ADDRESSES,
        indicatorNamesEnum.ADVANCEMENT_LEVEL_BY_STATE_AND_CAPACITY_MAX,
        indicatorNamesEnum.ADVANCEMENT_LEVEL_BY_PTO_AND_CAPACITY_MAX,
    ],
    [IndicatorsTypeEnum.NETWORK_SATURATION_LEVEL]: [
        indicatorNamesEnum.AVERAGE_CABLE_SATURATION_RATE,
        indicatorNamesEnum.AVERAGE_CABLE_THEORETICAL_RESERVE_RATIO,
        indicatorNamesEnum.AVERAGE_BOX_SATURATION_RATE,
        indicatorNamesEnum.AVERAGE_BOX_THEORETICAL_RESERVE_RATIO,
    ],
    [IndicatorsTypeEnum.NETWORK_SITES]: [
        indicatorNamesEnum.NRO_HOSTING_IN_ORANGE_NRA,
        indicatorNamesEnum.NRO_PHYSICAL_TYPE,
        indicatorNamesEnum.NRO_NRA_DISTANCE,
        indicatorNamesEnum.NRO_PBO_DISTANCE,
        indicatorNamesEnum.SITE_NUMBER_BY_LOGICAL_TYPE,
    ],
    [IndicatorsTypeEnum.PATHWAYS]: [
        indicatorNamesEnum.LINEAR_METER_BY_METHOD,
        indicatorNamesEnum.LINEAR_METER_BY_CLASS,
        indicatorNamesEnum.SLICE_LINEAR_METER_BY_TYPE,
        indicatorNamesEnum.PATHWAY,
    ],
    [IndicatorsTypeEnum.TECHNICAL_POINTS]: [
        indicatorNamesEnum.NB_BY_NATURE,
        indicatorNamesEnum.NB_OPTICAL_CHAMBER,
        indicatorNamesEnum.NATURE_PHYSICAL_TYPE,
    ],
};

class FttxDataEvolutionViewIndicatorsComponent {
    company = null;
    comparisonDeposit = null;
    comparisonIndicators = null;
    detailDifferencesArrays = {
        advancementLevelByPTOAndCapacityMax: { columns: [], values: [] },
        advancementLevelByStateAndCapacityMax: { columns: [], values: [] },
        averageBoxSaturationRate: { columns: [], values: [] },
        averageCableSaturationRate: { columns: [], values: [] },
        averageBoxTheoreticalReserveRatio: { columns: [], values: [] },
        averageCableTheoreticalReserveRatio: { columns: [], values: [] },
        beingDeployedAddresses: { columns: [], values: [] },
        cableLengthByCapacityAndLogicalType: { columns: [], values: [] },
        cableLengthByTypeAndProvider: { columns: [], values: [] },
        connectivity: { columns: [], values: [] },
        deployedAddresses: { columns: [], values: [] },
        distanceBetweenNroNra: { columns: [], values: [] },
        distanceNroPbo: { columns: [], values: [] },
        linearMeterByClass: { columns: [], values: [] },
        linearMeterByLogicalType: { columns: [], values: [] },
        linearMeterByMethod: { columns: [], values: [] },
        nbBoxes: { columns: [], values: [] },
        nbConnectionsByImplantationType: { columns: [], values: [] },
        nbGc: { columns: [], values: [] },
        nbHouses: { columns: [], values: [] },
        nbOpticalChamber: { columns: [], values: [] },
        nbPa: { columns: [], values: [] },
        nbPlugs: { columns: [], values: [] },
        nroHostingInOrangeNra: { columns: [], values: [] },
        nroSro: { columns: [], values: [] },
        opticalCableSupport: { columns: [], values: [] },
        pathway: { columns: [], values: [] },
        plugsStatus: { columns: [], values: [] },
        ptoStatus: { columns: [], values: [] },
        raFtte: { columns: [], values: [] },
        siteNumberByLogicalType: { columns: [], values: [] },
        sliceLinearMeterByType: { columns: [], values: [] },
        technicalPoints: { columns: [], values: [] },
    };
    IndicatorsTypeEnum = IndicatorsTypeEnum;
    indicatorTypeSelected = IndicatorsTypeEnum.NETWORK_SITES;
    inventoryId = null;
    isGraceV3 = false;
    loading = false;
    orderedIndicatorKeys = { ...defaultOrderedIndicatorKeys };
    referenceDeposit = null;
    referenceIndicators = null;

    constructor(
        $apiClientService,
        $auth,
        $dataModelService,
        $filter,
        indicatorR00140Service,
        $indicatorFttxDataEvolutionService,
        $timeout,
        $toasterService,
        dataModelProvider,
        RestProvider,
    ) {
        this._$dataModelService = $dataModelService;
        this._indicatorR00140Service = indicatorR00140Service;
        this._$indicatorFttxDataEvolutionService = $indicatorFttxDataEvolutionService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._companiesApi = new CompaniesApi($apiClientService.client);
        this._dataModelProvider = dataModelProvider;
        this._RestProvider = RestProvider;
        this._translate = $filter('translate');

        this.companyId = $auth.getPayload().company;
        this.IndicatorNameEnum = new IndicatorNameEnum();
    }

    async $onInit() {
        this.company = await this.getCompany();
        this.orderedIndicatorKeys = this.getOrderedIndicatorKeys();
    }

    async $onChanges(changes) {
        if (changes.comparisonIndicators) {
            this.comparisonIndicators = changes.comparisonIndicators.currentValue;
        }

        if (changes.referenceIndicators) {
            this.referenceIndicators = changes.referenceIndicators.currentValue;
        }

        if (this.referenceIndicators.fttxIndicators && this.comparisonIndicators.fttxIndicators && !this.loading) {
            this.detailDifferencesArrays = this.getDetailDifferencesArrays();
        }

        if (changes.referenceDeposit?.currentValue) {
            try {
                const dataModel = await this._dataModelProvider.get(changes.referenceDeposit.currentValue.dataModel);
                this.isGraceV3 = this._$dataModelService.isDataGraceTHDV3({ dataModel: dataModel });
            } catch (error) {
                this._$toasterService.error(error);
            }
        }
    }

    async downloadDifferencesDetails(indicatorName) {
        if (!this.comparisonDeposit) {
            return;
        }

        try {
            await this._RestProvider.downloadFromPath(
                `fttx-heritage-inventories/${this.inventoryId}/report/${this.comparisonDeposit.report._id}/indicator/${indicatorName}/download`,
            );
        } catch {
            this._$toasterService.error(this._translate('toaster.downloadError'));
        }
    }

    async getCompany() {
        this.loading = true;
        try {
            return (await this._companiesApi.getDetailsWithHttpInfo(this.companyId)).response.body;
        } catch (error) {
            this._$toasterService.error(error);

            return null;
        } finally {
            this._$timeout(() => (this.loading = false));
        }
    }

    getDetailDifferencesArrays() {
        return {
            advancementLevelByPTOAndCapacityMax:
                this._$indicatorFttxDataEvolutionService.getSimpleNumberDetailDifferences(
                    this.referenceIndicators.fttxIndicators.deploymentProgress.advancementLevelByPTOAndCapacityMax,
                    this.comparisonIndicators.fttxIndicators.deploymentProgress.advancementLevelByPTOAndCapacityMax,
                ),
            advancementLevelByStateAndCapacityMax: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.deploymentProgress.advancementLevelByStateAndCapacityMax,
                this.comparisonIndicators.fttxIndicators.deploymentProgress.advancementLevelByStateAndCapacityMax,
                'advancement',
                IndicatorDetailDifferencesTypeEnum.ADVANCEMENT_LEVEL_BY_STATE,
            ),
            averageBoxSaturationRate: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                IndicatorService.sortByCableCapacity(
                    this.referenceIndicators.fttxIndicators.networkSaturationLevel.averageBoxSaturationRate,
                ),
                IndicatorService.sortByCableCapacity(
                    this.comparisonIndicators.fttxIndicators.networkSaturationLevel.averageBoxSaturationRate,
                ),
                'averageSaturation',
                IndicatorDetailDifferencesTypeEnum.AVERAGE_SATURATION,
            ),
            averageBoxTheoreticalReserveRatio: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                IndicatorService.sortByCableCapacity(
                    this.referenceIndicators.fttxIndicators.networkSaturationLevel.averageBoxTheoreticalReserveRatio,
                ),
                IndicatorService.sortByCableCapacity(
                    this.comparisonIndicators.fttxIndicators.networkSaturationLevel.averageBoxTheoreticalReserveRatio,
                ),
                'averageReserve',
                IndicatorDetailDifferencesTypeEnum.AVERAGE_SATURATION,
            ),
            averageCableSaturationRate: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                IndicatorService.sortByCableCapacity(
                    this.referenceIndicators.fttxIndicators.networkSaturationLevel.averageCableSaturationRate,
                ),
                IndicatorService.sortByCableCapacity(
                    this.comparisonIndicators.fttxIndicators.networkSaturationLevel.averageCableSaturationRate,
                ),
                'averageSaturation',
                IndicatorDetailDifferencesTypeEnum.AVERAGE_SATURATION,
            ),
            averageCableTheoreticalReserveRatio: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                IndicatorService.sortByCableCapacity(
                    this.referenceIndicators.fttxIndicators.networkSaturationLevel.averageCableTheoreticalReserveRatio,
                ),
                IndicatorService.sortByCableCapacity(
                    this.comparisonIndicators.fttxIndicators.networkSaturationLevel.averageCableTheoreticalReserveRatio,
                ),
                'averageReserve',
                IndicatorDetailDifferencesTypeEnum.AVERAGE_SATURATION,
            ),
            beingDeployedAddresses: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.deploymentProgress.beingDeployedAddresses,
                this.comparisonIndicators.fttxIndicators.deploymentProgress.beingDeployedAddresses,
                'beingDeployedAddresses',
                IndicatorDetailDifferencesTypeEnum.ADDRESS_DEPLOYMENT,
                ['addressCount', 'globalPercentage'],
            ),
            cableLengthByImplantation: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.cableLengthByImplantation,
                this.comparisonIndicators.anct.cableLengthByImplantation,
                'cableLength',
                IndicatorDetailDifferencesTypeEnum.CABLE_LENGTH_BY_IMPLANTATION,
            ),
            cableLengthByOrganizationAndDesign: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.cableLengthByOrganizationAndDesign,
                this.comparisonIndicators.anct.cableLengthByOrganizationAndDesign,
                'cableLength',
                IndicatorDetailDifferencesTypeEnum.CABLE_LENGTH_BY_ORGANIZATION,
            ),
            cableLengthByOwnerAndManager: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.cableLengthByOwnerAndManager,
                this.comparisonIndicators.anct.cableLengthByOwnerAndManager,
                'cableLength',
                IndicatorDetailDifferencesTypeEnum.CABLE_LENGTH_BY_OWNER_MANAGER,
            ),
            cableLengthBySliceType: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.cableLengthBySliceType,
                this.comparisonIndicators.anct.cableLengthBySliceType,
                'cableLength',
                IndicatorDetailDifferencesTypeEnum.CABLE_LENGTH_BY_SLICE_TYPE,
            ),
            cableLengthByType: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.cableLengthByType,
                this.comparisonIndicators.anct.cableLengthByType,
                'cableLength',
                IndicatorDetailDifferencesTypeEnum.CABLE_LENGTH_BY_TYPE,
            ),
            cableLengthByTypeAndProvider: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.fttxCables.cableLengthByTypeAndProvider,
                this.comparisonIndicators.fttxIndicators.fttxCables.cableLengthByTypeAndProvider,
                'cableLength',
                IndicatorDetailDifferencesTypeEnum.CABLE_LENGTH_BY_PROVIDER,
            ),
            cableLengthByCapacityAndLogicalType: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.fttxCables.cableLengthByCapacityAndLogicalType,
                this.comparisonIndicators.fttxIndicators.fttxCables.cableLengthByCapacityAndLogicalType,
                'count',
                IndicatorDetailDifferencesTypeEnum.OPTICAL_CABLES,
            ),
            codeHexacle: this._$indicatorFttxDataEvolutionService.getDetailDifferencesSeveralCountKeys(
                this.referenceIndicators.anct.codeHexacle,
                this.comparisonIndicators.anct.codeHexacle,
                ['addressCount', 'validHexacleCount', 'validSup10charHexacleCount', 'nullHexacleCount'],
                IndicatorDetailDifferencesTypeEnum.CODE_HEXACLE,
            ),
            connectivity: this._$indicatorFttxDataEvolutionService.getObjectDetailDifferences(
                this.referenceIndicators.connectivity,
                this.comparisonIndicators.connectivity,
            ),
            deployedAddresses: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.deploymentProgress.deployedAddresses,
                this.comparisonIndicators.fttxIndicators.deploymentProgress.deployedAddresses,
                'deployedAddresses',
                IndicatorDetailDifferencesTypeEnum.ADDRESS_DEPLOYMENT,
                ['addressCount', 'globalPercentage'],
            ),
            distanceBetweenNroNra: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.distanceBetweenNroNra,
                this.comparisonIndicators.anct.distanceBetweenNroNra,
                'distanceKm',
                IndicatorDetailDifferencesTypeEnum.NRO_NRA_DISTANCE,
                ['distanceClass'],
            ),
            distanceNroPbo: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.distanceBetweenNroPbo,
                this.comparisonIndicators.distanceBetweenNroPbo,
                'distanceKm',
                IndicatorDetailDifferencesTypeEnum.DISTANCE_NRO_PBO,
            ),
            fieldOccurrence: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                IndicatorR00140Service.getFormattedFieldOccurrenceData(this.referenceIndicators.anct.fieldOccurrence),
                IndicatorR00140Service.getFormattedFieldOccurrenceData(this.comparisonIndicators.anct.fieldOccurrence),
                'occurrenceCount',
                IndicatorDetailDifferencesTypeEnum.FIELD_OCCURRENCE,
                [],
                (currentData) => this._indicatorR00140Service.getDifferenceReformattedData(currentData),
            ),
            linearMeterByClass: this._$indicatorFttxDataEvolutionService.getDetailDifferencesSeveralCountKeys(
                this.referenceIndicators.fttxIndicators.gc.linearMeterByClass,
                this.comparisonIndicators.fttxIndicators.gc.linearMeterByClass,
                ['nbGc', 'linearMeter'],
                IndicatorDetailDifferencesTypeEnum.LINEAR_METER_CLASS,
            ),
            linearMeterByLogicalType: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.linearMeterByLogicalType,
                this.comparisonIndicators.fttxIndicators.linearMeterByLogicalType,
                'linearMeter',
                IndicatorDetailDifferencesTypeEnum.LINEAR_METER_LOGICAL_TYPE,
            ),
            linearMeterByMethod: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.gc.linearMeterByMethod,
                this.comparisonIndicators.fttxIndicators.gc.linearMeterByMethod,
                'linearMeter',
                IndicatorDetailDifferencesTypeEnum.LINEAR_METER_DEFAULT,
            ),
            localNumberByImplantation: this._$indicatorFttxDataEvolutionService.getDetailDifferencesSeveralCountKeys(
                this.referenceIndicators.anct.localNumberByImplantation,
                this.comparisonIndicators.anct.localNumberByImplantation,
                ['housingCount', 'percent'],
                IndicatorDetailDifferencesTypeEnum.LOCAL_NUMBER_BY_IMPLANTATION,
            ),
            localNumberByDistanceRange: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.localNumberByDistanceRange,
                this.comparisonIndicators.anct.localNumberByDistanceRange,
                'localCount',
                IndicatorDetailDifferencesTypeEnum.LOCAL_NUMBER_BY_DISTANCE_RANGE,
            ),
            localNumberByLogicalType: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.localNumberByLogicalType,
                this.comparisonIndicators.anct.localNumberByLogicalType,
                'addressCount',
                IndicatorDetailDifferencesTypeEnum.SITE_NUMBER,
            ),
            localNumberBySiteLogicalType: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.localNumberBySiteLogicalType,
                this.comparisonIndicators.anct.localNumberBySiteLogicalType,
                'addressCount',
                IndicatorDetailDifferencesTypeEnum.SITE_NUMBER,
            ),
            nbBoxes: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.nbBoxIndicator.nbBPE,
                this.comparisonIndicators.nbBoxIndicator.nbBPE,
                'count',
                IndicatorDetailDifferencesTypeEnum.NB_BOXES,
            ),
            nbConnectionsByImplantationType: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.nbConnectionsByImplantationType,
                this.comparisonIndicators.fttxIndicators.nbConnectionsByImplantationType,
                'nbConnections',
                IndicatorDetailDifferencesTypeEnum.NB_CONNECTIONS_BY_IMPLANTATION_TYPE,
            ),
            nbGc: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.gc.nbGroupBy.nbByNature,
                this.comparisonIndicators.fttxIndicators.gc.nbGroupBy.nbByNature,
                'nbGc',
                IndicatorDetailDifferencesTypeEnum.NB_GC,
            ),
            nbHouses: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                [
                    this.referenceIndicators.housingIndicator.parent,
                    ...this.referenceIndicators.housingIndicator.children,
                ],
                [
                    this.comparisonIndicators.housingIndicator.parent,
                    ...this.comparisonIndicators.housingIndicator.children,
                ],
                'value',
                IndicatorDetailDifferencesTypeEnum.NB_PLUGS,
                ['icon', 'color'],
            ),
            nbOpticalChamber: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.nbOpticalChamber,
                this.comparisonIndicators.fttxIndicators.nbOpticalChamber,
                'nbChambres',
                IndicatorDetailDifferencesTypeEnum.NB_OPTICAL_CHAMBER,
            ),
            nbPa: this._$indicatorFttxDataEvolutionService.getSimpleNumberDetailDifferences(
                this.referenceIndicators.fttxIndicators.nbPa,
                this.comparisonIndicators.fttxIndicators.nbPa,
            ),
            nbPlugs: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                [this.referenceIndicators.plugIndicator.parent, ...this.referenceIndicators.plugIndicator.children],
                [this.comparisonIndicators.plugIndicator.parent, ...this.comparisonIndicators.plugIndicator.children],
                'value',
                IndicatorDetailDifferencesTypeEnum.NB_PLUGS,
                ['icon', 'color'],
            ),
            nroHostingInOrangeNra: this._$indicatorFttxDataEvolutionService.getSimpleNumberDetailDifferences(
                this.referenceIndicators.fttxIndicators.nroHostingInOrangeNra,
                this.comparisonIndicators.fttxIndicators.nroHostingInOrangeNra,
            ),
            nroSro: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                Object.values(this.referenceIndicators.nroSroIndicator).flat(),
                Object.values(this.comparisonIndicators.nroSroIndicator).flat(),
                'count',
                IndicatorDetailDifferencesTypeEnum.NRO_SRO,
            ),
            opticalCableSupport: this._$indicatorFttxDataEvolutionService.getDetailDifferencesSeveralCountKeys(
                this.referenceIndicators.opticalCableSupport.table,
                this.comparisonIndicators.opticalCableSupport.table,
                ['data_geom', 'data_saisie'],
                IndicatorDetailDifferencesTypeEnum.OPTICAL_CABLE_SUPPORT,
            ),
            pathway: this._$indicatorFttxDataEvolutionService.getDetailDifferencesSeveralCountKeys(
                this.referenceIndicators.pathway.table,
                this.comparisonIndicators.pathway.table,
                ['nbChems', 'countGeom'],
                IndicatorDetailDifferencesTypeEnum.TECHNICAL_POINTS,
                ['countReal', 'idManager', 'idOwner'],
            ),
            pboData: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.pboData,
                this.comparisonIndicators.anct.pboData,
                'nbPositions',
                IndicatorDetailDifferencesTypeEnum.PBO_DATA,
            ),
            plugsStatus: this._$indicatorFttxDataEvolutionService.getObjectDetailDifferences(
                this.referenceIndicators.fttxIndicators.plugsStatus,
                this.comparisonIndicators.fttxIndicators.plugsStatus,
            ),
            ptoStatus: this._$indicatorFttxDataEvolutionService.getObjectDetailDifferences(
                this.referenceIndicators.fttxIndicators.ptoStatus,
                this.comparisonIndicators.fttxIndicators.ptoStatus,
            ),
            raFtte: this._$indicatorFttxDataEvolutionService.getSimpleNumberDetailDifferences(
                this.referenceIndicators.fttxIndicators.fttxCables.raFtte,
                this.comparisonIndicators.fttxIndicators.fttxCables.raFtte,
            ),
            siteNumberByLogicalType: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.siteNumberByLogicalType,
                this.comparisonIndicators.anct.siteNumberByLogicalType,
                'siteCount',
                IndicatorDetailDifferencesTypeEnum.SITE_NUMBER,
            ),
            sliceLinearMeterByType: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.sliceLinearMeterByType,
                this.comparisonIndicators.fttxIndicators.sliceLinearMeterByType,
                'linearMeter',
                IndicatorDetailDifferencesTypeEnum.LINEAR_METER_DEFAULT,
            ),
            technicalPoints: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.technicalPoints.table,
                this.comparisonIndicators.technicalPoints.table,
                'count',
                IndicatorDetailDifferencesTypeEnum.TECHNICAL_POINTS,
                ['idManager', 'idOwner'],
            ),
        };
    }

    getOrderedIndicatorKeys() {
        return Object.keys(IndicatorsTypeEnum).reduce((acc, indicatorType) => {
            return {
                ...acc,
                [indicatorType]: defaultOrderedIndicatorKeys[indicatorType].toSorted((a, b) => {
                    return (
                        Number(FavoriteIndicatorsService.isIndicatorFavorite(this.company, b)) -
                        Number(FavoriteIndicatorsService.isIndicatorFavorite(this.company, a))
                    );
                }),
            };
        }, {});
    }

    scrollToIndicatorsBox() {
        const $element = angular.element('#indicatorsBox');
        this._$timeout(() => {
            angular.element('html').animate({ scrollTop: $element.offset().top }, 'slow');
        });
    }

    async updateFavoriteIndicators(indicatorName, isFavorite) {
        const newIndicatorNames = [...this.company.settings.configuration.favoriteIndicatorKeys];
        if (newIndicatorNames.includes(indicatorName) && !isFavorite) {
            newIndicatorNames.splice(newIndicatorNames.indexOf(indicatorName), 1);
        } else if (!newIndicatorNames.includes(indicatorName) && isFavorite) {
            newIndicatorNames.push(indicatorName);
        }

        try {
            await this._companiesApi.setFavoriteIndicators(this.companyId, {
                favoriteIndicatorNames: newIndicatorNames,
            });
        } catch (error) {
            this._$toasterService.error(error);

            return;
        }

        this.company = await this.getCompany();
        this.orderedIndicatorKeys = this.getOrderedIndicatorKeys();
        this.scrollToIndicatorsBox();
    }

    updateIndicatorTypeSelected(value) {
        if (!Object.values(IndicatorsTypeEnum).includes(value)) {
            throw new Error(`Unknown value : the received value (${value}) is not part of IndicatorTypeEnum`);
        }

        this.loading = true;
        this.indicatorTypeSelected = value;

        this._$timeout(() => (this.loading = false));
        this.scrollToIndicatorsBox();
    }
}

angular.module('dotic').component('fttxDataEvolutionViewIndicators', {
    controller: FttxDataEvolutionViewIndicatorsComponent,
    templateUrl: fttxDataEvolutionViewTemplate,
    bindings: {
        comparisonDeposit: '<',
        comparisonIndicators: '<',
        inventoryId: '<',
        loading: '<',
        referenceDeposit: '<',
        referenceIndicators: '<',
    },
});
