angular.module('dotic').config(($stateProvider) => {
    // List
    $stateProvider.state('app.gridControlList', {
        url: '/controlGrids/listTemplates?company&dataModel&search&p',
        params: {
            company: {
                value: '',
                squash: true,
                dynamic: true,
            },
            dataModel: {
                value: '',
                squash: true,
                dynamic: true,
            },
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
            isTemplate: {
                value: true,
                squash: true,
                dynamic: true,
            },
        },
        component: 'controlGridListPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
    $stateProvider.state('app.gridControlListCompanies', {
        url: '/controlGrids/list?company&dataModel&search&p',
        params: {
            company: {
                value: '',
                squash: true,
                dynamic: true,
            },
            dataModel: {
                value: '',
                squash: true,
                dynamic: true,
            },
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
            isTemplate: {
                value: false,
                squash: true,
                dynamic: true,
            },
        },
        component: 'controlGridListPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
    $stateProvider.state('app.gridControlAdminList', {
        url: '/controlGrids?search&p',
        params: {
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        component: 'controlGridAdminListPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });

    // Create
    $stateProvider.state('app.gridControlCreate', {
        url: '/controlGrids/create',
        component: 'controlGridFormPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });

    // Edit
    $stateProvider.state('app.gridControlEdit', {
        url: '/controlGrids/:gridControlId/edit/superAdmin?status&thematic&type&shortDescription&search&p',
        params: {
            status: {
                value: '',
                squash: true,
                dynamic: true,
            },
            thematic: {
                value: '',
                squash: true,
                dynamic: true,
            },
            type: {
                value: '',
                squash: true,
                dynamic: true,
            },
            shortDescription: {
                value: '',
                squash: true,
                dynamic: true,
            },
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
            segment: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        component: 'controlGridFormPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
    $stateProvider.state('app.gridControlAdminEdit', {
        url: '/controlGrids/:gridControlId/admin/edit?status&thematic&type&object&isActivated&phase&search&internalId&p',
        params: {
            status: {
                value: '',
                squash: true,
                dynamic: true,
            },
            thematic: {
                value: '',
                squash: true,
                dynamic: true,
            },
            object: {
                value: '',
                squash: true,
                dynamic: true,
            },
            phase: {
                value: '',
                squash: true,
                dynamic: true,
            },
            segment: {
                value: '',
                squash: true,
                dynamic: true,
            },
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            internalId: {
                value: '',
                squash: true,
                dynamic: true,
            },
            type: {
                value: '',
                squash: true,
                dynamic: true,
            },
            isActivated: {
                value: '',
                squash: true,
                dynamic: true,
            },
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        component: 'controlGridAdminFormPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin', 'superAdmin'],
        },
    });

    // View
    $stateProvider.state('app.controlGridView', {
        url: '/controlGrids/:gridControlId?status&thematic&type&object&isActivated&phase&search&internalId&p',
        params: {
            status: {
                value: '',
                squash: true,
                dynamic: true,
            },
            thematic: {
                value: '',
                squash: true,
                dynamic: true,
            },
            object: {
                value: '',
                squash: true,
                dynamic: true,
            },
            phase: {
                value: '',
                squash: true,
                dynamic: true,
            },
            segment: {
                value: '',
                squash: true,
                dynamic: true,
            },
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
            internalId: {
                value: '',
                squash: true,
                dynamic: true,
            },
            isActivated: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        component: 'controlGridViewPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin', 'superAdmin'],
        },
    });

    // Deposit grid control details
    $stateProvider.state('app.gridControlFromDeposit', {
        url: '/controlGrids/deposit/:depositId?thematic&object&severity&phase&status&internalId&search&p',
        params: {
            thematic: {
                value: '',
                squash: true,
                dynamic: true,
            },
            object: {
                value: '',
                squash: true,
                dynamic: true,
            },
            severity: {
                value: '',
                squash: true,
                dynamic: true,
            },
            phase: {
                value: '',
                squash: true,
                dynamic: true,
            },
            status: {
                value: '',
                squash: true,
                dynamic: true,
            },
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            internalId: {
                value: '',
                squash: true,
                dynamic: true,
            },
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        component: 'controlGridFromDepositPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });

    // Compare grid control
    $stateProvider.state('app.gridControlCompare', {
        url: '/controlGrids/compareGrid/{grid1:[A-Za-z0-9]+}/{grid2:[A-Za-z0-9]+}/view?company&search&idInterne&thematic&object&diff',
        params: {
            company: {
                value: '',
                squash: true,
                dynamic: true,
            },
            search: {
                value: '',
                squash: true,
                dynamic: true,
            },
            idInterne: {
                value: '',
                squash: true,
                dynamic: true,
            },
            thematic: {
                value: '',
                squash: true,
                dynamic: true,
            },
            object: {
                value: '',
                squash: true,
                dynamic: true,
            },
            diff: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        component: 'compareGridPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });
});
