import template from './nb-bti-indicator.component.html';

class NbBtiIndicatorComponent {
    isOld = false;
    loading = true;
    nbBti = [];
    sumTable = 0;

    constructor($timeout) {
        this._$timeout = $timeout;
    }

    $onInit() {
        this.loading = true;

        this.sumTable = this.nbBti?.length ?? 0;

        this._$timeout(() => {
            this.isOld = !this.nbBti?.length || angular.isUndefined(this.nbBti[0].code);
            this.loading = false;
        });
    }
}

angular.module('dotic').component('nbBtiIndicator', {
    controller: NbBtiIndicatorComponent,
    templateUrl: template,
    bindings: {
        nbBti: '<',
        parentRef: '<',
    },
});
