import { DataModelMongoRegexEnum, DataModelNameEnum } from '../../../models/data-model.model';
import template from './edit-bulk-element-modal.component.html';

class EditBulkElementModalComponent {
    assignment = null;
    configurationId = null;
    companyId = null;
    dataModelId = null;
    dataModelList = [];
    deliveryZoneId = null;
    hasANCTVerification = false;
    hasFttxDataEvolutionEnabled = false;
    hasV301ModelEnabled = false;
    isANCT = false;
    mappedControlConfigurationList = [];
    mappedDataModelList = [];
    mappedProjectList = [];
    networkSegments = null;
    phaseList = [];
    projectList = [];
    projectId = null;
    required = {
        phase: false,
        project: false,
        deliveryZone: false,
        controlConfiguration: false,
        networkSegments: false,
        dataModel: false,
    };
    userId = null;

    constructor(
        $auth,
        $dataModelService,
        $filter,
        $phaseService,
        $scope,
        $segmentsService,
        companyProvider,
        controlConfigurationProvider,
        dataModelProvider,
        deliveryZoneProvider,
        projectProvider,
    ) {
        this._$phaseService = $phaseService;
        this._$segmentsService = $segmentsService;
        this._controlConfigurationProvider = controlConfigurationProvider;
        this._dataModelProvider = dataModelProvider;
        this._deliveryZoneProvider = deliveryZoneProvider;
        this._companyProvider = companyProvider;
        this._projectProvider = projectProvider;
        this._translate = $filter('translate');

        const payload = $auth.getPayload();
        this.companyId = payload.company;
        this.userId = payload.userId;

        $scope.$watch('$ctrl.phase', () => {
            this.required.phase = !this.phase?.length;
        });
        $scope.$watch('$ctrl.projectId', () => this.onProjectSelected());
        $scope.$watch('$ctrl.configurationId', () => this.onConfigurationSelected());
        $scope.$watch('$ctrl.deliveryZoneId', () => this.onDeliveryZoneSelected());
        $scope.$watch('$ctrl.dataModelId', () => this.onDataModelSelected());
        $scope.$watch('$ctrl.networkSegments', () => this.onSegmentSelected());
    }

    async $onInit() {
        const company = await this._companyProvider.get(this.companyId);
        this.hasANCTVerification = company.settings.feature.hasANCTVerification;
        this.hasFttxDataEvolutionEnabled = company.settings.feature.hasFttxDataEvolutionEnabled;
        this.hasV301ModelEnabled = company.settings.feature.hasV301ModelEnabled;

        this.filename = this.resolve.filename;
        this.project = this.resolve.project;
        this.deliveryZone = this.resolve.deliveryZone;
        this.phase = this.resolve.phase;
        this.networkSegments = this.resolve.networkSegments || [];
        this.configuration = this.resolve.configuration;
        this.hasFttxIndicators = this.resolve.hasFttxIndicators;
        this.isANCT = this.resolve.isANCT;

        const dataModel = this.resolve.dataModel;
        if (dataModel?.slug !== DataModelMongoRegexEnum.V301 || this.hasV301ModelEnabled) {
            this.dataModel = dataModel;
        }

        this.segmentsList = this._$segmentsService.getAll().map((segment) => ({ key: segment, value: segment }));

        const projects = await this._companyProvider.getAllProjectsForDeposit(this.companyId);
        projects.sort((a, b) => a.name.localeCompare(b.name));
        this.projectList = projects;
        this.mappedProjectList = projects.map(this._mapIdName);

        if (this.project) {
            this.projectId = this.project.id;
            await this.onProjectSelected();
        }

        if (this.deliveryZone) {
            this.deliveryZoneId = this.deliveryZone.id;
            await this.onDeliveryZoneSelected();
        }

        if (this.dataModel) {
            this.dataModelId = this.dataModel.id;
            await this.onDataModelSelected();
        }

        if (this.configuration) {
            this.configurationId = this.configuration.id;
            await this.onConfigurationSelected();
        }
    }

    async onProjectSelected() {
        if (!angular.isString(this.projectId) || !this.projectId) {
            this.required.project = true;

            return;
        }

        this.required.project = false;

        this.project = this.projectList.find((item) => item._id === this.projectId);
        this.assignment = this.project.assignments.find(({ user }) => user === this.userId);

        let activeConfigurations = this.project.controlConfigurations.filter(({ isActive }) => isActive);

        // Get a list of dataModel available from the active configuration list
        this.dataModelList = activeConfigurations
            .filter((el) => el.dataModel)
            .map((el) => el.dataModel)
            .sort((a, b) => a.name.localeCompare(b.name))
            .reduce((acc, item) => {
                const index = acc.findIndex((x) => x.id === item.id);
                if (index === -1) {
                    acc.push(item);
                }

                return acc;
            }, []);

        // If a dataModel has been selected
        if (this.dataModel) {
            this.dataModelId = this.dataModel._id;
            // If a dataModel is selected, filter configuration list to the matching elements
            activeConfigurations = activeConfigurations.filter(({ dataModel }) => dataModel._id === this.dataModel._id);
        } else {
            // Empty the list when nothing is selected
            activeConfigurations = [];
        }

        // Pre-select a default configuration when possible
        if (activeConfigurations.length > 0) {
            const defaultConfiguration = activeConfigurations.find(
                ({ isDefaultConfigurationDataModel }) => isDefaultConfigurationDataModel,
            );
            this.configurationId = defaultConfiguration ? defaultConfiguration._id : activeConfigurations[0]._id;
        } else {
            this.dataModel = null;
            this.isANCT = false;
            this.hasFttxIndicators = false;
        }

        // Prepare list of DataModel available
        this.mappedDataModelList = this.dataModelList.map(this._mapDataModel);

        if (!this.hasV301ModelEnabled) {
            this.mappedDataModelList = [
                ...this.mappedDataModelList,
                {
                    disabled: true,
                    disabledTooltip: this._translate('shared.unavailableFeature'),
                    key: 'dummy_key',
                    value: DataModelNameEnum.GRACETHDV301,
                },
            ];
        }

        // Prepare list of Conf ctrl available
        this.mappedControlConfigurationList = activeConfigurations.map(this._mapControlConfiguration);

        // Set phases and filter by user assignments
        this.setPhases(this.project.phases, null);

        // Get associated delivery zones
        this._projectProvider.getAllDeliveryZone(this.projectId).then((deliveryZones) => {
            this.mappedDeliveryZoneList = deliveryZones
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(this._mapIdName);
        });

        await this.onConfigurationSelected();
    }

    async onDeliveryZoneSelected() {
        if (!this.deliveryZoneId || !angular.isString(this.deliveryZoneId)) {
            this.required.deliveryZone = true;

            return;
        }

        this.deliveryZone = await this._deliveryZoneProvider.get(this.deliveryZoneId);
        this.required.deliveryZone = false;
    }

    async onConfigurationSelected() {
        if (!this.configurationId || !angular.isString(this.configurationId)) {
            return;
        }

        this.configuration = await this._controlConfigurationProvider.get(this.configurationId);
    }

    onSegmentSelected() {
        this.required.networkSegments = !this.networkSegments?.length;
    }

    async onDataModelSelected() {
        if (!this.dataModelId || !angular.isString(this.dataModelId)) {
            return;
        }

        this.dataModel = await this._dataModelProvider.get(this.dataModelId);
        await this.onProjectSelected();
    }

    setPhases(projectPhases, controlGridPhases) {
        let phaseList = this._$phaseService
            .getAll()
            .filter((phase) => projectPhases.includes(phase))
            .filter((phase) => controlGridPhases === null || controlGridPhases.includes(phase));

        if (this.assignment && this.assignment.type === 'subcontractor') {
            const assignmentAllowedPhases = this.assignment.phases || [];
            phaseList = this.phaseList.filter(
                (phase) => this.assignment.company === this.companyId || assignmentAllowedPhases.indexOf(phase) !== -1,
            );
        }

        this.phaseList = phaseList;
        this.mappedPhaseList = phaseList.map(this._mapPhase);
    }

    _mapControlConfiguration({ id, name }) {
        return { key: id, value: name };
    }

    _mapIdName({ id, name }) {
        return { key: id, value: name };
    }

    _mapPhase(phase) {
        return { key: phase, value: phase };
    }

    _mapDataModel({ id, name }) {
        return { key: id, value: name };
    }

    onFormSubmit() {
        if (
            this.project &&
            this.deliveryZone &&
            this.configuration &&
            this.dataModel &&
            this.networkSegments.length &&
            this.phase
        ) {
            return this.close({
                $value: {
                    configuration: this.configuration,
                    dataModel: this.dataModel,
                    deliveryZone: this.deliveryZone,
                    hasFttxIndicators: this.hasFttxIndicators,
                    isANCT: this.isANCT,
                    networkSegments: this.networkSegments,
                    phase: this.phase,
                    project: this.project,
                    projectId: this.deliveryZone.projectId,
                },
            });
        }

        if (!this.phase) {
            this.required.phase = true;
        }

        if (!this.project) {
            this.required.project = true;
        }

        if (!this.deliveryZone) {
            this.required.deliveryZone = true;
        }

        if (!this.dataModel) {
            this.required.dataModel = true;
        }

        if (!this.configuration) {
            this.required.controlConfiguration = true;
        }
    }
}

angular.module('dotic').component('editBulkElementModal', {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
    },
    controller: EditBulkElementModalComponent,
    templateUrl: template,
});
