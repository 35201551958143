import template from './cable-length-by-implantation-indicator.component.html';

class CableLengthByImplantationIndicatorComponent {
    data = [];
    dataStructure = {
        capacityTableChartData: { datasets: [], labels: [] },
        data: [],
        iconOptions: [],
        lengthTableChartData: { datasets: [], labels: [] },
        tableData: { columns: [], title: '' },
    };
    parentRef = {};

    constructor($filter, indicatorR00141Service) {
        this._indicatorR00141Service = indicatorR00141Service;
    }

    $onInit() {
        this.dataStructure = this._indicatorR00141Service.generateDataStructure(this.data);
    }
}

angular.module('dotic').component('cableLengthByImplantationIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: CableLengthByImplantationIndicatorComponent,
    templateUrl: template,
});
