import template from './records-indicator.component.html';

class RecordsIndicatorComponent {
    constructor($indicatorRecordsService, $timeout) {
        this._$indicatorRecordsService = $indicatorRecordsService;
        this._$timeout = $timeout;

        this.abandonedObjectTree = [];
        this.isVisibleAbandonedObjects = false;
        this.chartData = null;
        this.data = [];
        this.hasAbandonedObjects = false;
        this.isOld = false;
        this.resultTablesData = [];

        this.loading = true;
    }

    $onInit() {
        if (
            !this.data?.recordsTable?.length ||
            angular.isUndefined(this.data?.recordsTable[0]['nb_lignes_par_table'])
        ) {
            this._$timeout(() => {
                this.isOld = true;
                this.loading = false;
            });

            return;
        }

        this.hasAbandonedObjects = !!this.data.abandonedObjectTable.length;

        this.newTable = this._$indicatorRecordsService.initNewTable(
            this.data.recordsTable,
            this.data.abandonedObjectTable,
        );

        this.resultTablesData = this._$indicatorRecordsService.fetchResultTables(this.newTable);

        this.totalTableTree = this._$indicatorRecordsService.initTotalTableTree(this.resultTablesData, [], false);

        if (this.hasAbandonedObjects) {
            this.abandonedObjectTree = this._$indicatorRecordsService.initAbandonedObjectTree(
                this.resultTablesData,
                [],
                false,
            );
        }

        this.chartOptions = this._$indicatorRecordsService.initChartOption(this.hasAbandonedObjects);
        this.chartData = this._$indicatorRecordsService.initChartData(
            this.resultTablesData,
            [],
            this.hasAbandonedObjects,
            false,
            this.isVisibleAbandonedObjects,
        );

        this.loading = false;
    }
}

angular.module('dotic').component('recordsIndicator', {
    controller: RecordsIndicatorComponent,
    templateUrl: template,
    bindings: {
        data: '<',
        isVisibleAbandonedObjects: '<?',
        parentRef: '<',
    },
});
