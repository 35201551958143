import { FMEJobEnum } from '../models/deposit.model';

angular.module('dotic').factory('depositProvider', depositProvider);

function depositProvider(ApiProvider, $httpParamSerializer, URI_KEYS) {
    return {
        update: updateDeposit,
        delete: deleteDeposit,
        get: getDeposit,
        getDashboard: getDashboard,
        getJobStatus: getJobStatus,
        getListDetail: getListDetail,
        getReport: getReportDeposit,
        getComparableDeposit,
        runAction,
        listFilters,
        listAll,
        listLoad,
        listControl,
        listVersions,
        exportToS3,
    };

    /**
     * DELETE /deposits/:depositId
     */

    function deleteDeposit(depositId) {
        return ApiProvider.one(URI_KEYS.DEPOSIT, depositId).remove();
    }

    /**
     *GET /deposits/:depositId
     */
    function getDeposit(depositId) {
        return ApiProvider.one(URI_KEYS.DEPOSIT, depositId).get();
    }

    function getJobStatus(depositId) {
        return ApiProvider.one(URI_KEYS.DEPOSIT, depositId).one('job-status').get();
    }

    function getListDetail(depositId) {
        return ApiProvider.one(URI_KEYS.DEPOSIT, depositId).one('list-details').get();
    }

    function getQueryParams({ filters = {}, sort = { column: 'createdAt', order: -1 } } = {}) {
        const qs = Object.keys(filters).reduce((acc, key) => {
            if (filters.idLivrable && filters.idLivrable === 'N/A') {
                acc[key] = filters[key];
            }

            if (filters[key] && filters[key] !== '') {
                acc[key] = filters[key];
            }

            return acc;
        }, {});

        // Add sort
        qs.column = sort.column;
        qs.order = sort.order;

        return qs;
    }

    /**
     * GET /deposits/
     */
    function listAll({ page = 0, filters = {}, perPage = 20, sort = { column: 'createdAt', order: -1 } } = {}) {
        const qs = getQueryParams({ filters, sort });

        return ApiProvider.one(URI_KEYS.DEPOSIT).customGET(null, {
            page,
            perPage,
            ...qs,
        });
    }

    /**
     * GET /deposits/load
     */
    function listLoad({ page = 0, filters = {}, perPage = 20, sort = { column: 'createdAt', order: -1 } } = {}) {
        const qs = getQueryParams({ filters, sort });

        return ApiProvider.one(URI_KEYS.DEPOSIT, FMEJobEnum.LOAD).customGET(null, {
            page,
            perPage,
            ...qs,
        });
    }

    /**
     * GET /deposits/control
     */
    function listControl({ page = 0, filters = {}, perPage = 20, sort = { column: 'createdAt', order: -1 } } = {}) {
        const qs = getQueryParams({ filters, sort });

        return ApiProvider.one(URI_KEYS.DEPOSIT, FMEJobEnum.CONTROL).customGET(null, {
            page,
            perPage,
            ...qs,
        });
    }

    /**
     * GET /deposits/control
     */
    function listFilters({ filters = {} } = {}) {
        const qs = Object.keys(filters).reduce((acc, key) => {
            if (filters[key] && filters[key] !== '') {
                acc[key] = filters[key];
            }

            return acc;
        }, {});

        return ApiProvider.one(URI_KEYS.DEPOSIT, 'list-filters').customGET(null, { ...qs });
    }

    /**
     * GET /deposits/versions
     */
    function listVersions(queryParams = {}) {
        return ApiProvider.one(URI_KEYS.DEPOSIT, 'list-versions').customGET(null, queryParams);
    }

    /**
     * GET /deposits/:depositId/reports
     * @param {string} depositId
     */
    function getReportDeposit(depositId) {
        return ApiProvider.one(URI_KEYS.DEPOSIT, depositId).one(URI_KEYS.REPORT).get();
    }

    /**
     * POST /deposits/:depositId/run-action
     * @param {string} depositId
     * @param {string} action
     * @param {*} postProcessingParams
     */
    function runAction(depositId, action, postProcessingParams = {}) {
        return ApiProvider.one(URI_KEYS.DEPOSIT, depositId)
            .one('run-action')
            .customPOST({ action, ...postProcessingParams });
    }

    /**
     * PUT /deposit/:depositId
     */
    function updateDeposit(depositId, deposit) {
        return ApiProvider.one(URI_KEYS.DEPOSIT, depositId).customPUT(deposit);
    }

    /**
     * GET /deposits/:depositId/exportToS3
     *
     * @param {string} depositId
     * @param {string} reportId
     * @returns {*} Promise
     */
    function exportToS3(depositId, reportId) {
        return ApiProvider.one(URI_KEYS.DEPOSIT, depositId).one('exportToS3').customGET(reportId);
    }

    /**
     * GET /deposits/:depositId/comparable
     *
     * @param {string} depositId
     * @returns {*} Promise
     */
    function getComparableDeposit(depositId) {
        return ApiProvider.one(URI_KEYS.DEPOSIT, depositId).one('comparable').get();
    }

    /**
     * GET /deposits/dashboard
     *
     * @returns {*} Promise
     */
    function getDashboard() {
        return ApiProvider.one(URI_KEYS.DEPOSIT, 'dashboard').get();
    }
}
