// Imports
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../../assets/images/help/lStock/lStock-visualiser.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../../assets/images/help/lStock/lStock-associe.jpg");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../../../assets/images/help/lStock/lStock-modeCarto.jpg");
// Module
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 5.3.4 / {{ 'shared.cartographicMode' | translate }} : </h3> <ul class=\"help-Doc__ul\"> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_0___ + "\" alt=\"Visualiser un livrable stocké\"/> <li class=\"help-Doc__subTitre4\"> {{ 'helpDeliverable.visualizeStockedCartoAssociated' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_1___ + "\" alt=\"Associer à un rapport\"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'shared.cartographicMode' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_2___ + "\" alt=\"{{ 'shared.cartographicMode' | translate }}\"/> </li> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/gestionDepot/livrableStocke/cartoLStocke.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;