import FavoriteIndicatorsService from '../../../../../services/favorite-indicators.service';
import { IndicatorNameEnum } from '../../../../../../sdk/connect-control-api-v1/src';

export default class FttxDataEvolutionThematicIndicatorsComponent {
    static defaultBindings = {
        company: '<',
        comparisonIndicators: '<',
        detailDifferencesArrays: '<',
        isGraceV3: '<?',
        loading: '<?',
        onDownloadDifferences: '&',
        onUpdateFavoriteIndicators: '&',
        orderedIndicatorKeys: '<',
        referenceIndicators: '<',
    };

    ANCTComparisonDataStructure = {};
    ANCTReferenceDataStructure = {};
    company = null;
    comparisonIndicators = null;
    detailDifferencesArrays = {};
    loading = false;
    onDownloadDifferences = null;
    onUpdateFavoriteIndicators = null;
    orderedIndicatorKeys = [];
    referenceIndicators = null;

    constructor($favoriteIndicatorsService, indicatorChartService) {
        this._$favoriteIndicatorsService = $favoriteIndicatorsService;
        this._indicatorChartService = indicatorChartService;
        this.IndicatorNameEnum = new IndicatorNameEnum();
    }

    $onInit() {
        if (
            !this.comparisonIndicators ||
            !this.detailDifferencesArrays ||
            !this.referenceIndicators ||
            !angular.isFunction(this.onDownloadDifferences) ||
            !angular.isFunction(this.onUpdateFavoriteIndicators)
        ) {
            throw new Error('Mandatory inputs are missing in FttxDataEvolutionThematicComponent');
        }

        this.ANCTComparisonDataStructure = this._indicatorChartService.generateANCTFullDataStructure(
            this.comparisonIndicators.anct,
        );
        this.ANCTReferenceDataStructure = this._indicatorChartService.generateANCTFullDataStructure(
            this.referenceIndicators.anct,
        );
    }

    getFavoriteIndicatorsTooltipMessage(indicatorName) {
        return this._$favoriteIndicatorsService.getFavoriteIndicatorsTooltipMessage(this.company, indicatorName);
    }

    isIndicatorFavorite(indicatorName) {
        return FavoriteIndicatorsService.isIndicatorFavorite(this.company, indicatorName);
    }

    onDownloadDifferencesDetailClick(indicatorName) {
        this.onDownloadDifferences({ indicatorName: indicatorName });
    }

    onFavoriteButtonClick(indicatorName, isFavorite) {
        this.onUpdateFavoriteIndicators({ indicatorName: indicatorName, isFavorite: isFavorite });
    }
}
