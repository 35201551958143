import FttxDataEvolutionThematicIndicatorsComponent from '../fttx-data-evolution-view-indicators-thematic.component';
import template from './fttx-data-evolution-fibers-cables.component.html';

class FttxDataEvolutionFibersCablesIndicatorsComponent extends FttxDataEvolutionThematicIndicatorsComponent {
    constructor($favoriteIndicatorsService, indicatorChartService) {
        super($favoriteIndicatorsService, indicatorChartService);
    }
}

angular.module('dotic').component('fttxDataEvolutionFibersCablesIndicatorsComponent', {
    controller: FttxDataEvolutionFibersCablesIndicatorsComponent,
    templateUrl: template,
    bindings: FttxDataEvolutionThematicIndicatorsComponent.defaultBindings,
});
