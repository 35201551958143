import template from './pathways-indicator.component.html';

class PathwaysIndicatorComponent {
    constructor($chartService, $indicatorOpticalCableSupportService, $indicatorMaxValueService, $timeout) {
        this._$indicatorOpticalCableSupportService = $indicatorOpticalCableSupportService;
        this._$indicatorMaxValueService = $indicatorMaxValueService;
        this._$timeout = $timeout;

        this.loaded = false;
        this.data = null;
        this.isOld = false;
        this.pathwayTypePhyChartData = null;
        this.pathways = null;
        this.depositUnknown = false;
        this.newNbChems = false;
        this.sumNbChems = 0;

        this.options = {
            responsive: true,
            scales: $chartService.getDefaultScaleConfiguration(false),
        };
    }

    $onInit() {
        if (!this.data?.table || !this.data.table[0] || angular.isUndefined(this.data.table[0].avancement)) {
            this.isOld = true;
        } else {
            this.isOld = false;

            if (this.data.table[0].avancement === 'Inconnu') {
                this.depositUnknown = true;
            }

            const pathwayTypePhyChartData = this.buildPathwayTypeChart(this.data);
            const pathways = this.sumByPathwayTypePhy(this.data);
            this.sumNbChems = this.getSumNbChems(this.data);
            const pathwayMaxValue = this._$indicatorMaxValueService.findMaxValueFlatten(pathwayTypePhyChartData);

            this._$timeout(() => {
                this.pathwayTypePhyChartData = pathwayTypePhyChartData;
                this.pathwayMaxValue = pathwayMaxValue;
                this.pathways = pathways;
            });
        }

        this.loaded = true;
    }

    getSumNbChems({ table }) {
        const result = Object.values(table).reduce((avancement, nbChems) => {
            for (const [key, value] of Object.entries(nbChems)) {
                if (!avancement[key]) {
                    avancement[key] = 0;
                }

                avancement[key] += value;
            }

            return avancement;
        }, {});

        return result.nbChems;
    }

    sumByPathwayTypePhy({ table }) {
        const pathways = {};

        table
            .sort((a, b) => a.avancement.localeCompare(b.avancement))
            .forEach((entry) => {
                const { avancement, countReal, countGeom, nbChems } = entry;
                if (!pathways[avancement]) {
                    const status = this._$indicatorOpticalCableSupportService.getData(avancement);
                    const colorReal = status?.colorSaisie || 'GREY';
                    const colorGeom = status?.colorCarto || '';

                    this.newNbChems = angular.isNumber(nbChems);

                    const title = status?.title || '';
                    const icon = status?.icon || '';

                    pathways[avancement] = {
                        countReal: 0,
                        countGeom: 0,
                        nbChems,
                        title,
                        colorGeom,
                        colorReal,
                        icon,
                    };
                }

                pathways[avancement].countReal += countReal;
                pathways[avancement].countGeom += countGeom;
            });

        return pathways;
    }

    buildPathwayTypeChart(data) {
        const { pathway_support, status } = data;
        if (!angular.isObject(pathway_support)) {
            return null;
        }

        const labels = Object.keys(pathway_support);

        const datasets = [];

        status.map((state) => {
            const status = this._$indicatorOpticalCableSupportService.getData(state);

            const colorReal = status?.colorSaisie || '';
            const colorGeom = status?.colorCarto || '';

            const geom = Object.values(pathway_support).map((path) => {
                if (path[state]?.countGeom) {
                    return path[state].countGeom;
                }

                return 0;
            });

            const real = Object.values(pathway_support).map((path) => {
                if (path[state]?.countReal) {
                    return path[state].countReal;
                }

                return 0;
            });

            datasets.push(
                {
                    backgroundColor: colorGeom,
                    label: `Lg carto : ${state}`,
                    data: geom.map((element) => element?.toFixed(1) ?? null),
                    borderColor: '#fff',
                    borderWidth: 1,
                    categoryPercentage: 0.75,
                    barPercentage: 0.75,
                    maxBarThickness: 15,
                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        color: colorGeom,
                        clamp: true,
                        font: {
                            weight: 'bold',
                        },
                    },
                },
                {
                    backgroundColor: colorReal,
                    label: `Lg réelle : ${state}`,
                    data: real.map((element) => element?.toFixed(2) ?? null),
                    borderColor: '#fff',
                    borderWidth: 1,
                    categoryPercentage: 0.75,
                    barPercentage: 0.75,
                    maxBarThickness: 15,
                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        color: colorReal,
                        clamp: true,
                        font: {
                            weight: 'bold',
                        },
                    },
                },
            );
        });

        return { datasets, labels };
    }
}

angular.module('dotic').component('pathwaysIndicator', {
    controller: PathwaysIndicatorComponent,
    templateUrl: template,
    bindings: {
        data: '<',
        parentRef: '<',
        isGraceV3: '<',
    },
});
