export default class FavoriteIndicatorsService {
    constructor($filter) {
        this._translate = $filter('translate');
    }

    getFavoriteIndicatorsTooltipMessage(company, indicatorName) {
        return this._translate(
            FavoriteIndicatorsService.isIndicatorFavorite(company, indicatorName)
                ? 'indicator.removeFromFavorite'
                : 'indicator.addToFavorite',
        );
    }

    static isIndicatorFavorite(company, indicatorName) {
        return !!company?.settings?.configuration?.favoriteIndicatorKeys.includes(indicatorName);
    }
}

angular.module('dotic').factory('$favoriteIndicatorsService', FavoriteIndicatorsService);
