import { DepositsApi, ReportsApi } from '../../../../sdk/connect-control-api-v1/src';
import template from './reportCompare.html';

const filterResultList = [
    { key: 'diff', value: 'Différences' },
    { key: 'same', value: 'Identiques' },
    { key: 'unused', value: 'Inexistant' },
    { key: 'error', value: 'Inopérant' },
    { key: 'fail', value: 'Anomalies' },
    { key: 'success', value: 'Réussi' },
];

class ReportComparePage {
    company = {};

    constructor(
        $apiClientService,
        $auth,
        $authorizationService,
        $companySettings,
        $controlStatisticsService,
        $dataModelService,
        $excelService,
        $filter,
        $filterService,
        $indicatorDistanceRangeService,
        $indicatorNewPlugService,
        $location,
        $modalService,
        $reportService,
        $scope,
        $segmentsService,
        $state,
        $timeout,
        $toasterService,
        LoaderService,
        RestProvider,
        reportProvider,
        userMetricsProvider,
    ) {
        this._$auth = $auth;
        this._$companySettings = $companySettings;
        this._$controlStatisticsService = $controlStatisticsService;
        this._$dataModelService = $dataModelService;
        this._$excelService = $excelService;
        this._$filterService = $filterService;
        this._humanizeSeverity = $filter('humanizeSeverity');
        this._humanizeThematic = $filter('humanizeThematic');
        this._translate = $filter('translate');
        this._$indicatorDistanceRangeService = $indicatorDistanceRangeService;
        this._$indicatorNewPlugService = $indicatorNewPlugService;
        this._$location = $location;
        this._$modalService = $modalService;
        this._$reportService = $reportService;
        this._$scope = $scope;
        this._$segmentsService = $segmentsService;
        this._$state = $state;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._LoaderService = LoaderService;
        this._RestProvider = RestProvider;
        this._depositApi = new DepositsApi($apiClientService.client);
        this._reportApi = new ReportsApi($apiClientService.client);
        this._reportProvider = reportProvider;
        this._userMetricsProvider = userMetricsProvider;

        this.isAllowed = $authorizationService.isAllowed;
        this.companyId = $auth.getPayload().company;
        this.controls = [];
        this.controlShown = null;
        this.reportData1 = {};
        this.reportData2 = {};
        this.filteredControls = [];

        this.graphToCompare = 'controls';
        this.indicators = [];
        this.loading = true;
        this.reportDatas = [{}, {}];
        this.chartData = null;
        this.hasGraphsOpen = false;
        this.hasAnomaliesOpen = false;

        this.isBottomFilter = true;
        this.filterOptions = [
            {
                allowed: true,
                name: 'activity',
                value: 'activity',
                placeholder: 'filter.all.activeAndInactive',
                translateKeyLabel: 'filter.activity',
            },
            {
                allowed: true,
                name: 'severities',
                selected: 'severity',
                placeholder: 'filter.all.severity',
                translateKeyLabel: 'shared.severity',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'objects',
                selected: 'object',
                placeholder: 'filter.all.object',
                hasSearch: true,
                translateKeyLabel: 'shared.object',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'results',
                value: 'result',
                placeholder: 'filter.all.result',
                translateParamsLabel: { COUNT: 1 },
                translateKeyLabel: 'shared.result',
            },
            {
                allowed: true,
                name: 'thematics',
                selected: 'thematic',
                placeholder: 'filter.all.thematic',
                hasSearch: true,
                translateKeyLabel: 'shared.thematic',
                type: 'multi-select',
            },
        ];

        this.filterValues = {
            objects: [],
            thematics: [],
            severities: [],
            results: [],
            activity: [],
        };

        this.filters = {
            activity: '',
            severity: [],
            object: [],
            result: '',
            thematic: [],
        };

        // Set filters from urls
        const { params } = $state;
        this.setFilters(params);
        $scope.$on('$locationChangeSuccess', () => {
            this.setFilters(params);
        });

        // Watch scope changes
        $scope.$watchGroup(
            [
                '$ctrl.filters.thematic',
                '$ctrl.filters.object',
                '$ctrl.filters.severity',
                '$ctrl.filters.result',
                '$ctrl.filters.activity',
                '$ctrl.filters.search',
                '$ctrl.controls',
            ],
            () => this.filterControls(),
        );
    }

    setFilters(params = {}, includeSearch = true) {
        if (!includeSearch && this.filters) {
            params.search = this.filters.search;
        }

        this.filters = {
            thematic: this._$filterService.getFilterValue(params.thematic),
            object: this._$filterService.getFilterValue(params.object),
            severity: this._$filterService.getFilterValue(params.severity),
            result: params.result || '',
            activity: parseInt(params.activity) || '',
            search: params.search || '',
        };
    }

    async prepareDeposits() {
        const reportDatas = [
            await this.getReportFromDeposit(this._$state.params.deposit1),
            await this.getReportFromDeposit(this._$state.params.deposit2),
        ];

        this.company = reportDatas[0].deposit.company;

        this.reportDatas = reportDatas.map((reportData) => {
            const cloneDeposit = { ...reportData.deposit };
            cloneDeposit.networkSegments = this._$segmentsService.setNetworkSegments(
                reportData.deposit.networkSegments,
            );

            if (!cloneDeposit.networkSegments.includes('DI')) {
                cloneDeposit.hasSegmentTrDi = false;
                cloneDeposit.hasSegmentDi = false;

                return {
                    controls: reportData.controls,
                    deposit: cloneDeposit,
                    statistics: reportData.statistics,
                };
            }

            cloneDeposit.hasSegmentTrDi = cloneDeposit.networkSegments.includes('TR');
            cloneDeposit.hasSegmentDi = !cloneDeposit.hasSegmentTrDi;

            return {
                controls: reportData.controls,
                deposit: cloneDeposit,
                statistics: reportData.statistics,
            };
        });

        this.isGraceV3 = this._$dataModelService.isDataGraceTHDV3(this.reportDatas[0].deposit);

        this.reportData1 = reportDatas[0];
        this.reportData2 = reportDatas[1];

        this._userMetricsProvider.compareReports(
            this.reportData1.deposit.report.id,
            this.reportData2.deposit.report.id,
        );
    }

    getFilterValues() {
        return {
            objects: this.getObjectList(this.controls),
            severities: this.getSeverityList(this.controls),
            thematics: this.getThematicList(this.controls),
            results: filterResultList,
            activity: [
                {
                    key: 1,
                    value: `${this._translate('reportCompare.activeForReport')} v${this.reportData1.deposit.version}`,
                },
                {
                    key: 2,
                    value: `${this._translate('reportCompare.activeForReport')} v${this.reportData2.deposit.version}`,
                },
                {
                    key: -1,
                    value: `${this._translate('reportCompare.disabledForReport')} v${this.reportData1.deposit.version}`,
                },
                {
                    key: -2,
                    value: `${this._translate('reportCompare.disabledForReport')} v${this.reportData2.deposit.version}`,
                },
            ],
        };
    }

    initReportDataIndicators(reportData) {
        return {
            pbo: [
                { value: reportData.statistics.indicators.pbo, id: 'pbo' },
                { value: reportData.statistics.indicators.bpe, id: 'bpe' },
                {
                    value: reportData.statistics.indicators.type_ebp_pto,
                    id: 'pto',
                },
            ],
            housing: reportData.statistics.indicators.housing,
            tables: reportData.statistics.indicators.tables,
            pathway: reportData.statistics.indicators.pathway,
            natureTypePhy: reportData.statistics.indicators.nature_type_phy,
            avgPBO: reportData.statistics.indicators.avgpbo,
            nbBPE: reportData.statistics.indicators.nbBPE,
            lgCable: reportData.statistics.indicators.lgCable,
            newPlug: reportData.statistics.indicators.newPlug,
            connect: reportData.statistics.indicators.connectivity,
            abandonedObject: reportData.statistics.indicators.abandonedObject,
            opticalCableSupport: reportData.statistics.indicators.opticalCableSupport,
            nbBti: reportData.statistics.indicators.nbBti,
            cableBti: reportData.statistics.indicators.cableBti,
            distanceBetweenNroPbo: reportData.statistics.indicators.distanceBetweenNroPbo,
            distanceBetweenSroPbo: reportData.statistics.indicators.distanceBetweenSroPbo,
            distanceBetweenNroSro: reportData.statistics.indicators.distanceBetweenNroSro,
            junctionBox: reportData.statistics.indicators.junctionBox,
            oldSRO: true,
            oldNRO: true,
            oldDistanceNroPbo: true,
            oldDistanceNroSro: true,
            oldDistanceSroPbo: true,
        };
    }

    initReportDataIndicatorsNewTable(reportDataIndicators, reportData) {
        reportDataIndicators.newTable = {
            recordsTable: reportData?.statistics?.indicators?.tables ?? [],
            abandonedObjectTable:
                reportData?.statistics?.indicators?.abandonedObject === 0
                    ? []
                    : reportData.statistics.indicators.abandonedObject,
        };
    }

    initReportDataIndicatorsPlug(reportDataIndicators, reportData) {
        const plugsData =
            reportData.statistics.indicators.newPlug ??
            this._$indicatorNewPlugService.initIndicNewPlug(reportData.statistics.indicators);

        reportDataIndicators.housingTree = this._$indicatorNewPlugService.initHousingTree(plugsData, null, false);

        reportDataIndicators.plug = this._$indicatorNewPlugService.initPlugTree(plugsData, null, false);
    }

    initReportDataIndicatorsSroTypePhy(reportDataIndicators, reportData) {
        if (!reportData.statistics.indicators.sroTypePhy) {
            return;
        }

        reportDataIndicators.oldSRO = false;

        reportDataIndicators.sroTypePhy = {
            parent: {
                textIcon: 'SRO',
                color: '#5069a9',
                value: reportData.statistics.indicators.sroTypePhy.reduce((acc, sro) => acc + sro.count, 0),
            },
            children: reportData.statistics.indicators.sroTypePhy.map((sro) => {
                return {
                    icon: 'building',
                    color: '#e971b1',
                    text: sro.type.toLocaleLowerCase(),
                    value: sro.count,
                };
            }),
        };
    }

    initReportDataIndicatorsNroTypePhy(reportDataIndicators, reportData) {
        if (!reportData.statistics.indicators.nroTypePhy) {
            return;
        }

        reportDataIndicators.oldNRO = false;

        reportDataIndicators.nroTypePhy = {
            parent: {
                textIcon: 'NRO',
                color: '#5069a9',
                value: reportData.statistics.indicators.nroTypePhy.reduce((acc, nro) => acc + nro.count, 0),
            },
            children: reportData.statistics.indicators.nroTypePhy.map((nro) => {
                return {
                    icon: 'building',
                    color: '#e971b1',
                    text: nro.type.toLocaleLowerCase(),
                    value: nro.count,
                };
            }),
        };
    }

    initReportDataIndicatorsDistanceNroPbo(reportDataIndicators, reportData) {
        if (!reportData.statistics.indicators.distanceBetweenNroPbo) {
            return;
        }

        if (reportData.deposit.hasSegmentTrDi) {
            reportDataIndicators.oldDistanceNroPbo = false;
            reportDataIndicators.distanceBetweenNroPboChart =
                this._$indicatorDistanceRangeService.preparedChartDistanceRange(
                    reportData.statistics.indicators.distanceBetweenNroPbo,
                );

            return;
        }

        reportDataIndicators.oldDistanceNroPbo = true;
        reportData.statistics.indicators.distanceBetweenNroPbo = [];
        reportDataIndicators.distanceBetweenNroPboChart = 0;
    }

    initReportDataIndicatorsDistanceNroSro(reportDataIndicators, reportData) {
        if (!reportData.statistics.indicators.distanceBetweenNroSro) {
            return;
        }

        if (reportData.deposit.hasSegmentTrDi) {
            reportDataIndicators.oldDistanceNroSro = false;
            reportDataIndicators.distanceBetweenNroSroChart =
                this._$indicatorDistanceRangeService.preparedChartDistanceRange(
                    reportData.statistics.indicators.distanceBetweenNroSro,
                );

            return;
        }

        reportDataIndicators.oldDistanceNroSro = true;
        reportData.statistics.indicators.distanceBetweenNroSro = [];
        reportDataIndicators.distanceBetweenNroSroChart = 0;
    }

    initReportDataIndicatorsDistanceSroPbo(reportDataIndicators, reportData) {
        if (!reportData.statistics.indicators.distanceBetweenSroPbo) {
            return;
        }

        if (reportData.deposit.hasSegmentDi) {
            reportDataIndicators.oldDistanceSroPbo = false;
            reportDataIndicators.distanceBetweenSroPboChart =
                this._$indicatorDistanceRangeService.preparedChartDistanceRange(
                    reportData.statistics.indicators.distanceBetweenSroPbo,
                );

            return;
        }

        reportDataIndicators.oldDistanceSroPbo = true;
        reportData.statistics.indicators.distanceBetweenSroPbo = [];
        reportDataIndicators.distanceBetweenSroPboChart = 0;
    }

    async $onInit() {
        this._$scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    key: 'comparison',
                    title: this._translate('shared.comparison'),
                    href: this._$location.path(),
                },
            ],
            defaultPrevious: {
                title: this._translate('shared.controlDeposit'),
                href: this._$state.href('app.depositJob-control'),
                key: 'deposits',
            },
            allowedPreviousKeys: ['deposits'],
        });

        try {
            await this.prepareDeposits();
            this.prepareControls();
        } catch (error) {
            this._$toasterService.error(error);
            this._$timeout(() => (this.loading = false));

            return;
        }

        this.filterValues = this.getFilterValues();

        this.indicators = this.reportDatas.map((reportData) => {
            const reportDataIndicators = this.initReportDataIndicators(reportData);

            this.initReportDataIndicatorsNewTable(reportDataIndicators, reportData);
            this.initReportDataIndicatorsPlug(reportDataIndicators, reportData);
            this.initReportDataIndicatorsSroTypePhy(reportDataIndicators, reportData);
            this.initReportDataIndicatorsNroTypePhy(reportDataIndicators, reportData);
            this.initReportDataIndicatorsDistanceNroPbo(reportDataIndicators, reportData);
            this.initReportDataIndicatorsDistanceNroSro(reportDataIndicators, reportData);
            this.initReportDataIndicatorsDistanceSroPbo(reportDataIndicators, reportData);

            return reportDataIndicators;
        });

        this.hasLinkBetweenGridsEnabled = await this._$companySettings.getCompanyHasLinkBetweenGridsEnabled(
            this.companyId,
        );
        this.controlDiffCount = this.controls.filter(
            (control) => control.faults1 !== control.faults2 || control.enabled1 !== control.enabled2,
        ).length;
        this.controlSameCount = this.controls.filter(
            (control) => control.faults1 === control.faults2 && control.enabled1 === control.enabled2,
        ).length;

        this._$timeout(() => (this.loading = false));
    }

    toggleGraphVisibility() {
        this.hasGraphsOpen = !this.hasGraphsOpen;
    }

    toggleAnomaliesVisibility() {
        this.hasAnomaliesOpen = !this.hasAnomaliesOpen;
    }

    async downloadCompareReport() {
        this._userMetricsProvider.downloadComparedReports(
            this.reportData1.deposit.report.id,
            this.reportData2.deposit.report.id,
        );

        this._LoaderService.open(`
        <p>${this._translate('shared.downloadingFile')}</p>
        <p>${this._translate('shared.timeConsuming')}</p>`);

        try {
            await this._RestProvider.downloadFromPath(
                `reports/${this.reportData1.deposit.id}/${this.reportData2.deposit.id}/compare/pdf`,
            );
        } catch (error) {
            this._$toasterService.error(error);
        }

        this._LoaderService.dismiss();
    }

    generateActivityFilter(activity) {
        return (control) => {
            switch (activity) {
                case 1:
                    return control.enabled1;
                case 2:
                    return control.enabled2;
                case -1:
                    return !control.enabled1;
                case -2:
                    return !control.enabled2;
                default:
                    return true;
            }
        };
    }

    filterSearch(search) {
        return (control) =>
            search === '' ||
            (control.idClient || '').toLowerCase().indexOf(search) > -1 ||
            (control.controlPoint || '').toLowerCase().indexOf(search) > -1 ||
            (control.description || '').toLowerCase().indexOf(search) > -1 ||
            (control.shortDescription || '').toLowerCase().indexOf(search) > -1;
    }

    filterControls() {
        this._$state.go('.', {
            ...this.filters,
            object: this.filters.object.join(','),
            severity: this.filters.severity.join(','),
            thematic: this.filters.thematic.join(','),
        });

        this.filteredControls = this.controls
            .filter(this._$filterService.genericMultiFilter(this.filters.severity, 'severity'))
            .filter(this._$filterService.genericMultiFilter(this.filters.object, 'object'))
            .filter(this._$filterService.genericMultiFilter(this.filters.thematic, 'thematic'))
            .filter(this.filterSearch(this.filters.search.toLowerCase()))
            .filter(this.generateActivityFilter(parseInt(this.filters.activity, 10)))
            .filter(this.resultFilter(this.filters.result));
    }

    displayError1(control) {
        if (!control.enabled1) {
            return this._translate('shared.inactiveControl');
        }

        if (control.invalid_count1 > 0) {
            return control.invalid_count1;
        }

        return control.error1 ? control.error1 : 0;
    }

    displayError2(control) {
        if (!control.enabled2) {
            return this._translate('shared.inactiveControl');
        }

        if (control.invalid_count2 > 0) {
            return control.invalid_count2;
        }

        return control.error2 ? control.error2 : 0;
    }

    resultFilter(key) {
        return (control) => {
            switch (key) {
                case 'success':
                    return control.faults1 === 0 || control.faults2 === 0;
                case 'error': {
                    const code = [-1, -2];

                    return code.indexOf(control.faults1) !== -1 || code.indexOf(control.faults2) !== -1;
                }
                case 'unused':
                    return control.faults1 === -3 || control.faults2 === -3;
                case 'fail':
                    return control.faults1 > 0 || control.faults2 > 0;
                case 'diff':
                    return control.faults1 !== control.faults2 || control.enabled1 !== control.enabled2;
                case 'same':
                    return control.faults1 === control.faults2 && control.enabled1 === control.enabled2;
                default:
                    return true;
            }
        };
    }

    findControlPoint(data, item) {
        return (
            data.find((control) => control.controlPoint === item.controlPoint && control.severity === item.severity) ??
            {}
        );
    }

    async getReportFromDeposit(depositId) {
        try {
            const deposit = (await this._depositApi.getByIdWithHttpInfo(depositId)).response.body;
            const statistics = (await this._reportApi.getReportStatisticsWithHttpInfo(deposit.report.id)).response.body;

            return this.formatReportData(deposit, statistics);
        } catch (error) {
            this._$toasterService(error);
        }
    }

    async openControlErrors(item) {
        if (item.invalid_count1 <= 0 && item.invalid_count2 <= 0) {
            return;
        }

        const depositId1 = this._$state.params.deposit1;
        const depositId2 = this._$state.params.deposit2;

        const version1 = this.reportData1.deposit.version;
        const version2 = this.reportData2.deposit.version;

        this.depositControls1 = this.reportData1.deposit.report.controls;
        this.depositControls2 = this.reportData2.deposit.report.controls;

        const control1 = this.findControlPoint(this.depositControls1, item) || {};
        const control2 = this.findControlPoint(this.depositControls2, item) || {};

        if (control1.invalid_count <= 0 && control2.invalid_count <= 0) {
            return;
        }

        const hasControlPointInError = Object.entries(control1).length === 0 || Object.entries(control2).length === 0;
        if (!hasControlPointInError) {
            try {
                const controlErrors = [
                    await this._reportProvider.getVersionControlJson(
                        depositId1,
                        control1.controlPoint,
                        control1.severity,
                    ),
                    await this._reportProvider.getVersionControlJson(
                        depositId2,
                        control2.controlPoint,
                        control2.severity,
                    ),
                ];
                const control = angular.isDefined(control1)
                    ? { ...control1, version1, version2 }
                    : { ...control2, version1, version2 };

                return this._$modalService.triggerControlErrorModal(control, controlErrors, undefined, false);
            } catch (error) {
                this._$toasterService.error(error);
            }
        }

        this.reportId =
            angular.isDefined(control1) && control1.faults >= 0
                ? this.reportData1.deposit.report.id
                : this.reportData2.deposit.report.id;
        const control = angular.isDefined(control1) && control1.faults >= 0 ? control1 : control2;

        return this._$modalService.triggerControlErrorModal(
            control,
            this._$reportService.getErrors(this.reportId, control.controlPoint, control.severity),
        );
    }

    showSameOrDiff(value) {
        this.hasAnomaliesOpen = true;
        this.filters.result = value;
        const resultFilter = this.filterOptions.find((item) => item.name === 'results');
        this._$scope.$broadcast('forceFilterRender', {
            filter: resultFilter,
            saveChanges: true,
            openDropdown: false,
        });

        // $location.hash('id') with $anchorScroll don't work here...
        this._$timeout(() => {
            const element = angular.element('#anomalies');
            angular.element('html').animate({ scrollTop: element.offset().top }, 'slow');
        }, 0);
    }

    toggleSubLine(controlPoint) {
        if (this.controlShown === controlPoint) {
            this.controlShown = null;
        } else {
            this.controlShown = controlPoint;
        }
    }

    formatReportData(deposit, statistics) {
        const controls = this._$reportService.getControls(deposit.report.controls, deposit.gridControl).controls;
        deposit.report.controls = angular.copy(controls);

        return { controls: controls, deposit: deposit, statistics: statistics };
    }

    /**
     * Fetch controls
     */
    prepareControls() {
        this.controls = this._$reportService.getComparisonReports(
            this.reportData1.deposit.report,
            this.reportData2.deposit.report,
        );
    }

    /**
     * Get filter list
     */
    getSeverityList(controlGrid = []) {
        return controlGrid.reduce((acc, { severity }) => {
            if (!acc.find((item) => item.key === severity)) {
                acc.push({
                    key: severity,
                    value: this._humanizeSeverity(severity),
                });
            }

            return acc;
        }, []);
    }

    getObjectList(controlGrid = []) {
        return controlGrid.reduce((acc, { object }) => {
            if (!acc.find((item) => item.key === object)) {
                acc.push({
                    key: object,
                    value: object,
                });
            }

            return acc;
        }, []);
    }

    getThematicList(controlGrid = []) {
        return controlGrid.reduce((acc, { thematic }) => {
            if (!acc.find((item) => item.key === thematic)) {
                acc.push({
                    key: thematic,
                    value: this._humanizeThematic(thematic),
                });
            }

            return acc;
        }, []);
    }

    cancelFilters() {
        this.setFilters({}, false);
    }

    removeFilter(filterName, initialValue) {
        this.filters[filterName] = initialValue;
        this._$state.go('.', this.filters);
    }

    removeSearch() {
        this.filters.search = '';
        this._$state.go('.', this.filters);
    }

    downloadCompareExcel() {
        try {
            const deposits = this.reportDatas.map(({ deposit }) => deposit);
            const controls = this.reportDatas.map(({ controls }) => controls);
            let statistics = this.reportDatas.map(({ statistics }) => statistics);

            statistics.forEach((stat) => (stat.indicators.nbControls = stat.controls.global));
            controls.forEach((controlPoint, index) => (statistics[index].indicators.controlPoints = controlPoint));
            statistics = statistics.map(({ indicators }, index) => {
                indicators.reportName = deposits[index].name;

                return indicators;
            });

            const names = {
                nameOrigin: this._$controlStatisticsService.makeNameStatistics(deposits[0].createdAt),
                nameToCompare: this._$controlStatisticsService.makeNameStatistics(deposits[1].createdAt),
            };

            const tableSynthesis = this._$reportService.preparationSynthesisForExcel(deposits);
            const tableAnomalies = this._$reportService.preparationAnomaliesForExcel(controls, names);
            const tablesIndicators = this._$controlStatisticsService.preparationIndicatorsForExcel(statistics);

            const fileName = [
                this._translate('shared.report', { COUNT: 1 }),
                this._$controlStatisticsService.makeDateFileName(deposits[0].createdAt),
                this._$controlStatisticsService.makeDateFileName(deposits[1].createdAt),
            ].join('_');

            return this._$excelService.exportReportXLSX(
                [tableSynthesis, tableAnomalies],
                tablesIndicators,
                this.company.settings,
                fileName,
                'deposit.downloadReportSuccess',
                { hasNbRecordsPerTable: false },
            );
        } catch {
            this._$toasterService.error(this._translate('toaster.downloadError'));
        }
    }
}

angular.module('dotic').component('reportComparePage', {
    controller: ReportComparePage,
    templateUrl: template,
});
