import template from './report-summary.compoent.html';

class ReportSummaryComponent {
    report = {};
}

angular.module('dotic').component('reportSummary', {
    bindings: {
        report: '<',
    },
    controller: ReportSummaryComponent,
    templateUrl: template,
});
