import { IndicatorChartService } from '../../../services/indicator/indicator-chart.service';
import { ReportsApi } from '../../../../sdk/connect-control-api-v1/src';
import template from './anct-verifications-pdf.component.html';

class ANCTVerificationsPdfComponent {
    ANCTControls = [];
    ANCTFullDataStructure = {};
    barChartOptions = {
        responsive: true,
        tooltips: {
            enabled: false,
        },
        scales: null,
    };
    controlPointChartData = {};
    controlResultSynthesisChartData = {};
    controlSynthesis = {};
    groupedANCTClusteredData = [];
    loading = true;
    standaloneANCTClusteredData = [];

    constructor(
        $apiClientService,
        $chartService,
        $filter,
        $indicatorControlPointService,
        $indicatorNumberControlService,
        $location,
        $pdfReportService,
        $reportService,
        $scope,
        $segmentsService,
        $stateParams,
        $state,
        $timeout,
        $toasterService,
        indicatorChartService,
    ) {
        this._$chartService = $chartService;
        this._$indicatorControlPointService = $indicatorControlPointService;
        this._$indicatorNumberControlService = $indicatorNumberControlService;
        this._$location = $location;
        this._$pdfReportService = $pdfReportService;
        this._$reportService = $reportService;
        this._$scope = $scope;
        this._$segmentsService = $segmentsService;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._indicatorChartService = indicatorChartService;
        this._reportApi = new ReportsApi($apiClientService.client);
        this._translate = $filter('translate');
    }

    async $onInit() {
        this.loading = true;

        this.barChartOptions.scales = this._$chartService.getDefaultScaleConfiguration(true, {
            fontSize: 12,
            minRotation: 8,
        });

        try {
            const ANCTReportSynthesis = (await this._reportApi.getByIdWithHttpInfo(this._$stateParams.id)).response
                .body;

            this.controlSynthesis = ANCTReportSynthesis.controls;
            this.ANCTFullDataStructure = this._indicatorChartService.generateANCTFullDataStructure(
                ANCTReportSynthesis.indicators.ANCT,
            );

            const ANCTClusteredData = IndicatorChartService.getClusteredData(this.ANCTFullDataStructure).sort(
                (a, b) => (a.options.ANCTCorrespondenceNumber ?? 0) - (b.options.ANCTCorrespondenceNumber ?? 0),
            );

            this.groupedANCTClusteredData = ANCTClusteredData.filter(
                (indicatorClusteredData) => indicatorClusteredData.options?.ANCTCorrespondenceNumber === 13,
            );
            this.standaloneANCTClusteredData = ANCTClusteredData.filter(
                (indicatorClusteredData) => indicatorClusteredData.options?.ANCTCorrespondenceNumber !== 13,
            );

            this.report = await this.getReport(this._$stateParams.id);

            this.ANCTControls = this._$reportService.getControls(
                this.report.controls,
                this.report.deposit.gridControl,
            ).controls;

            const controlPointSummary = this._$indicatorControlPointService.computeChartData(this.ANCTControls);
            this.controlPointChartData = this._$indicatorControlPointService.loadGlobalControlChart(
                controlPointSummary,
                null,
                this.ANCTControls.length,
                0,
                false,
                0,
            );

            this.controlResultSynthesisChartData = this.getControlResultSynthesisData(this.ANCTControls);
        } catch (error) {
            this._$toasterService.error(error);
        }

        this._$scope.$emit('keepPreviousNavigation', {
            allowedPreviousKeys: ['anctVerification'],
            defaultPrevious: {
                title: `${this._translate('shared.report', { COUNT: 1 })} ${this.report?.deposit.name ?? ''}`,
                href: this._$state.href('app.anctVerificationsDetail', { id: this._$stateParams.id }),
                key: 'anctVerification',
            },
            newPage: [
                {
                    key: 'anctVerificationPdf',
                    title: this._translate('pdf.overview'),
                    href: this._$location.path(),
                },
            ],
        });

        this._$timeout(() => {
            this.loading = false;
        });

        // The digest cycle implied by `this.loading = false` needs to be resolved for this to work properly
        this._$timeout(() => {
            this._$pdfReportService.definePageNumbers();
        });
    }

    async getReport(reportId) {
        const report = (await this._reportApi.getDetailsWithHttpInfo(reportId, { isANCT: true })).response.body;
        report.deposit.networkSegments = this._$segmentsService.setNetworkSegments(report.deposit.networkSegments);

        return report;
    }

    // This part will be refactored in another PR
    getControlResultSynthesisData(controls) {
        const nbControlData = {
            minorAnomalyNb: 0,
            majorAnomalyNb: 0,
            blockingAnomalyNb: 0,
            minorSuccessNb: 0,
            majorSuccessNb: 0,
            blockingSuccessNb: 0,
            numberTotalControl: 0,
            anomalyNumberTotalCount: 0,
            successNumberTotalCount: 0,
        };

        controls.forEach((control) => {
            control.total_count = this.parseValue(control.total_count);
            control.invalid_count = this.parseValue(control.invalid_count);
            control.valid_count = this.parseValue(control.valid_count);

            nbControlData.numberTotalControl += control.total_count;
            nbControlData.anomalyNumberTotalCount += control.invalid_count;
            nbControlData.successNumberTotalCount += control.valid_count;

            if (control.faults || control.invalid_count) {
                this._$indicatorNumberControlService.increaseCountingNbControls(
                    nbControlData,
                    control,
                    false,
                    'minorAnomalyNb',
                    'minorSuccessNb',
                    'majorAnomalyNb',
                    'majorSuccessNb',
                    'blockingAnomalyNb',
                    'blockingSuccessNb',
                );

                return;
            }

            if (!control.faults || !control.invalid_count) {
                this._$indicatorNumberControlService.increaseCountingNbControls(
                    nbControlData,
                    control,
                    false,
                    'minorAnomalyNb',
                    'minorSuccessNb',
                    'majorAnomalyNb',
                    'majorSuccessNb',
                    'blockingAnomalyNb',
                    'blockingSuccessNb',
                );
            }
        });

        return this._$indicatorNumberControlService.loadAnomalyVsSuccessChart(nbControlData, [], false);
    }

    parseValue(rawValue) {
        return angular.isNumber(rawValue) && !isNaN(rawValue) ? rawValue : 0;
    }
}

angular.module('dotic').component('anctVerificationsPdfComponent', {
    controller: ANCTVerificationsPdfComponent,
    templateUrl: template,
});
