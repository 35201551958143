import { IndicatorFttxDataEvolutionService } from '../../../../services/indicator-fttx-data-evolution.service';
import template from './differences-details.component.html';

const TranslationKeyEnum = {
    ADDRESS_COUNT: 'addressCount',
    COUNT_GEOM: 'countGeom',
    DATA_GEOM: 'data_geom',
    DATA_SAISIE: 'data_saisie',
    HOUSING_COUNT: 'housingCount',
    LINEAR_METER: 'linearMeter',
    LOCAL_COUNT: 'localCount',
    NB_CHEMS: 'nbChems',
    NB_GC: 'nbGc',
    NULL_HEXACLE_COUNT: 'nullHexacleCount',
    PERCENT: 'percent',
    VALID_HEXACLE_COUNT: 'validHexacleCount',
    VALID_SUP_10_HEXACLE_COUNT: 'validSup10charHexacleCount',
};

class DifferencesDetailsComponent {
    columns = [];
    defaultSort = 'type';
    nbDifferencesColumnName = IndicatorFttxDataEvolutionService.nbDifferencesColumnName;
    onDownloadClick = null;
    unity = null;
    values = [];

    constructor($filter, $sce, $scope) {
        this._translate = $filter('translate');

        // Monkey patch needed to use a custom tooltip
        $scope.getTooltip = $sce.trustAsHtml(this.getTooltip());
    }

    $onChanges() {
        if (!this.values || !angular.isObject(this.values[0]?.nbDifferences)) {
            return;
        }

        this.setUpSeveralNbDifferences();
    }

    getClasses(column, item) {
        if (!column.name.includes('nbDifferences')) {
            return '';
        }

        const classes = 'f-700 f-14';
        const value = angular.isObject(item.nbDifferences)
            ? item.nbDifferences[column.name.split('-')[1]]
            : item.nbDifferences;

        if (!value) {
            return classes;
        }

        return classes + (value < 0 ? ' c-red' : ' c-green');
    }

    getTooltip() {
        return `
            <span class="f-600">${this._translate('shared.operationCarriedOut')} :</span><br/>
            ${this._translate('deposit.comparison')}
            <span class="f-600 f-18">-</span><br/>
            ${this._translate('deposit.inventoryDepositReference')}<br/>
        `;
    }

    getTranslation(key) {
        switch (key) {
            case TranslationKeyEnum.ADDRESS_COUNT:
                return this._translate('shared.address');
            case TranslationKeyEnum.COUNT_GEOM:
            case TranslationKeyEnum.DATA_GEOM:
                return this._translate('reportCompare.lengthCarto');
            case TranslationKeyEnum.DATA_SAISIE:
                return this._translate('reportCompare.realLength');
            case TranslationKeyEnum.HOUSING_COUNT:
                return this._translate('shared.house');
            case TranslationKeyEnum.LINEAR_METER:
                return this._translate('shared.linearMeter');
            case TranslationKeyEnum.LOCAL_COUNT:
                return this._translate('shared.local');
            case TranslationKeyEnum.NB_CHEMS:
                return `Nb. ${this._translate('shared.pathway').toLowerCase()}`;
            case TranslationKeyEnum.NB_GC:
                return this._translate('shared.nbReceptionPointsBuilt');
            case TranslationKeyEnum.NULL_HEXACLE_COUNT:
                return this._translate('shared.nullHexacleCount');
            case TranslationKeyEnum.PERCENT:
                return this._translate('shared.percentage', { COUNT: 1 });
            case TranslationKeyEnum.VALID_HEXACLE_COUNT:
                return this._translate('shared.specificCharNumberHexacleCount', { COMPARATOR: 'less', COUNT: 10 });
            case TranslationKeyEnum.VALID_SUP_10_HEXACLE_COUNT:
                return this._translate('shared.specificCharNumberHexacleCount', { COMPARATOR: 'more', COUNT: 10 });
            default:
                return key;
        }
    }

    onDownload() {
        if (!this.onDownloadClick) {
            throw new Error('No download function found !');
        }

        this.onDownloadClick();
    }

    setUpSeveralNbDifferences() {
        const additionalColumns = Object.keys(this.values[0].nbDifferences).map((key) => {
            return {
                name: `nbDifferences-${key}`,
                text: `${this._translate('shared.difference')} - ${this.getTranslation(key)}`,
            };
        });
        this.columns = this.columns.toSpliced(this.columns.indexOf('nbDifferences'), 1, ...additionalColumns);
    }
}

angular.module('dotic').component('differencesDetails', {
    controller: DifferencesDetailsComponent,
    templateUrl: template,
    bindings: {
        columns: '<',
        defaultSort: '@?',
        onDownloadClick: '&?',
        unity: '@?',
        values: '<',
    },
});
