import comparaisonTemplate from './comparison.page.html';
import comparisonDepositTemplate from './deposit/comparisonDeposit.page.html';
import comparisonGridTemplate from './grid/comparisonGrid.page.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.comparisonPage', {
            url: '/comparison?controlStat&fillStat',
            params: {
                controlStat: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                fillStat: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
            },
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
            templateUrl: comparaisonTemplate,
            controller: 'ComparisonController as $ctrl',
        })
        .state('app.comparisonDepositPage', {
            url: '/comparison/deposit/?deposit',
            params: {
                deposit: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
            },
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'user', 'extendedUser'],
            },
            templateUrl: comparisonDepositTemplate,
            controller: 'ComparisonDepositController as $ctrl',
        })
        .state('app.comparisonGridPage', {
            url: '/comparison/grid?controlGrid&fillGrid',
            params: {
                controlGrid: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                fillGrid: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
            },
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
            templateUrl: comparisonGridTemplate,
            controller: 'ComparisonGridController as $ctrl',
        });
});
