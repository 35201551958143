import template from './favorite-button.component.html';

class FavoriteButtonComponent {
    additionalClasses = '';
    isActive = false;
    tooltipMessage = '';
    onClick = null;

    onButtonClick(value) {
        if (!angular.isFunction(this.onClick)) {
            return;
        }

        this.onClick({ value: value });
    }
}

angular.module('dotic').component('favoriteButton', {
    controller: FavoriteButtonComponent,
    templateUrl: template,
    bindings: {
        additionalClasses: '@?',
        isActive: '<',
        tooltipMessage: '<?',
        onClick: '&',
    },
});
