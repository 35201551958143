import template from './cable-length-by-owner-and-manager-indicator.component.html';

class CableLengthByOwnerAndManagerIndicatorComponent {
    data = [];
    dataStructure = {
        data: [],
        iconOptions: [],
        tableChartData: { datasets: [], labels: [] },
        tableData: { columns: [], title: '' },
    };
    parentRef = {};

    constructor(indicatorR00145Service) {
        this._indicatorR00145Service = indicatorR00145Service;
    }

    $onInit() {
        this.dataStructure = this._indicatorR00145Service.generateDataStructure(this.data);
    }
}

angular.module('dotic').component('cableLengthByOwnerAndManagerIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: CableLengthByOwnerAndManagerIndicatorComponent,
    templateUrl: template,
});
