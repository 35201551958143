// Imports
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../assets/images/help/agregation/agregationListDeposit.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../assets/images/help/agregation/agregationModalGenerate.jpg");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../../assets/images/help/agregation/agregationVisualisation.jpg");
var ___HTML_LOADER_IMPORT_3___ = require("../../../../../assets/images/help/agregation/agregationStatus.jpg");
// Module
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 9.1.1 / {{ 'shared.aggregationListDeposit' | translate }} : </h3> <p class=\"help-Doc_Alerte help-Doc-Box-Alerte\"> <span>{{ 'shared.aggregationManagementWarning' | translate }}.</span> </p> <ul class=\"help-Doc__ul\"> <li class=\"help-Doc__subTitre4\"> {{ 'shared.aggregationListDeposit' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_0___ + "\" alt=\"{{ 'shared.aggregationListDeposit' | translate }}\"/> </li> </ul> <ul class=\"help-Doc__ul\"> <li class=\"help-Doc__subTitre4\"> {{ 'shared.aggregationModalGenerate' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_1___ + "\" alt=\"{{ 'shared.aggregationModalGenerate' | translate }}\"/> </li> </ul> <ul class=\"help-Doc__ul\"> <li class=\"help-Doc__subTitre4\"> {{ 'shared.aggregationVisualisation' | translate }} : <p class=\"help-Doc_Alerte-interne help-Doc-Box-Alerte-interne\"> <span> ! {{ 'shared.aggregationGenerateWarning' | translate }} . <br/> </span> </p> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_2___ + "\" alt=\"{{ 'shared.aggregationVisualisation' | translate }}\"/> </li> </ul> <ul class=\"help-Doc__ul\"> <li class=\"help-Doc__subTitre4\"> {{ 'shared.aggregationStatus' | translate }} : <img class=\"help-Doc__img help-Doc__W80\" src=\"" + ___HTML_LOADER_IMPORT_3___ + "\" alt=\"{{ 'shared.aggregationStatus' | translate }}\"/> </li> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/agregation/agregationInfo.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;