import FttxDataEvolutionThematicIndicatorsComponent from '../fttx-data-evolution-view-indicators-thematic.component';
import template from './fttx-data-evolution-deployment-progress.component.html';

class FttxDataEvolutionDeploymentProgressIndicatorsComponent extends FttxDataEvolutionThematicIndicatorsComponent {
    constructor($favoriteIndicatorsService, indicatorChartService) {
        super($favoriteIndicatorsService, indicatorChartService);
    }
}

angular.module('dotic').component('fttxDataEvolutionDeploymentProgressIndicatorsComponent', {
    controller: FttxDataEvolutionDeploymentProgressIndicatorsComponent,
    templateUrl: template,
    bindings: FttxDataEvolutionThematicIndicatorsComponent.defaultBindings,
});
