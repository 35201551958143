import { ThematicEnum } from '../models/grace.model';

function getThematicI18nKey(thematic, translate) {
    switch (thematic) {
        case ThematicEnum.ANCT_VERIFICATIONS:
            return translate('thematic.anctVerifications');
        case ThematicEnum.COHERENCE:
            return translate('thematic.coherence');
        case ThematicEnum.DATA_AND_MODEL:
            return translate('thematic.dataAndModel');
        case ThematicEnum.FILLING:
            return translate('thematic.filling');
        case ThematicEnum.FORMAT_AND_GRACE_COHERENCE:
            return translate('thematic.formatAndGraceCoherence');
        case ThematicEnum.GEOMETRY:
            return translate('thematic.geometry');
        case ThematicEnum.GEOMETRY_AND_TOPOLOGY:
            return translate('thematic.geometryAndTopology');
        case ThematicEnum.JOB:
            return translate('thematic.job');
        case ThematicEnum.JOB_COHERENCE:
            return translate('thematic.jobCoherence');
        case ThematicEnum.OPERATOR_COMPATIBILITY:
            return translate('thematic.operatorCompatibility');
        case ThematicEnum.REGULATORY:
            return translate('thematic.regulatory');
        case ThematicEnum.SI_ORANGE_COMPATIBILITY:
            return translate('thematic.SIOrangeCompatibility');
        case ThematicEnum.STRUCTURE:
            return translate('thematic.structure');
        case ThematicEnum.TOPOLOGY:
            return translate('thematic.topology');
        default:
            throw `Error: thematic ${thematic} doesn't exist`;
    }
}

angular.module('dotic').filter('humanizeThematic', ($filter) => {
    return (value) => {
        try {
            return getThematicI18nKey(value, $filter('translate'));
        } catch {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    };
});
