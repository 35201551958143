import EnvironmentSettingsService from '../../services/environment-settings.service';
import menuHelpTemplate from '../menu/menuHelp.html';
import menuTemplate from '../menu/menu.html';
import template from './content.html';
import topnavbarTemplate from './topnavbar.html';

class IndexComponent {
    constructor(
        $auth,
        $http,
        $authorizationService,
        $document,
        $filter,
        $location,
        $log,
        $notificationService,
        $rootScope,
        $scope,
        $securityService,
        $state,
        $timeout,
        $toasterService,
        $transitions,
        $userService,
        $window,
        authenticationProvider,
        companyProvider,
        controlConfigurationProvider,
        historyProvider,
        projectProvider,
        userMetricsProvider,
        userProvider,
    ) {
        this._$auth = $auth;
        this._$http = $http;
        this._$timeout = $timeout;
        this._$document = $document;
        this._$log = $log;
        this._$state = $state;
        this._$notificationService = $notificationService;
        this._$toasterService = $toasterService;
        this._$window = $window;
        this._$userService = $userService;
        this._$authorizationService = $authorizationService;
        this._translate = $filter('translate');

        this._userProvider = userProvider;
        this._authenticationProvider = authenticationProvider;
        this._controlConfigurationProvider = controlConfigurationProvider;
        this._historyProvider = historyProvider;
        this._projectProvider = projectProvider;
        this._userMetricsProvider = userMetricsProvider;

        this.isOrange = EnvironmentSettingsService.isOrange;
        this.isAllowed = $authorizationService.isAllowed;
        this.user = {};
        this.stateCurrentName = $state.current.name;
        this.notifications = {
            list: [],
            projects: [],
            controlConfigurations: [],
        };
        this.projectList = [];

        this.appLoaded = false;
        this.notificationsLoaded = false;
        this.timeout = 0;
        this.helpMenu = this.stateCurrentName.substr(0, 9) === 'app.help.';

        this.menuTemplate = menuTemplate;
        this.menuHelpTemplate = menuHelpTemplate;
        this.topnavbarTemplate = topnavbarTemplate;

        $transitions.onStart({ entering: 'app.help.**' }, () => {
            this.helpMenu = true;
        });

        $transitions.onSuccess({ exiting: 'app.help' }, () => {
            this.helpMenu = false;
        });
        $scope.$on('updateNavigation', (evt, args) => {
            evt.stopPropagation();

            const { newPage } = args;
            this.pageTitle = newPage;
            $document[0].title = this.getPageTitleString(newPage);
        });

        $scope.$on('keepPreviousNavigation', (evt, args) => {
            evt.stopPropagation();

            const { newPage, defaultPrevious, allowedPreviousKeys } = args;

            let newPageTitle = [];
            if (
                angular.isDefined(this.pageTitle) &&
                angular.isArray(this.pageTitle) &&
                this.pageTitle.length > 0 &&
                allowedPreviousKeys.includes(this.pageTitle[this.pageTitle.length - 1].key)
            ) {
                const keys = this.pageTitle.map((p) => p.key);
                if (keys.includes(newPage[0].key)) {
                    const indexOfExistingKey = keys.indexOf(newPage[0].key);
                    this.pageTitle.splice(indexOfExistingKey);
                }

                newPageTitle = newPageTitle.concat(this.pageTitle, newPage);
            } else {
                newPageTitle = newPageTitle.concat(defaultPrevious, newPage);
            }

            this.pageTitle = newPageTitle;
            $document[0].title = this.getPageTitleString(newPageTitle);
        });

        $scope.$on('openBulk', (evt, args) => {
            evt.stopPropagation();

            const { data } = args;

            this.openBulk(data);
        });

        $scope.$on('updateNavigationUrl', (evt, args) => {
            evt.stopPropagation();

            let href = $location.path() + $window.location.search;
            if (args?.href) {
                href = args.href;
            }

            if (angular.isDefined(this.pageTitle) && angular.isArray(this.pageTitle) && this.pageTitle.length > 0) {
                this.pageTitle[this.pageTitle.length - 1].href = href;
            }
        });
    }

    $onInit() {
        this.appLoaded = false;
        this.notificationsLoaded = false;
        if (this.isLogout()) {
            this._$timeout(() => {
                this.appLoaded = true;
            });
        } else {
            this.checkExpiration()
                .then(() => this.loadData())
                .then(() =>
                    this._$timeout(() => {
                        this.appLoaded = true;
                    }),
                )
                .then(() => this.loadNotifications())
                .then(() =>
                    this._$timeout(() => {
                        this.notificationsLoaded = true;
                    }),
                )
                .catch(this._$toasterService.error);
        }
    }

    getPageTitleString(pageTitle = []) {
        if (pageTitle.length < 1) {
            return 'ConnectControl';
        }

        return `${pageTitle[pageTitle.length - 1].title} - ConnectControl`;
    }

    isLogout() {
        return this._$state.is('app.logout');
    }

    async logout(params = {}) {
        await this._$auth.logout();

        if (APP_SETTINGS.OPENID_LABEL) {
            // in background, notify webserver of logout to handle auth0 logout
            this._$http({ method: 'GET', url: '/auth0/logout' }).catch(this._$log.error);
        }

        return this._$state.go('login', params);
    }

    async loadData() {
        try {
            const payload = this._$auth.getPayload();
            const me = await this._userProvider.me();

            if (!me) {
                return this.logout();
            }

            if (me.settings.interface.isMenuOpen === true) {
                this._$document[0].body.classList.add('menu--no-anim');
                this._$document[0].body.classList.add('menu--opened');
                this._$timeout(() => {
                    this._$document[0].body.classList.remove('menu--no-anim');
                }, 16);
            }

            this.user = me;
            this.connectedUser = me;
            this.isSubContractor = this.isSubCompany(me);

            // Check if permissions have changed
            if (!this._$authorizationService.checkPermissions(me.permissions, payload.permissions)) {
                await this.updateToken();
            }

            // Get company list
            if (me.role !== 'superAdmin' && me.companies.length > 1) {
                this.companyBond = me.companies.filter((company) => company.id !== me.creditCompany.id);
            }

            // Load userFilters
            localStorage.setItem('CC_FILTERS', angular.toJson(me.userFilters));

            this._$userService.setConnectedUser(this.user);

            // Redirect new SSO user to a welcome page
            if (payload.userRole !== 'superAdmin' && !me.companies.length) {
                return this._$state.go('welcome');
            }

            this.connectedCompany = me.companies.find((company) => company.id === payload.company);
            // Check company list
            if (me.role !== 'superAdmin' && !this.connectedCompany) {
                await this.updateToken();

                return this.loadData();
            }

            this._$userService.setConnectedCompany(this.connectedCompany);
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    isSubCompany(user) {
        if (!user.creditCompany || ['user', 'extendedUser'].includes(user.role)) {
            return false;
        }

        return user.creditCompany.settings?.feature?.isSubCompany;
    }

    async loadNotifications() {
        try {
            const [history, projects, controlConfigurations] = await Promise.all([
                this._historyProvider.getAll({
                    page: 1,
                    limit: 5,
                    type: 'NOTIFICATION',
                }),
                this._projectProvider.getAll(),
                this._controlConfigurationProvider.getAll(),
            ]);

            this.notifications = {
                list: history,
                controlConfigurations: controlConfigurations,
            };
            this.projectsList = projects.filter(({ isActive }) => isActive);
            this.notificationLength = history.filter((notification) => !notification.hasRead).length;
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    showMenu() {
        this._$document[0].body.classList.add('menu--show');
        this._$timeout.cancel(this.timeout);
    }

    hideMenu() {
        this.timeout = this._$timeout(() => {
            this._$document[0].body.classList.remove('menu--show');
        }, 100);
    }

    forceHideMenu() {
        this._$document[0].body.classList.remove('menu--show');
    }

    toggleMenu() {
        this._$document[0].body.classList.toggle('menu--opened');
        this._$document[0].body.classList.remove('menu--show');

        const isOpened = this._$document[0].body.classList.contains('menu--opened');
        this._userProvider.updateSetting(this.user, 'interface', 'isMenuOpen', isOpened);
    }

    hasState(states, params) {
        if (angular.isUndefined(params)) {
            return states.some((state) => this._$state.includes(`app.${state}`));
        }

        return states.some((state) => this._$state.includes(`app.${state}`)) && this.hasParams(params);
    }

    hasParams(params) {
        const hasParams = {};

        Object.entries(params).forEach(([name, value]) => {
            hasParams[name] =
                Object.prototype.hasOwnProperty.call(this._$state.params, name) && this._$state.params[name] === value;
        });

        return Object.values(hasParams).includes(false) === false;
    }

    isActive(...pages) {
        return pages.some((page) => {
            switch (page) {
                case 'dashboard':
                    return this.hasState(['dashboard', 'dashboardAdmin', 'dashboardSuperAdmin', 'dashboardUser']);
                case 'superAdminFillGridTemplate':
                    return this.hasState(['gridFillList']);
                case 'superAdminFillGridCompanies':
                    return this.hasState(['gridFillListCompanies']);
                case 'superAdminControlGridTemplate':
                    return this.hasState(['gridControlList']);
                case 'superAdminControlGridCompanies':
                    return this.hasState(['gridControlListCompanies']);
                case 'superAdminUsers':
                    return this.hasState(['userList']);
                case 'superAdminCompanies':
                    return this.hasState([
                        'companyList',
                        'companyCreate',
                        'companyEdit',
                        'companyDuplicate',
                        'companyDetail',
                    ]);
                case 'superAdminStorageBases':
                    return this.hasState(['serverList']);
                case 'superAdminUserMetrics':
                    return this.hasState(['userMetrics', 'userMetricsDetails']);
                case 'adminDashboardExtended':
                    return this.hasState(['dashboardExtended']);
                case 'adminFttxDataEvolution':
                    return this.hasState(['fttxDataEvolution', 'fttxDataEvolutionView']);
                case 'adminJob':
                    return this.hasState(['depositList']);
                case 'adminJobControl':
                    return this.hasState(['depositJob-control']);
                case 'adminJobLoad':
                    return this.hasState(['depositJob-load']);
                case 'adminProject':
                    return this.hasState([
                        'projectList',
                        'projectDetail',
                        'projectViewGridControl',
                        'projectViewGridFill',
                    ]);
                case 'adminControlConfiguration':
                    return this.hasState(['controlConfigurationList']);
                case 'adminFillGrid':
                    return this.hasState(['gridFillAdminList', 'gridFillAdminView', 'gridFillAdminEdit']);
                case 'adminControlGrid':
                    return this.hasState(['gridControlAdminList', 'gridControlAdminView', 'gridControlAdminEdit']);
                case 'adminInterventionGrid':
                    return this.hasState([
                        'interventionGridCreate',
                        'interventionGridEdit',
                        'interventionGridView',
                        'interventionGridsList',
                    ]);
                case 'adminPostProcessing':
                    return this.hasState(['postProcessing']);
                case 'adminUsers':
                    return this.hasState(['companyDetail']);
                case 'adminBulk':
                    return this.hasState(['bulk']);
                case 'userBulk':
                    return this.hasState(['bulk']);
                case 'extendedUserBulk':
                    return this.hasState(['bulk']);
                case 'userReport':
                    return this.hasState(['depositList', 'reportDetail', 'reportCompare']);
                case 'userJob':
                    return this.hasState(['depositList']);
                case 'userJobControl':
                    return this.hasState(['depositJob-control']);
                case 'userJobLoad':
                    return this.hasState(['depositJob-load']);
                case 'userProject':
                    return this.hasState([
                        'projectListUser',
                        'projectDetail',
                        'projectViewGridControl',
                        'projectViewGridFill',
                    ]);
                case 'extendedUserReport':
                    return this.hasState(['depositList', 'reportDetail', 'reportCompare']);
                case 'extendedUserJob':
                    return this.hasState(['depositList']);
                case 'extendedUserJobControl':
                    return this.hasState(['depositJob-control']);
                case 'extendedUserJobLoad':
                    return this.hasState(['depositJob-load']);
                case 'extendedUserProject':
                    return this.hasState([
                        'projectListUser',
                        'projectDetail',
                        'projectViewGridControl',
                        'projectViewGridFill',
                    ]);
                case 'adminControlStatistics':
                    return this.hasState(['statisticsHome']);
                case 'adminFillingStatistics':
                    return this.hasState(['statisticsFillHome']);
                case 'adminDeliveryZone':
                    return this.hasState(['deliveryZoneList']);
                case 'sendToExternalPlatform':
                    return this.hasState(['sendToExternalPlatform']);
                default:
                    return false;
            }
        });
    }

    checkExpiration() {
        const token = this._$auth.getToken();
        const now = moment().unix();
        const payload = this._$auth.getPayload();

        if (!payload) {
            // Logout with an error message for the V3 login page
            return this.logout({ redirectUrl: this._$window.location, info: 'message.sessionExpired' });
        }

        const isValidDataPayload = token && payload.userId;

        // @orange Don't check the expiration for client on orange network with unlimited token
        // This fix would be removed later with a better token refresh method
        const isValidExpirationPayload = this.isOrange() ? true : payload.exp && now <= payload.exp;

        if (!isValidDataPayload || !isValidExpirationPayload) {
            // Logout with an error message for the V3 login page
            return this.logout({ redirectUrl: this._$window.location, info: 'message.sessionExpired' });
        }

        return Promise.resolve();
    }

    submitDeposit() {
        if (this.connectedCompany.isSuspended) {
            return null;
        }
        //To remove BULK CHOOSE
        else if (this.connectedCompany) {
            return this.openBulk();
        } else {
            return null;
        }
    }

    openBulk() {
        return this._$state.go('app.bulk');
    }

    setHistoryHasRead(notificationId) {
        const userId = this._$auth.getPayload().userId;

        this._$timeout(() => {
            this.notifications.list = this.notifications.list.map((notification) => {
                if (notification.id === notificationId && !notification.hasRead) {
                    notification.hasRead = true;
                }

                return notification;
            });

            this.notificationLength = this.notifications.list.filter((notification) => !notification.hasRead).length;
        });

        this._userProvider.setHistoryHasRead(userId, { histories: [notificationId] }).then(() => this.loadData());
    }

    formatNotification(notification) {
        return this._$notificationService.format(notification, {
            projectList: this.projectsList,
            controlConfigurationList: this.notifications.controlConfigurations,
        });
    }

    toggleMenuContainer(type, $event) {
        $event.stopPropagation();
        $event.preventDefault();

        const key = `isMenu${type}Open`;
        const user = this.connectedUser;
        if (!user) {
            return;
        }

        user.settings.interface[key] = !user.settings.interface[key];

        return this._userProvider.updateSetting(user, 'interface', key, user.settings.interface[key]);
    }

    async updateToken() {
        try {
            const response = await this._authenticationProvider.updateToken();
            this._$auth.setToken(response.token);
        } catch {
            // Silent
        }
    }

    async changeCompany(companyId) {
        try {
            await this._userMetricsProvider.changeCompany(companyId);

            const authChangeCompany = await this._authenticationProvider.changeCompany(companyId);
            this._$auth.setToken(authChangeCompany.token);
            this._$state.go('app.depositJob-control', {}, { reload: true });
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    logoutMetric() {
        this._userMetricsProvider.logout();
    }

    notificationsMetric() {
        this._userMetricsProvider.openNotifications();
    }
}

angular.module('dotic').component('index', {
    controller: IndexComponent,
    templateUrl: template,
});
