import { group as radashGroup, sum as radashSum, unique as radashUnique } from 'radash';
import { ChartService } from '../../chart.service';
import { ColorService } from '../../color.service';
import { IndicatorService } from '../indicator.service';

const KeyEnum = {
    CABLE_LENGTH: 'cableLength',
    CABLE_LOGICAL_TYPE: 'cableLogicalType',
    CABLE_PHYSICAL_TYPE: 'cablePhysicalType',
    CABLE_TYPE: 'cableType',
};

// Linked to cable-length-by-type indicator.
class IndicatorR00132Service {
    static ANCTCorrespondenceNumber = 28;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    generateDataStructure(data) {
        const formattedData = IndicatorService.getFormattedKilometerData(data, [KeyEnum.CABLE_LENGTH]);

        return {
            ANCTCorrespondenceNumber: IndicatorR00132Service.ANCTCorrespondenceNumber,
            data: formattedData,
            iconOptions: this.getIconOptions(formattedData),
            tableChartData: this.getTableChartData(formattedData),
            tableData: {
                columns: this.getColumns(),
                title: this._translate('reportCompare.detailLengthCable'),
            },
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.CABLE_TYPE,
                translation: this._translate('shared.type'),
            },
            {
                key: KeyEnum.CABLE_LOGICAL_TYPE,
                translation: this._translate('shared.logicalType'),
            },
            {
                key: KeyEnum.CABLE_PHYSICAL_TYPE,
                translation: this._translate('shared.physicalType'),
            },
            {
                key: KeyEnum.CABLE_LENGTH,
                translation: `${this._translate('shared.length', { COUNT: 1 })} (km)`,
            },
        ];
    }

    getIconOptions(data) {
        const groupDataByLogicalType = radashGroup(data, (currentData) => currentData[KeyEnum.CABLE_LOGICAL_TYPE]);

        return radashUnique(
            Object.entries(groupDataByLogicalType).map(([key, currentData]) => {
                return {
                    color: ColorService.getCableLogicalTypeColor(key),
                    textIcon: key,
                    unit: 'Km',
                    value: radashSum(currentData, (value) => value[KeyEnum.CABLE_LENGTH]),
                };
            }),
            (currentData) => currentData.textIcon,
        );
    }

    getTableChartData(data) {
        const dataGroupByLogicalType = radashGroup(data, (currentData) => currentData[KeyEnum.CABLE_LOGICAL_TYPE]);
        const cableTypes = radashUnique(data.map((currentData) => currentData[KeyEnum.CABLE_TYPE]));

        const datasets = Object.entries(dataGroupByLogicalType).map(([key, values]) => {
            const cableLengths = cableTypes.map((cableType) => {
                const findValue = values.find((currentValue) => currentValue[KeyEnum.CABLE_TYPE] === cableType);

                return findValue?.[KeyEnum.CABLE_LENGTH] || 0;
            });

            return ChartService.getTableChartDataset(cableLengths, key, ColorService.getCableLogicalTypeColor(key));
        });

        return {
            datasets: datasets,
            labels: cableTypes,
        };
    }
}

angular.module('dotic').factory('indicatorR00132Service', IndicatorR00132Service);
