import template from './local-number-by-distance-range-indicator.component.html';

class LocalNumberByDistanceRangeIndicatorComponent {
    chartSize = 180;
    data = [];
    dataStructure = {
        data: [],
        doughnutChartData: { bigLegend: {}, datasets: [], labels: [] },
        tableChartData: { datasets: [], labels: [] },
        tableData: { columns: [], title: '' },
    };
    parentRef = {};

    constructor(indicatorR00143Service) {
        this._indicatorR00143Service = indicatorR00143Service;
    }

    $onInit() {
        this.dataStructure = this._indicatorR00143Service.generateDataStructure(this.data);
    }
}

angular.module('dotic').component('localNumberByDistanceRangeIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: LocalNumberByDistanceRangeIndicatorComponent,
    templateUrl: template,
});
