// Imports
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../../assets/images/help/lCtrl/lCtrl-qgis-byReport.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../../assets/images/help/lCtrl/lCtrl-qgis-modale.jpg");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../../../assets/images/help/lCtrl/lCtrl-qgis-chargementByReport.jpg");
var ___HTML_LOADER_IMPORT_3___ = require("../../../../../../assets/images/help/lStock/lStock-modeQgis.jpg");
// Module
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 5.2.10 / {{ 'helpDeliverable.downloadQGIS' | translate }} : </h3> <ul class=\"help-Doc__ul\"> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_0___ + "\" alt=\"{{ 'helpDeliverable.downloadQGIS' | translate }}\"/> <li class=\"help-Doc__subTitre4\"> {{ 'helpDeliverable.downloadedFiles' | translate }} : <img class=\"help-Doc__img help-Doc__W40\" src=\"" + ___HTML_LOADER_IMPORT_1___ + "\" alt=\"{{ 'helpDeliverable.downloadedFiles' | translate }}\"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'helpDeliverable.loadDownloadReport' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_2___ + "\" alt=\"{{ 'helpDeliverable.loadDownloadReport' | translate }}\"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'helpDeliverable.visualizeWithDelivery' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_3___ + "\" alt=\"{{ 'helpDeliverable.visualizeWithDelivery' | translate }}\"/> </li> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/gestionDepot/livrableControle/qGisLControle.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;