import { unique as radashUnique } from 'radash';

import { ProjectsApi } from '../../../../sdk/connect-control-api-v1/src';
import template from './projectDetail.html';

class ProjectDetailPage {
    constructor(
        $apiClientService,
        $auth,
        $authorizationService,
        $companySettings,
        $dataModelService,
        $excelService,
        $filter,
        $indicatorNewPlugService,
        $location,
        $mViewerService,
        $modalService,
        $projectService,
        $scope,
        $state,
        $stateParams,
        $timeout,
        $toasterService,
        $uibModal,
        $window,
        LoaderService,
        projectProvider,
        userMetricsProvider,
    ) {
        this._$auth = $auth;
        this._$companySettings = $companySettings;
        this._$dataModelService = $dataModelService;
        this._$excelService = $excelService;
        this._translate = $filter('translate');
        this._$indicatorNewPlugService = $indicatorNewPlugService;
        this._$location = $location;
        this._$projectService = $projectService;
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._$uibModal = $uibModal;
        this._LoaderService = LoaderService;
        this._projectProvider = projectProvider;
        this._projectApi = new ProjectsApi($apiClientService.client);
        this._userMetricsProvider = userMetricsProvider;
        this._$modalService = $modalService;

        this._$mViewerService = $mViewerService;
        this._$window = $window;
        this.companyId = $auth.getPayload().company;
        this.controlConfigurationList = [];
        this.deliveryZonesList = [];
        this.hasConfigurationOpen = false;
        this.hasGraphsOpen = false;
        this.housingTree = null;
        this.isAllowed = $authorizationService.isAllowed;
        this.loading = true;
        this.plugTree = null;
        this.project = {};
        this.projectId = $stateParams.projectId;
        this.serversList = [];
        this.subContractorList = [];

        this.filter = {
            searchControlConfig: '',
            searchDeliveryZone: '',
            searchHasSubCompanies: '',
        };

        $scope.$watchGroup(
            [
                '$ctrl.controlConfigurationList',
                '$ctrl.deliveryZonesList',
                '$ctrl.subContractorList',
                '$ctrl.filter.searchControlConfig',
                '$ctrl.filter.searchDeliveryZone',
                '$ctrl.filter.searchHasSubCompanies',
            ],
            () => this.setScopeProjectWithFilters(),
        );
    }

    async $onInit() {
        this.loading = true;
        this.serversList = [];

        this._$companySettings.getCompanyHasLinkBetweenGridsEnabled(this.companyId).then((result) => {
            this.hasLinkBetweenGridsEnabled = result;
        });

        await this.fetchProject(this.projectId);

        this._$scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    title: this.project.name,
                    key: 'projectDetail',
                    href: this._$location.path(),
                },
            ],
            defaultPrevious: {
                title: this._translate('shared.projects'),
                href: this._$state.href('app.projectList'),
                key: 'projects',
            },
            allowedPreviousKeys: [
                'anctVerification',
                'projects',
                'report',
                'statistic',
                'statistics',
                'servers',
                'controlConfigurations',
                'serverDetail',
                'deliveryZoneDetail',
                'controlConfigurationDetail',
                'deposits',
                'comparaison',
                'fillingStatistic',
                'fillingStatisticsCompare',
            ],
        });

        this._$timeout(() => {
            this.loading = false;
        });
    }

    filterSearchDeliveryZone(search) {
        return (deliveryZone) => search === '' || deliveryZone.name.toLowerCase().indexOf(search) > -1;
    }

    filterSearchSubCompanies(search) {
        return (assignment) =>
            assignment.company.name.toLowerCase().indexOf(search) > -1 ||
            assignment.user.fullname.toLowerCase().indexOf(search) > -1;
    }

    setScopeProjectWithFilters() {
        const searchControlConfig = this.filter.searchControlConfig.toLowerCase();
        const searchDeliveryZone = this.filter.searchDeliveryZone.toLowerCase();
        const searchHasSubCompanies = this.filter.searchHasSubCompanies.toLowerCase();

        this.filtereDeliveryZoneList = this.deliveryZonesList.filter(this.filterSearchDeliveryZone(searchDeliveryZone));

        this.filtereSubContractorList = this.subContractorList.filter(
            this.filterSearchSubCompanies(searchHasSubCompanies),
        );

        // Validate configurations (remove old/invalid ones)
        const items = this.controlConfigurationList.filter((item) => item.dataModel);

        // Filter out
        const results = items.filter(
            (item) =>
                searchControlConfig ||
                item.name.toLocaleLowerCase().indexOf(searchControlConfig) > -1 ||
                item.dataModel.name.toLocaleLowerCase().indexOf(searchControlConfig) > -1,
        );

        const groups = results.reduce((acc, item) => {
            const { slug, name } = item.dataModel;
            if (!acc[slug]) {
                acc[slug] = {
                    name,
                    slug,
                    isOpen: true,
                    controlConfigurations: [],
                };
            }

            acc[slug].controlConfigurations.push(item);

            return acc;
        }, {});

        this.filteredControlConfigurationList = Object.values(groups).sort((a, b) => a.name.localeCompare(b.name));
    }

    newDeposit(data) {
        this._$scope.$emit('openBulk', { data });
    }

    canOpenMViewer() {
        return (
            this.project.company?.geoserver?.url &&
            this.project.geoserver?.slug &&
            this.project.company.settings?.feature.hasMviewerForProject
        );
    }

    openMviewerWithDataModel() {
        if (!this.canOpenMViewer()) {
            return;
        }

        const modal = this._$uibModal.open({
            component: 'selectDataModelModal',
            size: 'sm',
            resolve: {
                action: () => this._translate('shared.validate'),
                project: () => this.project,
            },
        });

        modal.result.then(
            (result) => {
                this._$timeout(() => {
                    if (result) {
                        this.openMviewer(result);
                    }
                });
            },
            () => {},
        );
    }

    async openMviewer(dataModelId) {
        this._LoaderService.open(`
                <p>${this._translate('shared.loading')}</p>
                <p>${this._translate('projectList.timeConsuming')}</p>
            `);

        try {
            const url = await this._$mViewerService.getServerUrl({ project: this.project, dataModelId: dataModelId });
            if (!url) {
                this._$toasterService.error(this._translate('shared.disabledMapDistribution'));

                return;
            }
            this._userMetricsProvider.openProjectMviewer(this.project._id);

            await this._projectProvider.refreshTables(this.project._id);

            return this._$window.open(url, '_blank');
        } catch (error) {
            this._$toasterService.error(error);
        } finally {
            this._LoaderService.dismiss();
        }
    }

    async fetchProject(projectId) {
        try {
            this._userMetricsProvider.openProject(projectId);
            this.project = (await this._projectApi.getByIdWithHttpInfo(projectId)).response.body;
        } catch (error) {
            this._$toasterService.error(error);

            return;
        }

        this.isGraceV3 = this.project.controlConfigurations.every((controlConfiguration) =>
            this._$dataModelService.isDataGraceTHDV3(controlConfiguration),
        );

        const subCompanies = this.project.company.subCompanies || [];
        this.controls = this.project.controls;
        this.userList = this.project.assignments
            .reduce((acc, assignment) => {
                const user = assignment.user;
                if (acc.find((userToCompare) => userToCompare.id === user.id)) {
                    return acc;
                }

                return [...acc, user];
            }, [])
            .map((user) => user.fullname)
            .join(', ');

        this.phasesList = this.project.phases.join(', ');
        this.owner = this.project.ownedBy || this.project.createdBy;

        this.serversList = radashUnique(
            this.project.deliveryZones
                .filter((deliveryZone) => deliveryZone.server)
                .map((deliveryZone) => deliveryZone.server),
            (server) => server.id,
        );

        this.subContractorList = this.project.assignments
            .filter(
                (assignment) =>
                    assignment.type === 'subcontractor' && subCompanies.indexOf(assignment.company.id) !== -1,
            )
            .map((item) => {
                const deliveryZones = this.project.deliveryZones.filter(
                    (deliveryZone) => deliveryZone.assignments?.indexOf(item.company.id) > -1,
                );

                return {
                    ...item,
                    deliveryZoneCount: deliveryZones.length,
                };
            });

        this.deliveryZonesList = this.project.deliveryZones;
        this.controlConfigurationList = this.project.controlConfigurations;
        this.project.countDeposits = this.project.countDepositLoads + this.project.countDepositControls;

        this.prepareControlPointChart();

        const indicators = this.project.indicators;
        this.indicators = {
            abandonedObject: indicators.abandonedObject,
            avgpbo: indicators.avgpbo,
            cables: indicators.cables,
            housing: indicators.housing,
            lgCable: indicators.lgCable?.map((cable) => {
                if (cable.capacity !== '') {
                    cable.capacity = parseInt(cable.capacity, 10);

                    return cable;
                }

                cable.capacity = '-';

                return cable;
            }),
            nature_type_phy: indicators.nature_type_phy,
            nbBPE: indicators.nbBPE,
            pbo: [
                { value: indicators.pbo, id: 'pbo' },
                { value: indicators.bpe, id: 'bpe' },
                {
                    value: indicators.type_ebp_pto,
                    id: 'pto',
                },
            ],
            opticalCableSupport: indicators.opticalCableSupport,
            sro: indicators.sro,
            sroTypePhy: indicators.sroTypePhy,
            nroTypePhy: indicators.nroTypePhy,
            tables: indicators.tables,
            newPlug: indicators.newPlug,
            newHousing: indicators.newPlug,
            pathway: indicators.pathway,
            connect: indicators.connectivity,
            nbBti: indicators.nbBti,
            cableBti: indicators.cableBti,
            junctionBox: indicators.junctionBox,
        };

        this.newTable = {
            recordsTable: indicators?.tables ?? [],
            abandonedObjectTable: indicators.abandonedObject === 0 ? [] : indicators.abandonedObject,
        };

        this.junctionBoxes = this.indicators.junctionBox?.filter(
            (junctionBox, index, self) =>
                index ===
                self.findIndex(
                    (box) =>
                        box.bpCode === junctionBox.bpCode &&
                        box.bpCodeExt === junctionBox.bpCodeExt &&
                        box.nbBpDerivation === junctionBox.nbBpDerivation,
                ),
        );

        const plugsData = this.indicators.newPlug ?? this._$indicatorNewPlugService.initIndicNewPlug(this.indicators);

        this.housingTree = this._$indicatorNewPlugService.initHousingTree(plugsData, null, false);
        this.plugTree = this._$indicatorNewPlugService.initPlugTree(plugsData, null, false);
    }

    prepareControlPointChart() {
        const successControls = this.controls.totalSuccessControls;
        const anomalyControl =
            this.controls.totalMinorControls + this.controls.totalMajorControls + this.controls.totalBlockingControls;
        const errorControls = this.controls.totalErrorControls;
        const warningControls = this.controls.totalWarningControls;
        const controlActivatedLength = this.project.countDepositControls;

        const labelsBasic = [
            this._translate('shared.noAnomalyControl', {
                COUNT: successControls,
            }),
            this._translate('shared.anomalyControlPoint', {
                COUNT: anomalyControl,
            }),
            this._translate('shared.inoperativeControl', {
                COUNT: errorControls,
            }),
        ];

        const dataSetsBasicData = [successControls, anomalyControl, errorControls];
        const dataSetBasicBackgroundColor = ['#00a889', '#dd3073', '#b2b2ba'];

        const labels = this.hasLinkBetweenGridsEnabled
            ? [
                  ...labelsBasic,
                  this._translate('shared.notMetControlPoint', {
                      COUNT: warningControls,
                  }),
              ]
            : labelsBasic;

        const datasets = [
            {
                data: this.hasLinkBetweenGridsEnabled ? [...dataSetsBasicData, warningControls] : dataSetsBasicData,
                backgroundColor: this.hasLinkBetweenGridsEnabled
                    ? [...dataSetBasicBackgroundColor, '#5069a9']
                    : dataSetBasicBackgroundColor,
            },
        ];

        this.controlPointsData = {
            bigLegend: {
                figure: controlActivatedLength,
                name: this._translate('shared.controlDeposit', { COUNT: controlActivatedLength }),
            },
            labels,
            datasets,
        };
    }

    toggleConfigurationVisibility() {
        this.hasConfigurationOpen = !this.hasConfigurationOpen;
    }

    toggleGraphVisibility() {
        this.hasGraphsOpen = !this.hasGraphsOpen;
    }

    isServerBroadcast(serverId) {
        if (serverId) {
            if (angular.isObject(this.project.geoserver)) {
                if (angular.isArray(this.project.geoserver.broadcastServers)) {
                    if (this.project.geoserver.broadcastServers.includes(serverId)) {
                        return 'fas fa-satellite-dish c-blue';
                    }
                }
            }

            return 'far fa-circle c-gray';
        }
    }

    projectHasDependencies() {
        return this.project.countDeposits > 0;
    }

    async removeProject() {
        if (this.projectHasDependencies()) {
            return;
        }

        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.project', {
                COUNT: 1,
                NAME: this.project.name,
            }).toLowerCase(),
        );
        if (!isAccepted) {
            return;
        }

        try {
            await this._projectProvider.delete(this.project.id);
            this._$toasterService.info({
                body: this._translate('removeModal.successProject', {
                    COUNT: 1,
                }),
            });
            await this._$state.go('app.projectList');
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async exportProject(project) {
        project.assignments = await this._projectProvider.getAssignments(project.id);

        const excelData = this._$projectService.jsonToExcel(project);

        return this._$excelService.downloadXLSX(
            { headers: true },
            [excelData],
            'projets',
            'projectList.projectsExported',
        );
    }
}

angular.module('dotic').component('projectDetailPage', {
    controller: ProjectDetailPage,
    templateUrl: template,
});
