import template from './cable-length-by-slice-type-indicator.component.html';

class CableLengthBySliceTypeIndicatorComponent {
    data = [];
    dataStructure = {
        data: [],
        iconOptions: [],
        tableChartData: { datasets: [], labels: [] },
        tableData: { columns: [], title: '' },
    };
    parentRef = {};

    constructor(indicatorR00146Service) {
        this._indicatorR00146Service = indicatorR00146Service;
    }

    $onInit() {
        this.dataStructure = this._indicatorR00146Service.generateDataStructure(this.data);
    }
}

angular.module('dotic').component('cableLengthBySliceTypeIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: CableLengthBySliceTypeIndicatorComponent,
    templateUrl: template,
});
