// Imports
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../assets/images/help/projets/projet-suppression-impossible.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../assets/images/help/projets/projet-suppression-possible.jpg");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../../assets/images/help/projets/projet-suppression-modale.jpg");
// Module
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 2.6 / {{ 'helpProject.delete' | translate }} : </h3> <p class=\"help-Doc_Alerte help-Doc-Box-Alerte\"> <span>! {{ 'helpProject.adminDelete' | translate }}.</span> </p> <ul class=\"help-Doc__ul\"> <li class=\"help-Doc__subTitre4\"> {{ 'helpProject.delete' | translate }} : <div class=\"help-Doc__center\"> <div class=\"row\"> <div class=\"col-md-offset-2 col-md-4 help-Doc__positionLegende\"> <span class=\"help-Doc__span\"> {{ 'helpConfig.deleteIfAssociated' | translate: { TYPE: 'project' } }} <em class=\"help-Doc__em-Alerte\">\" {{ 'helpConfig.impossibleDelete' | translate }} \"</em> : </span> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_0___ + "\" alt=\"{{ 'helpConfig.impossibleDelete' | translate }}\"/> </div> </div> </div> <div class=\"help-Doc__center\"> <div class=\"row\"> <div class=\"col-md-offset-2 col-md-4 help-Doc__positionLegende\"> <span class=\"help-Doc__span\"> {{ 'helpConfig.deleteIfAssociated' | translate: { BOOLEAN: 1, TYPE: 'project' } }} <em class=\"help-Doc__em-Alerte\">\" {{ 'helpConfig.deletionPossible' | translate }} \"</em> : </span> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_1___ + "\" alt=\"{{ 'helpConfig.impossibleDelete' | translate }}\"/> </div> </div> </div> </li> <li class=\"help-Doc__subTitre4\"> {{ 'shared.confirmationModal ' | translate }} : <img class=\"help-Doc__img help-Doc__W60\" src=\"" + ___HTML_LOADER_IMPORT_2___ + "\" alt=\"{{ 'shared.confirmationModal ' | translate }}\"/> </li> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/gestionProjet/supprimerProjet.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;