import { FeatureEnum } from '../../guards/has-feature.guard';
import listTemplate from './anct-verifications-list/anct-verifications-list.component.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.anctVerificationsList', {
        url: '/anctVerifications',
        controller: 'anctVerificationsListController as $ctrl',
        templateUrl: listTemplate,
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
        resolve: {
            canActivate: [
                'hasFeatureGuard',
                (hasFeatureGuard) => {
                    return hasFeatureGuard(FeatureEnum.ANCT);
                },
            ],
        },
    });

    $stateProvider.state('app.anctVerificationsDetail', {
        url: '/anctVerifications/{id:[A-Za-z0-9]+}',
        component: 'anctVerificationsDetail',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
        resolve: {
            canActivate: [
                'hasFeatureGuard',
                (hasFeatureGuard) => {
                    return hasFeatureGuard(FeatureEnum.ANCT);
                },
            ],
        },
    });

    $stateProvider.state('app.anctVerificationsPdf', {
        url: '/anctVerifications/{id:[A-Za-z0-9]+}/pdf',
        component: 'anctVerificationsPdfComponent',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
        resolve: {
            canActivate: [
                'hasFeatureGuard',
                (hasFeatureGuard) => {
                    return hasFeatureGuard(FeatureEnum.ANCT);
                },
            ],
        },
    });
});
