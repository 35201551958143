// Imports
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../../assets/images/help/remplissage/remplissage-suppression-not-allowed.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../../assets/images/help/remplissage/remplissage-suppression-allowed.jpg");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../../../assets/images/help/remplissage/remplissage-suppression-modale.jpg");
// Module
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 3.1.6 / {{ 'helpConfig.deleteFillGrid' | translate }} : </h3> <p class=\"help-Doc_Alerte help-Doc-Box-Alerte\"> <span> ! {{ 'helpConfig.warningDeleteTemplateFillGrid' | translate }}. <br/> ! {{ 'helpConfig.warningDeleteWorkingFillGrid' | translate }}. </span> </p> <ul class=\"help-Doc__ul\"> <li class=\"help-Doc__subTitre4\"> {{ 'helpConfig.deleteIfAssociated' | translate: { TYPE: 'grid' } }} <em class=\"help-Doc__em-Alerte\">\" {{ 'helpConfig.impossibleDelete' | translate }} \"</em> : <div class=\"col-md-offset-2 col-md-4 help-Doc__positionLegende\"> <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_0___ + "\" alt=\"{{ 'helpConfig.impossibleDelete' | translate }}\"/> </div> </li> <li class=\"help-Doc__subTitre4\"> {{ 'helpConfig.deleteIfAssociated' | translate: { BOOLEAN: 1, TYPE: 'grid' } }} <em class=\"help-Doc__em-Alerte\">\" {{ 'helpConfig.deletionPossible' | translate }} \"</em> : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_1___ + "\" alt=\"{{ 'helpConfig.deletionPossible' | translate }}\"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'shared.confirmationModal' | translate }} : <img class=\"help-Doc__img help-Doc__W60\" src=\"" + ___HTML_LOADER_IMPORT_2___ + "\" alt=\"{{ 'shared.confirmationModal' | translate }}\"/> </li> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/gestionConfigurations/grillesRemplissage/supprimerGRemplissage.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;