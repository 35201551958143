import template from './doughnut-indicator.component.html';

class DoughnutIndicatorComponent {
    chartSize = 180;
    indicatorDataStructure = {};
    isDisplayingDetails = false;
    isParentLoading = false;
    isSimpleMode = false;
    title = '';

    onToggleDisplayDetails() {
        this.isDisplayingDetails = !this.isDisplayingDetails;
    }
}

angular.module('dotic').component('doughnutIndicator', {
    bindings: {
        chartSize: '<?',
        indicatorDataStructure: '<',
        isParentLoading: '<?',
        isSimpleMode: '<?',
        title: '@',
    },
    controller: DoughnutIndicatorComponent,
    templateUrl: template,
});
