import { sum as radashSum } from 'radash';

import { AddressesDeploymentStateTypeEnum } from '../../../../models/indicator.model';
import { DoughnutChartColorEnum } from '../../../../services/color.service';
import template from './addresses-deployment-state-indicator.component.html';

const KeyEnum = {
    ADDRESS_COUNT: 'addressCount',
    BEING_DEPLOYED_ADDRESSES: 'beingDeployedAddresses',
    DEPLOYED_ADDRESSES: 'deployedAddresses',
    GLOBAL_PERCENTAGE: 'globalPercentage',
    ZS_CODE: 'zsCode',
};

class AddressesDeploymentStateIndicator {
    chartSize = 180;
    columns = [];
    data = [];
    doughnutChartData = { bigLegend: {}, datasets: [], labels: [] };
    isParentLoading = false;
    key = '';
    KeyEnum = KeyEnum;
    parentRef = {};
    type = AddressesDeploymentStateTypeEnum.DONE;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    $onInit() {
        if (!Object.values(AddressesDeploymentStateTypeEnum).includes(this.type)) {
            throw new Error('Incorrect type set for AddressesDeploymentState indicator');
        }

        this.key =
            this.type === AddressesDeploymentStateTypeEnum.DONE
                ? KeyEnum.DEPLOYED_ADDRESSES
                : KeyEnum.BEING_DEPLOYED_ADDRESSES;

        this.doughnutChartData = this.getDoughnutChartData(this.data);
        this.columns = this.getColumns();
    }

    getColumns() {
        return [
            {
                key: KeyEnum.ZS_CODE,
                translation: 'ZSRO',
            },
            {
                key: KeyEnum.ADDRESS_COUNT,
                translation: this._translate('shared.addressCount'),
            },
            {
                isPercentage: true,
                key: this.key,
                translation: this._translate('shared.addressState', {
                    STATE: this.type === AddressesDeploymentStateTypeEnum.DONE ? 'deployed' : 'beingDeployed',
                }),
            },
        ];
    }

    getDoughnutChartData(data) {
        const percentage = data[0]?.[KeyEnum.GLOBAL_PERCENTAGE] || 0;

        return {
            bigLegend: {
                figure: radashSum(data, (currentData) => currentData[KeyEnum.ADDRESS_COUNT]),
                name: this._translate('shared.address'),
            },
            datasets: [
                {
                    backgroundColor: [
                        this.type === AddressesDeploymentStateTypeEnum.DONE
                            ? DoughnutChartColorEnum.SUCCESS
                            : DoughnutChartColorEnum.IN_PROGRESS,
                        DoughnutChartColorEnum.NEUTRAL,
                    ],
                    data: [percentage, 100 - percentage],
                },
            ],
            labels: [
                this._translate('shared.addressState', {
                    STATE: this.type === AddressesDeploymentStateTypeEnum.DONE ? 'deployed' : 'beingDeployed',
                }),
                this._translate('shared.addressState', {
                    STATE: this.type === AddressesDeploymentStateTypeEnum.DONE ? 'notDeployed' : 'notBeingDeployed',
                }),
            ],
        };
    }
}

angular.module('dotic').component('addressesDeploymentStateIndicator', {
    controller: AddressesDeploymentStateIndicator,
    templateUrl: template,
    bindings: {
        data: '<',
        isParentLoading: '<',
        parentRef: '<',
        type: '@?',
    },
});
