/**
 * ConnectControl API Documentation (v1)
 * The official ConnectControl Open API documentation (v1)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The UpdateMongoResult model module.
 * @module model/UpdateMongoResult
 * @version 1.0.0
 */
class UpdateMongoResult {
    /**
     * Constructs a new <code>UpdateMongoResult</code>.
     * @alias module:model/UpdateMongoResult
     * @param acknowledged {Boolean} 
     * @param matchedCount {Number} 
     * @param modifiedCount {Number} 
     * @param upsertedCount {Number} 
     * @param upsertedId {String} 
     */
    constructor(acknowledged, matchedCount, modifiedCount, upsertedCount, upsertedId) { 
        
        UpdateMongoResult.initialize(this, acknowledged, matchedCount, modifiedCount, upsertedCount, upsertedId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, acknowledged, matchedCount, modifiedCount, upsertedCount, upsertedId) { 
        obj['acknowledged'] = acknowledged;
        obj['matchedCount'] = matchedCount;
        obj['modifiedCount'] = modifiedCount;
        obj['upsertedCount'] = upsertedCount;
        obj['upsertedId'] = upsertedId;
    }

    /**
     * Constructs a <code>UpdateMongoResult</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UpdateMongoResult} obj Optional instance to populate.
     * @return {module:model/UpdateMongoResult} The populated <code>UpdateMongoResult</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UpdateMongoResult();

            if (data.hasOwnProperty('acknowledged')) {
                obj['acknowledged'] = ApiClient.convertToType(data['acknowledged'], 'Boolean');
            }
            if (data.hasOwnProperty('matchedCount')) {
                obj['matchedCount'] = ApiClient.convertToType(data['matchedCount'], 'Number');
            }
            if (data.hasOwnProperty('modifiedCount')) {
                obj['modifiedCount'] = ApiClient.convertToType(data['modifiedCount'], 'Number');
            }
            if (data.hasOwnProperty('upsertedCount')) {
                obj['upsertedCount'] = ApiClient.convertToType(data['upsertedCount'], 'Number');
            }
            if (data.hasOwnProperty('upsertedId')) {
                obj['upsertedId'] = ApiClient.convertToType(data['upsertedId'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>UpdateMongoResult</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UpdateMongoResult</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of UpdateMongoResult.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['upsertedId'] && !(typeof data['upsertedId'] === 'string' || data['upsertedId'] instanceof String)) {
            throw new Error("Expected the field `upsertedId` to be a primitive type in the JSON string but got " + data['upsertedId']);
        }

        return true;
    }


}

UpdateMongoResult.RequiredProperties = ["acknowledged", "matchedCount", "modifiedCount", "upsertedCount", "upsertedId"];

/**
 * @member {Boolean} acknowledged
 */
UpdateMongoResult.prototype['acknowledged'] = undefined;

/**
 * @member {Number} matchedCount
 */
UpdateMongoResult.prototype['matchedCount'] = undefined;

/**
 * @member {Number} modifiedCount
 */
UpdateMongoResult.prototype['modifiedCount'] = undefined;

/**
 * @member {Number} upsertedCount
 */
UpdateMongoResult.prototype['upsertedCount'] = undefined;

/**
 * @member {String} upsertedId
 */
UpdateMongoResult.prototype['upsertedId'] = undefined;






export default UpdateMongoResult;

