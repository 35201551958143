// Imports
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../../assets/images/help/postProcessing/postProcessingParamsSelect.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../../assets/images/help/postProcessing/postProcessingParamsImport.jpg");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../../../assets/images/help/postProcessing/postProcessingParamsOther.jpg");
// Module
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 4.2 / {{ 'postProcessing.configurePostProcessing' | translate }} : </h3> <ul class=\"help-Doc__ul\"> <li class=\"help-Doc__subTitre4\"> {{ 'postProcessing.selectFiles' | translate }} : <img class=\"help-Doc__img help-Doc__W99\" src=\"" + ___HTML_LOADER_IMPORT_0___ + "\" alt=\"{{ 'postProcessing.selectFiles' | translate }}\"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'postProcessing.importFiles' | translate }} : <img class=\"help-Doc__img help-Doc__W99\" src=\"" + ___HTML_LOADER_IMPORT_1___ + "\" alt=\"{{ 'postProcessing.importFiles' | translate }}\"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'postProcessing.otherActions' | translate }} : <img class=\"help-Doc__img help-Doc__W99\" src=\"" + ___HTML_LOADER_IMPORT_2___ + "\" alt=\"{{ 'postProcessing.otherActions' | translate }}\"/> </li> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/postProcessing/postProcessingParams/postProcessingParamsManage.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;