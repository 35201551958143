import FttxDataEvolutionThematicIndicatorsComponent from '../fttx-data-evolution-view-indicators-thematic.component';
import template from './fttx-data-evolution-boxes.component.html';

class FttxDataEvolutionBoxesIndicatorsComponent extends FttxDataEvolutionThematicIndicatorsComponent {
    constructor($favoriteIndicatorsService, indicatorChartService) {
        super($favoriteIndicatorsService, indicatorChartService);
    }
}

angular.module('dotic').component('fttxDataEvolutionBoxesIndicatorsComponent', {
    controller: FttxDataEvolutionBoxesIndicatorsComponent,
    templateUrl: template,
    bindings: FttxDataEvolutionThematicIndicatorsComponent.defaultBindings,
});
