import template from './icon-list-indicator.component.html';

class IconListIndicatorComponent {
    indicatorDataStructure = {
        data: [],
        iconOptions: [],
        tableData: { columns: [], title: '' },
    };
    isDisplayingDetails = false;
    isParentLoading = false;
    title = '';

    onToggleDisplayDetails() {
        this.isDisplayingDetails = !this.isDisplayingDetails;
    }
}

angular.module('dotic').component('iconListIndicator', {
    bindings: {
        indicatorDataStructure: '<',
        isParentLoading: '<?',
        title: '@',
    },
    controller: IconListIndicatorComponent,
    templateUrl: template,
});
